import styled from 'styled-components/macro'

export const BuildControllerMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: ${props => props.isFullView ? `0` : '440px'};
    bottom: 40px;
    width: ${props => props.isFullView ? `100%` : 'calc(100% - 440px)'};

    @media (max-width: 991px) {
        bottom: initial;
        top: calc(40vh - 70px);
        left: 50%;
        transform: translateX(-50%);
    }
`

export const BuildControllerMenuItem = styled.div`
    background: rgb(230,230,230);
    display: flex;
    flex-direction: column;
    transition: all .1s;
    position: relative;
    text-align: center;
    font-size: 25px;
    color: #222;
    padding: 5px 10px;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
    border-left: 1px solid rgba(0, 0, 0, .3);

    &:hover {
        background: #fff;
        cursor: pointer;    
    }

    &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-left: none;
    }

    &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`

export const BuildControllerMenuItemIcon = styled.div`
    
    svg {
        transform: scale(0.8);
        * {
            fill: #222;
        }
    }
`

export const BuildControllerMenuItemText = styled.div`
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
`




