import React, { useContext, useState, useEffect } from 'react'
import { CanvasWrapper } from './Canvas.css'
//import {createScene} from '../../functions/BabylonViewer/renderScene'
import { Engine, Scene } from 'react-babylonjs'
import { generateFrames, createReferencePlanes } from '../../utils/functions'

import {
    Vector3,
    Color3,
    AssetsManager,
    Plane,
    MorphTargetManager,
    MorphTarget,
    CubeTexture,
    MeshBuilder,
    StandardMaterial,
    PBRMaterial,
    Texture,
    PointerEventTypes,
    PointerDragBehavior,
    Space,
    VertexBuffer,
    Mesh
} from '@babylonjs/core';
import "@babylonjs/loaders/glTF";
import "pepjs"
import 'babylonjs-inspector'
import { GlobalState } from '../../App';


const Canvas = ({ canvasStyle }) => {

    const GlobalStore = useContext(GlobalState);

    const setScene = (sceneRef) => {
        GlobalStore.sceneGlobalRef.current = sceneRef.scene;
        const scene = sceneRef.scene;
        //scene.debugLayer.show();
        scene.getBoundingBoxRenderer().frontColor.set(0.5, 0.7, 1);
        scene.getBoundingBoxRenderer().backColor.set(0.5, 0.7, 1);
        const assetsManager = new AssetsManager(scene);
        assetsManager.useDefaultLoadingScreen = false;
        const mainStructureTask = assetsManager.addMeshTask("mainStructureTask", "", "./babylon_assets/meshes/", "main_structure.glb");
        const leftLeanTask = assetsManager.addMeshTask("leftLeanTask", "", "./babylon_assets/meshes/", "left_lean.glb");
        const rightLeanTask = assetsManager.addMeshTask("rightLeanTask", "", "./babylon_assets/meshes/", "right_lean.glb");
        const mainStructureMorphTargetTask = assetsManager.addMeshTask("mainStructureMorphTargetTask", "", "./babylon_assets/meshes/", "main_structure_morphTarget.glb");
        const leanRightMorphTargetTask = assetsManager.addMeshTask("leanRightMorphTargetTask", "", "./babylon_assets/meshes/", "right_lean_morphTarget.glb");
        const leanLeftMorphTargetTask = assetsManager.addMeshTask("leanLeftMorphTargetTask", "", "./babylon_assets/meshes/", "left_lean_morphTarget.glb");
        const doorsTask = assetsManager.addMeshTask("doorsTask", "", "./babylon_assets/meshes/", "doors.glb");
        const arrowTask = assetsManager.addMeshTask("arrowTask", "", "./babylon_assets/meshes/", "arrow.glb");

        mainStructureTask.onSuccess = (task) => {
            const mainStructureMeshes = task.loadedMeshes;
            let mainStructureParent_R;
            let mainStructureParent_L;
            let mainStructureWalls = [];
            const clipPlane_R = new Plane(0, 0, -1, -0.25);
            const clipPlane_L = new Plane(0, 0, 1, -0.25);

            mainStructureMeshes.map((mesh) => {
                mesh.isPickable = false;
                mesh.name.includes(mesh.name.includes("Profile") || mesh.name.includes("Ridge") ? mesh.isVisible = false : mesh.isVisible = true);
                if (mesh.name.includes("Main_Helper_R")) {
                    mainStructureParent_R = mesh;
                    const mainDistanceFromCenter = mesh.getBoundingInfo().boundingBox.extendSizeWorld.z * 2;
                    const mainLengthFromCenter = mesh.getBoundingInfo().boundingBox.minimumWorld.x;
                    GlobalStore.leanClipPlaneDistance.current = mainDistanceFromCenter;
                    GlobalStore.mainLengthFromCenter.current = mainLengthFromCenter;
                }
                else if (mesh.name.includes("Main_Helper_L")) {
                    mainStructureParent_L = mesh;
                }
                return true
            })
            GlobalStore.main_L_startPosZ.current = mainStructureParent_L.position.z;
            GlobalStore.main_R_startPosZ.current = mainStructureParent_R.position.z;
            GlobalStore.main_L_startPosY.current = mainStructureParent_L.position.y;
            GlobalStore.main_R_startPosY.current = mainStructureParent_R.position.y;

            mainStructureParent_R.isVisible = false;
            mainStructureParent_L.isVisible = false;

            mainStructureParent_R.computeWorldMatrix(true);
            const storagePlaneDistance = mainStructureParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
            GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;
            const clipPlaneStorage = new Plane(-1, 0, 0, storagePlaneDistance + 0.5);


            for (const mesh of mainStructureParent_R.getChildMeshes()) {
                if (mesh.name.includes("multiFrameBase")) {
                    generateFrames(mesh, mainStructureParent_R, 2.4, 0.65, clipPlane_R, GlobalStore.clonedFramesMain, 1, scene);
                }
                if (mesh.name.includes("walls") || mesh.name.includes("wainscot")) {
                    mainStructureWalls.push(mesh);
                }
                if (!mesh.name.includes("Cylinder001") && !mesh.name.includes("__root__")) {
                    mesh.onBeforeRenderObservable.add(function () {
                        scene.clipPlane = clipPlane_R;
                    });
                    mesh.onAfterRenderObservable.add(function () {
                        scene.clipPlane = null;
                    });
                }
                if (mesh.name === ("storage_main_R")) {
                    mesh.onBeforeRenderObservable.add(function () {
                        scene.clipPlane2 = clipPlaneStorage;
                    });
                    mesh.onAfterRenderObservable.add(function () {
                        scene.clipPlane2 = null;
                    });
                }
                if (mesh.name === ("storage_main_backWall_R")) {
                    mesh.isVisible = false;
                }
            }

            for (const mesh of mainStructureParent_L.getChildMeshes()) {
                if (mesh.name.includes("multiFrameBase")) {
                    generateFrames(mesh, mainStructureParent_L, 2.4, 0.65, clipPlane_L, GlobalStore.clonedFramesMain, 1, scene);
                }
                if (mesh.name.includes("walls") || mesh.name.includes("wainscot")) {
                    mainStructureWalls.push(mesh);
                }
                if (!mesh.name.includes("Cylinder001") && !mesh.name.includes("__root__")) {
                    mesh.onBeforeRenderObservable.add(function () {
                        scene.clipPlane = clipPlane_L;
                    });
                    mesh.onAfterRenderObservable.add(function () {
                        scene.clipPlane = null;
                    });
                }
                if (mesh.name === ("storage_main_L")) {
                    mesh.onBeforeRenderObservable.add(function () {
                        scene.clipPlane2 = clipPlaneStorage;
                    });
                    mesh.onAfterRenderObservable.add(function () {
                        scene.clipPlane2 = null;
                    });
                }
                if (mesh.name === ("storage_main_backWall_L")) {
                    mesh.isVisible = false;
                }
            }
            //const wallsClipPanelDistanceFromGround = mainStructureParent_L.getBoundingInfo().boundingBox.extendSizeWorld.y * 2 + 20;
            const wallsClipPanelDistanceFromGround = mainStructureParent_L.getBoundingInfo().boundingBox.maximumWorld.y + 5;
            const clipPlaneWall = new Plane(0, -1, 0, wallsClipPanelDistanceFromGround);
            mainStructureWalls.map((wall) => {
                wall.onBeforeRenderObservable.add(function () {
                    scene.resetCachedMaterial();
                    scene.clipPlane2 = clipPlaneWall;
                });
                wall.onAfterRenderObservable.add(function () {
                    scene.clipPlane2 = null;
                });
            });
            GlobalStore.mainWallMeshes.current = mainStructureWalls;
        }

        leftLeanTask.onSuccess = (task) => {
            let leftLeanMeshes = task.loadedMeshes;
            let leftLeanParent;
            let leftLeanWalls = [];
            const clipPlane_LL = new Plane(0, 0, 1, GlobalStore.leanClipPlaneDistance.current);
            leftLeanMeshes.map((mesh) => {
                mesh.isVisible = false
                mesh.isPickable = false;
                if (mesh.name.includes("Lean_Left_Helper")) {
                    leftLeanParent = mesh;
                }
                return true;
            });
            GlobalStore.lean_L_startPosZ.current = leftLeanParent.position.z;
            GlobalStore.lean_L_startPosY.current = leftLeanParent.position.y;


            leftLeanParent.computeWorldMatrix(true);
            const storagePlaneDistance = leftLeanParent.getBoundingInfo().boundingBox.minimumWorld.x;
            GlobalStore.leanLLengthFromCenter.current = storagePlaneDistance;
            const clipPlaneStorage = new Plane(-1, 0, 0, storagePlaneDistance + 0.5);
            let leftLeanStorage;
            leftLeanParent.getChildMeshes().map((mesh) => {
                if (mesh.name.includes("multiFrameBase")) {
                    generateFrames(mesh, leftLeanParent, 2.4, 0.65, clipPlane_LL, GlobalStore.clonedFramesLeanL, 1, scene);
                }
                if (mesh.name.includes("walls") || mesh.name.includes("wainscot")) {
                    leftLeanWalls.push(mesh);
                }
                mesh.onBeforeRenderObservable.add(function () {
                    scene.clipPlane = clipPlane_LL;
                });
                mesh.onAfterRenderObservable.add(function () {
                    scene.clipPlane = null;
                });
                if (mesh.name === ("storage_lean_L")) {
                    mesh.onBeforeRenderObservable.add(function () {
                        scene.clipPlane2 = clipPlaneStorage;
                    });
                    mesh.onAfterRenderObservable.add(function () {
                        scene.clipPlane2 = null;
                    });
                    mesh.getChildMeshes().map(child => {
                        child.onBeforeRenderObservable.add(function () {
                            scene.clipPlane2 = clipPlaneStorage;
                        });
                        child.onAfterRenderObservable.add(function () {
                            scene.clipPlane2 = null;
                        });
                    })
                }
                if (mesh.name === ("storage_leanL_backWall")) {
                    mesh.material.backFaceCulling = false;
                    mesh.isVisible = false;
                }
                return true;
            })

            const wallsClipPanelDistanceFromGround = leftLeanParent.getBoundingInfo().boundingBox.maximumWorld.y + 5;
            const clipPlaneWall = new Plane(0, -1, 0, wallsClipPanelDistanceFromGround);
            leftLeanWalls.map((wall) => {
                wall.onBeforeRenderObservable.add(function () {
                    scene.resetCachedMaterial();
                    scene.clipPlane2 = clipPlaneWall;
                });
                wall.onAfterRenderObservable.add(function () {
                    scene.clipPlane2 = null;
                });
            });
            GlobalStore.leftLeanWallMeshes.current = leftLeanWalls;
        }

        rightLeanTask.onSuccess = (task) => {
            let rightLeanMeshes = task.loadedMeshes;
            let rightLeanParent;
            let rightLeanWalls = [];
            const clipPlane_LR = new Plane(0, 0, -1, GlobalStore.leanClipPlaneDistance.current);
            rightLeanMeshes.map((mesh) => {
                mesh.isVisible = false;
                mesh.isPickable = false;
                if (mesh.name.includes("Lean_Right_Helper")) {
                    rightLeanParent = mesh;
                }
                return true;
            });

            GlobalStore.lean_R_startPosZ.current = rightLeanParent.position.z;
            GlobalStore.lean_R_startPosY.current = rightLeanParent.position.y;

            rightLeanParent.computeWorldMatrix(true);
            const storagePlaneDistance = rightLeanParent.getBoundingInfo().boundingBox.minimumWorld.x;
            GlobalStore.leanRLengthFromCenter.current = storagePlaneDistance;
            const clipPlaneStorage = new Plane(-1, 0, 0, storagePlaneDistance + 0.5);

            rightLeanParent.getChildMeshes().map((mesh) => {
                if (mesh.name.includes("multiFrameBase")) {
                    generateFrames(mesh, rightLeanParent, 2.4, 0.65, clipPlane_LR, GlobalStore.clonedFramesLeanR, 1, scene);
                }
                if (mesh.name.includes("walls") || mesh.name.includes("wainscot")) {
                    rightLeanWalls.push(mesh);
                }
                mesh.onBeforeRenderObservable.add(function () {
                    scene.clipPlane = clipPlane_LR;
                });
                mesh.onAfterRenderObservable.add(function () {
                    scene.clipPlane = null;
                });
                if (mesh.name === ("storage_lean_R")) {
                    mesh.onBeforeRenderObservable.add(function () {
                        scene.clipPlane2 = clipPlaneStorage;
                    });
                    mesh.onAfterRenderObservable.add(function () {
                        scene.clipPlane2 = null;
                    });
                    mesh.getChildMeshes().map(child => {
                        child.onBeforeRenderObservable.add(function () {
                            scene.clipPlane2 = clipPlaneStorage;
                        });
                        child.onAfterRenderObservable.add(function () {
                            scene.clipPlane2 = null;
                        });
                    })
                }
                if (mesh.name === ("storage_leanR_backWall")) {
                    mesh.isVisible = false;
                }
                return true;
            })

            const wallsClipPanelDistanceFromGround = rightLeanParent.getBoundingInfo().boundingBox.maximumWorld.y + 5;
            const clipPlaneWall = new Plane(0, -1, 0, wallsClipPanelDistanceFromGround);
            rightLeanWalls.map((wall) => {
                wall.onBeforeRenderObservable.add(function () {
                    scene.resetCachedMaterial();
                    scene.clipPlane2 = clipPlaneWall;
                });
                wall.onAfterRenderObservable.add(function () {
                    scene.clipPlane2 = null;
                });
            });
            GlobalStore.rightLeanWallMeshes.current = rightLeanWalls;
        }


        mainStructureMorphTargetTask.onSuccess = (task) => {
            const morphMeshes = task.loadedMeshes;
            morphMeshes.map(mesh => { mesh.isVisible = false; mesh.isPickable = false; })
        }
        leanRightMorphTargetTask.onSuccess = (task) => {
            const morphMeshes = task.loadedMeshes;
            morphMeshes.map(mesh => { mesh.isVisible = false; mesh.isPickable = false; })
        }
        leanLeftMorphTargetTask.onSuccess = (task) => {
            const morphMeshes = task.loadedMeshes;
            morphMeshes.map(mesh => { mesh.isVisible = false; mesh.isPickable = false })
        }
        doorsTask.onSuccess = (task) => {
            const doors = task.loadedMeshes;
            const parentMaterial = new StandardMaterial("w&d_parentMaterial", scene);
            parentMaterial.alpha = 0;
            const doorsMaterial = new PBRMaterial("doorsMaterial", scene);
            const windowGlassMaterial = new PBRMaterial("windowGlassMaterial", scene);
            const framedOpeningsAlphaMaterial = new StandardMaterial("framedOpeningsAlphaMaterial", scene);

            doors.map(mesh => {
                if (mesh.name.indexOf("_Helper") > -1) {
                    mesh.isVisible = false;
                    mesh.isPickable = false;
                }
                else if (mesh.name === ("Window (36x80 glass)")) {
                    mesh.material = windowGlassMaterial;
                }
                else if (mesh.name.includes("Framed Openings_alpha") || mesh.name === ("FramedWalkin_alpha") || mesh.name === ("WindowFramed_alpha")) {
                    mesh.material = framedOpeningsAlphaMaterial;
                }
                else {
                    mesh.material = doorsMaterial;
                    mesh.isPickable = false;
                    mesh.isVisible = false;
                }
            })
        }

        arrowTask.onSuccess = (task) => {
            const arrow = task.loadedMeshes;
            arrow.map(mesh => {
                mesh.isVisible = false
                mesh.isPickable = false;
            });
        }

        assetsManager.load();

        const pointerDragBehaviorX = new PointerDragBehavior({ dragAxis: new Vector3(1, 0, 0) });
        const pointerDragBehaviorZ = new PointerDragBehavior({ dragAxis: new Vector3(0, 0, 1) });


        const pointerDragBehaviorYZ = new PointerDragBehavior({ dragPlaneNormal: new Vector3(1, 0, 0) })
        const pointerDragBehaviorXY = new PointerDragBehavior({ dragPlaneNormal: new Vector3(0, 0, 1) })

        // Use drag plane in world space
        pointerDragBehaviorX.useObjectOrientationForDragging = false;
        pointerDragBehaviorZ.useObjectOrientationForDragging = false;
        pointerDragBehaviorYZ.useObjectOrientationForDragging = false;
        pointerDragBehaviorXY.useObjectOrientationForDragging = false;

        scene.onPointerObservable.add((pointerInfo) => {
            let pickedMesh;
            switch (pointerInfo.type) {
                case PointerEventTypes.POINTERDOWN:
                    if (pointerInfo.pickInfo.hit) {
                        pickedMesh = pointerInfo.pickInfo.pickedMesh.parent;
                        try {
                            if (pickedMesh.bindedTo.indexOf("front") > -1 || pickedMesh.bindedTo.indexOf("back") > -1) {
                                if (pickedMesh.name.indexOf("window") > -1) {
                                    pickedMesh.removeBehavior(pointerDragBehaviorYZ);
                                    pickedMesh.addBehavior(pointerDragBehaviorYZ);
                                }
                                else {
                                    pickedMesh.removeBehavior(pointerDragBehaviorZ);
                                    pickedMesh.addBehavior(pointerDragBehaviorZ);
                                }
                            }
                            else {
                                if (pickedMesh.name.indexOf("window") > -1) {
                                    pickedMesh.removeBehavior(pointerDragBehaviorXY);
                                    pickedMesh.addBehavior(pointerDragBehaviorXY);
                                }
                                else {
                                    pickedMesh.removeBehavior(pointerDragBehaviorX);
                                    pickedMesh.addBehavior(pointerDragBehaviorX);
                                }
                            }
                        }
                        catch (e) {
                            console.log("pickedMesh error :" + e)
                        }
                    }
                    break;
            }
        });

        scene.executeWhenReady(() => {
            //get necesery meshes
            const mainParent_R = scene.getMeshByName("Main_Helper_R");
            const mainParent_L = scene.getMeshByName("Main_Helper_L");
            const mainParent_R_M = scene.getMeshByName("Main_Helper_R_morph0");
            const mainParent_L_M = scene.getMeshByName("Main_Helper_L_morph0");
            const rightLeanParent = scene.getMeshByName("Lean_Right_Helper");
            const rightLeanParent_M = scene.getMeshByName("Lean_Right_Helper_morph0");
            const leftLeanParent = scene.getMeshByName("Lean_Left_Helper");
            const leftLeanParent_M = scene.getMeshByName("Lean_Left_Helper_morph0");
            const ground = scene.getMeshByName("Cylinder001");
            const dimensionHelper_M = scene.getMeshByName("dimensionHelper_M");
            const dimensionHelper_R = scene.getMeshByName("dimensionHelper_R");
            const dimensionHelper_L = scene.getMeshByName("dimensionHelper_L");


            ground.computeWorldMatrix(true);
            const groundMax = ground.getBoundingInfo().boundingBox.maximumWorld.y;
            const clipPlaneWholeScene = new Plane(0, -1, 0, groundMax);
            scene.meshes.map(mesh => {
                mesh.onBeforeRenderObservable.add(function () {
                    scene.resetCachedMaterial();
                    scene.clipPlane3 = clipPlaneWholeScene;
                });
                mesh.onAfterRenderObservable.add(function () {
                    scene.clipPlane3 = null;
                });
            })


            GlobalStore.dimensionHelper_M_scalingZ.current = dimensionHelper_M.scaling.z * 10;
            dimensionHelper_M.isVisible = false;
            //setMaterials
            setMaterials(scene);
            //define morphTargets for meshes
            //MAIN PARENT RIGHT
            for (const mainMesh of mainParent_R.getChildMeshes()) {
                for (const morphMesh of mainParent_R_M.getChildMeshes()) {
                    const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
                    if (mainMesh.name.includes(morphMeshName)) {
                        let morphManager = new MorphTargetManager();
                        mainMesh.morphTargetManager = morphManager;
                        let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
                        morphManager.addTarget(target);
                    }
                }
            }
            //MAIN PARENT LEFT
            for (const mainMesh of mainParent_L.getChildMeshes()) {
                for (const morphMesh of mainParent_L_M.getChildMeshes()) {
                    const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
                    if (mainMesh.name.includes(morphMeshName)) {
                        let morphManager = new MorphTargetManager();
                        mainMesh.morphTargetManager = morphManager;
                        let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
                        morphManager.addTarget(target);
                    }
                }
            }
            //RIGHT LEAN
            for (const rightLeanMesh of rightLeanParent.getChildMeshes()) {
                for (const morphMesh of rightLeanParent_M.getChildMeshes()) {
                    const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
                    if (rightLeanMesh.name.includes(morphMeshName)) {
                        let morphManager = new MorphTargetManager();
                        rightLeanMesh.morphTargetManager = morphManager;
                        let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
                        morphManager.addTarget(target);
                    }
                }
            }
            //LEFT LEAN
            for (const leftLeanMesh of leftLeanParent.getChildMeshes()) {
                for (const morphMesh of leftLeanParent_M.getChildMeshes()) {
                    const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
                    if (leftLeanMesh.name.includes(morphMeshName)) {
                        let morphManager = new MorphTargetManager();
                        leftLeanMesh.morphTargetManager = morphManager;
                        let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
                        morphManager.addTarget(target);
                    }
                }
            }


            //createReference planes (highlighted invisible planes which represents the part of the warehouse that we are looking at)
            const planeMat = new StandardMaterial("planeMat", scene);
            planeMat.alpha = 0;
            planeMat.transparencyMode = 3;
            //main structure planes            
            createReferencePlanes(dimensionHelper_M, null, "front", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_M, null, "back", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_M, null, "left", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_M, null, "right", ground, planeMat, GlobalStore, scene)
            //left lean planes
            createReferencePlanes(dimensionHelper_L, mainParent_L, "front_L", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_L, mainParent_L, "back_L", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_L, null, "left_L", ground, planeMat, GlobalStore, scene)
            // //right lean planes
            createReferencePlanes(dimensionHelper_R, mainParent_R, "front_R", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_R, mainParent_R, "back_R", ground, planeMat, GlobalStore, scene)
            createReferencePlanes(dimensionHelper_R, null, "right_R", ground, planeMat, GlobalStore, scene)

            //push wainscot meshes to the global variable
            scene.meshes.map(mesh => {
                if (mesh.name.indexOf("wainscot") > -1) {
                    mesh.isVisible = false;
                    mesh.material = scene.getMaterialByName("wainscotMaterial");
                    GlobalStore.wainscotMeshes.current.push(mesh);
                }
            })

            //(GlobalStore, scene, "global");

            //each frame calculate 
            scene.registerBeforeRender(() => {
                try {
                    //look for the closest reference plane that we look at
                    let distances = [];
                    let planes = [];
                    GlobalStore.cameraDistanceReferencePlanes.current.map((plane) => {
                        if (plane.partOf === "main") {
                            distances.push(Vector3.Distance(scene.activeCamera.position, plane.position));
                            planes.push(plane);
                        }
                        else if (plane.partOf === "leftLean" && GlobalStore.isLeftLeanActive.current) {
                            distances.push(Vector3.Distance(scene.activeCamera.position, plane.position));
                            planes.push(plane);
                        }
                        else if (plane.partOf === "rightLean" && GlobalStore.isRightLeanActive.current) {
                            distances.push(Vector3.Distance(scene.activeCamera.position, plane.position));
                            planes.push(plane);
                        }
                        plane.isActivePlane = false;
                        plane.showBoundingBox = false;
                    })
                    const min = Math.min(...distances);
                    const indexOfMin = distances.indexOf(min);
                    const closestPlane = planes[indexOfMin];
                    closestPlane.isActivePlane = true;
                    closestPlane.showBoundingBox = true;
                } catch (e) {
                    console.log("there are no reference frames")
                }
            })

            GlobalStore.setIsLoading(false);
        })

    }

    const setCamera = (camera) => {
        console.log("camera created");
    }

    const setMaterials = (scene) => {
        const materials = scene.materials;
        const envTexture = new CubeTexture("./babylon_assets/textures/env.env", scene, 1024);
        const lineMaterial = new StandardMaterial("lineMaterial", scene);
        const panelBumpTexture = new Texture("./babylon_assets/textures/wall_normal.jpg", scene)
        lineMaterial.emissiveColor = new Color3.White();
        for (const material of materials) {
            //outer materials (always white ??so far??)
            if (material.name.includes("innerWalls")) {
                material.albedoColor = new Color3(0.8, 0.8, 0.8).toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0.1;
                material.roughness = 1;
                material.zOffset = -0.6;
            }
            else if (material.name.includes("innerRoof")) {
                material.albedoColor = new Color3(0.8, 0.8, 0.8).toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0.1;
                material.roughness = 1;
            }
            //innerMaterials
            else if (material.name.includes("outerWalls")) {
                material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0.7;
                material.roughness = 0.7;
                material.bumpTexture = panelBumpTexture
                material.backFaceCulling = false;
                GlobalStore.outerWallsMaterials.current.push(material);
            }
            else if (material.name.includes("outerRoof")) {
                material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0.7;
                material.roughness = 0.7;
                material.bumpTexture = panelBumpTexture
                GlobalStore.outerRoofMaterials.current.push(material);
            }
            else if (material.name.includes("frames")) {
                material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0.45;
                material.roughness = 0.69;
                GlobalStore.outerTrimMaterials.current.push(material);
            }
            else if (material.name === "doorsMaterial") {
                material.albedoColor = new Color3(0.8, 0.8, 0.8).toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0;
                material.roughness = 0.8;
            }
            else if (material.name === "windowGlassMaterial") {
                material.albedoColor = new Color3(0.4, 0.4, 0.4).toLinearSpace();
                material.reflectionTexture = envTexture;
                material.metallic = 0;
                material.roughness = 0.8;
            }
            else if (material.name === "framedOpeningsAlphaMaterial") {
                material.diffuseColor = new Color3(0, 0, 0).toLinearSpace();
                material.backFaceCulling = false;
            }
        }
        const skybox = MeshBuilder.CreateBox("skyBox", { size: 1500.0 }, scene);
        const skyboxMaterial = new StandardMaterial("skyBox", scene);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.reflectionTexture = new CubeTexture("./babylon_assets/textures/sky/sky", scene);
        skyboxMaterial.reflectionTexture.rotationY = 1.89;
        skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
        skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
        skyboxMaterial.specularColor = new Color3(0, 0, 0);
        skybox.material = skyboxMaterial;
        skybox.isPickable = false;
        skybox.position.y -= 200;
        const wainscotMaterial = new PBRMaterial("wainscotMaterial", scene);
        wainscotMaterial.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
        wainscotMaterial.reflectionTexture = envTexture;
        wainscotMaterial.metallic = 0.7;
        wainscotMaterial.roughness = 0.7;
        wainscotMaterial.bumpTexture = panelBumpTexture
        wainscotMaterial.zOffset = -1;
    }
    return (
        <CanvasWrapper>
            <Engine canvasId="renderCanvas"
                canvasStyle={{ visibility: canvasStyle }}
                antialias={true}
                adaptToDeviceRatio={true}
                engineOptions={{ preserveDrawingBuffer: true, stencil: true }}
            >
                <Scene
                    id="renderCanvas"
                    onSceneMount={scene => setScene(scene)}
                >
                    <arcRotateCamera
                        name="arcRotateCamera"
                        alpha={0}
                        beta={1.3}
                        radius={GlobalStore.cameraRadius}
                        target={new Vector3(0, 20, 0)}
                        lowerRadiusLimit={1}
                        upperRadiusLimit={400}
                        lowerBetaLimit={0.001}
                        upperBetaLimit={1.5}
                        inertia={0.6}
                        useAutoRotationBehavior={GlobalStore.cameraRotating}
                        wheelPrecision={1.2}
                        pinchPrecision={1.2}
                        panningSensibility={100}
                        onCreated={camera => setCamera(camera)}
                    >
                    </arcRotateCamera>
                    <hemisphericLight
                        name="hemisphericLight"
                        specular={new Color3.Black()}
                        groundColor={new Color3(0.8, 0.8, 0.8)}
                        intensity={0.7}>
                    </hemisphericLight>
                </Scene>
            </Engine>
        </CanvasWrapper>
    )
}

export default Canvas
