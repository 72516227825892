import React, { useContext, useState, useRef, useEffect } from "react";
import { BuiltSizeWrapper, BuiltSizeScrollbarWrapper } from "./BuiltSize.css";
import SectionTitle from "../../SectionTitle/SectionTitle";
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import Separator from "../../../utils/Separator/Separator";

//import Scrollbar from "react-scrollbars-custom";

import InputBoxes from "../parts/InputBoxes/InputBoxes";
import InputSelect from "../parts/InputSelect/InputSelect";

import Grid from "@material-ui/core/Grid";

import { Plane, Space, Axis } from "@babylonjs/core";
import {
	regenerateFrames,
	recreatePlanes,
	createMeasurementsGUI,
	switchMorphLevel,
	mainSidesWallClipPlanesChange,
	mainEndsWallClipPlanesChange,
	handlePriceValue,
} from "../../../utils/functions";

import { GlobalState } from "../../../App";

import roofData from "../parts/InputBoxes/roofData";

const roofPitchSelectOptions = [
	{ value: "2/12", label: "2/12" },
	{ value: "3/12", label: "3/12" },
	{ value: "4/12", label: "4/12" },
	{ value: "5/12", label: "5/12" },
];

const widthSelectOptions = [];
for (let i = 12; i < 61; i++) {
	widthSelectOptions.push({
		value: i.toString(),
		label: i.toString(),
		price: i * 10,
	});
}

const lengthSelectOptions = [];
for (let i = 21; i <= 101; i++) {
	lengthSelectOptions.push({
		value: i.toString(),
		label: i.toString(),
		price: i * 10,
	});
}

const heightSelectOptions = [
	{ value: "6", label: "6", price: 500 },
	{ value: "7", label: "7", price: 525 },
	{ value: "8", label: "8", price: 550 },
	{ value: "9", label: "9", price: 575 },
	{ value: "10", label: "10", price: 600 },
	{ value: "11", label: "11", price: 625 },
	{ value: "12", label: "12", price: 650 },
	{ value: "13", label: "13", price: 675 },
	{ value: "14", label: "14", price: 700 },
	{ value: "15", label: "15", price: 725 },
	{ value: "16", label: "16", price: 750 },
	{ value: "17", label: "17", price: 775 },
	{ value: "18", label: "18", price: 800 },
	{ value: "19", label: "19", price: 825 },
	{ value: "20", label: "20", price: 850 },
];

const windSnowSelectOptions = [
	{ value: "Select", label: "Select" },
	{ value: "140mph", label: "140 MPH + 30 PSF Certified" },
];


const BuiltSize = () => {
	const [doubleLegBaserail, setDoubleLegBaserail] = useState(false);
	const [upgradeGauge, setUpgradeGauge] = useState(false);

	const GlobalStore = useContext(GlobalState);
	const scene = GlobalStore.sceneGlobalRef.current;
	//main structure helpers (controlers)
	const mainParent_R = scene.getMeshByName("Main_Helper_R");
	const mainParent_L = scene.getMeshByName("Main_Helper_L");
	//main dimensionHelper
	const dimensionHelper_M = scene.getMeshByName("dimensionHelper_M");
	//leans helpers (controlers)
	const leftLeanParent = scene.getMeshByName("Lean_Left_Helper");
	const rightLeanParent = scene.getMeshByName("Lean_Right_Helper");
	//roof materials
	const roofMain = scene.getMaterialByName("outerRoof_M");
	const roofLeft = scene.getMaterialByName("outerRoof_LL");
	const roofRight = scene.getMaterialByName("outerRoof_RL");
	//scene frameBases
	const mainFrameBase_R = scene.getMeshByName("Standard Garage (frame)_001");
	const mainFrameBase_L = scene.getMeshByName("Standard Garage (frame)_00");
	//references for calculation of the height
	const wallHeightReference = scene.getMeshByName("Profile_004");
	const groundRef = scene.getMeshByName("Cylinder001");
	//wall references
	const mainFrontWalls_R = scene.getTransformNodeByName("frontWalls_R");
	const mainFrontWalls_L = scene.getTransformNodeByName("frontWalls_L");
	const mainBackWalls_R = scene.getTransformNodeByName("backWalls_R");
	const mainBackWalls_L = scene.getTransformNodeByName("backWalls_L");
	const mainSideWalls_R = scene.getTransformNodeByName("sideWalls_R");
	const mainSideWalls_L = scene.getTransformNodeByName("sideWalls_L");
	//storage references
	let mainStorage_R;
	mainParent_R.getChildMeshes().map(mesh => {
		if (mesh.name === "storage_main_R") {
			mainStorage_R = mesh;
		}
		return 0
	})
	let mainStorage_L;
	mainParent_L.getChildMeshes().map(mesh => {
		if (mesh.name === "storage_main_L") {
			mainStorage_L = mesh;
		}
		return 0
	})
	const leftStorage = scene.getMeshByName("storage_lean_L");
	const leftSideWallStorage = scene.getMeshByName("storage_leanL_sideWall");
	const rightStorage = scene.getMeshByName("storage_lean_R");
	const rightSideWallStorage = scene.getMeshByName("storage_leanR_sideWall");
	//wainscot references
	const wainscot_B_L = scene.getMeshByName("wainscot_B_L");
	const wainscot_F_L = scene.getMeshByName("wainscot_F_L");
	const wainscot_S_L = scene.getMeshByName("wainscot_S_L");
	const wainscot_B_R = scene.getMeshByName("wainscot_B_R");
	const wainscot_F_R = scene.getMeshByName("wainscot_F_R");
	const wainscot_S_R = scene.getMeshByName("wainscot_S_R");
	//create and define current indexes of the dropdown menus
	const roofPitchIndex = useRef(null);
	const heightIndex = useRef(null);
	const widthIndex = useRef(null);
	const lengthIndex = useRef(null);

	//get current buildingType data
	const presetData = GlobalStore.buildingType.customizationData.mainStructure;
	//find the data indexes inside the selectOptions 
	widthIndex.current = widthSelectOptions.findIndex(x => x.value === presetData.width);
	heightIndex.current = heightSelectOptions.findIndex(x => x.value === presetData.height);
	lengthIndex.current = lengthSelectOptions.findIndex(x => x.value === presetData.length);
	roofPitchIndex.current = roofPitchSelectOptions.findIndex(x => x.value === presetData.roofPitch);
	//on init 
	useEffect(() => {
		handleSizeHeight(presetData.height, null, true)
		handleSizeLength(presetData.length, null, true)
		handleRoofPitch(presetData.roofPitch, null, true)
		handleSizeWidth(presetData.width, null, true)

		//recreate reference planes
		recreatePlanes("main", null, "dimensionHelper_M", GlobalStore, ["front", "back", "left", "right"], scene);

		scene.meshes.map((mesh) => {
			if (mesh.bindedTo?.indexOf("right") > -1 || mesh.bindedTo?.indexOf("left") > -1) {
				const referencePlane = scene.getMeshByName(mesh.bindedTo);
				referencePlane.computeWorldMatrix(true);
				mesh.position.z = referencePlane.position.z / 10;
			}
			return 0
		});
		//create new set of gui elements with proper dimensions
		createMeasurementsGUI(GlobalStore, scene, "global");
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	//
	//handle doublelegbasterail
	const handleDoubleLegBasetrailChange = () => {
		setDoubleLegBaserail(!doubleLegBaserail);
	};
	//handle upgrageGague
	const handleUpgradeGaugeChange = () => {
		setUpgradeGauge(!upgradeGauge);
	};
	//handle roof panels selection
	const handleRoofPanels = (index) => {
		switch (index) {
			case 0:
				roofMain.bumpTexture.wAng = 0;
				roofLeft.bumpTexture.wAng = 0;
				roofRight.bumpTexture.wAng = 0;
				break;
			case 1:
				roofMain.bumpTexture.wAng = 1.57;
				roofLeft.bumpTexture.wAng = 1.57;
				roofRight.bumpTexture.wAng = 1.57;
				break;
			default:
				break;
		}
	}
	//handle roofPitch on input change
	const handleRoofPitch = (targetValue, inputName, init) => {
		//clear measurements GUI elements
		GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
		GlobalStore.currentDimensionElements.current = [];
		switch (targetValue) {
			case "2/12":
				switchMorphLevel([mainParent_R, mainParent_L], 0);
				GlobalStore.mainMorphLevel.current = 0;
				break;
			case "3/12":
				switchMorphLevel([mainParent_R, mainParent_L], 0.33);

				GlobalStore.mainMorphLevel.current = 0.33;
				break;
			case "4/12":
				switchMorphLevel([mainParent_R, mainParent_L], 0.66);

				GlobalStore.mainMorphLevel.current = 0.66;
				break;
			case "5/12":
				switchMorphLevel([mainParent_R, mainParent_L], 1);

				GlobalStore.mainMorphLevel.current = 1;
				break;
			default:
				console.log("targetValue does not exist");
				break;
		}
		GlobalStore.setMainRoofPitch(targetValue)
		if (!init) {
			//create new set of gui elements with proper dimensions		
			createMeasurementsGUI(GlobalStore, scene, "main", ["roofPitch", targetValue]);
		}

	};
	//sets clipPlanes on the meshes in the scene (used for setting planes that control width of the mesh)
	const setWidthClipPlanes = (parents, clipPlane) => {
		parents.map((parent, i) => {
			parent.getChildMeshes().map((child) => {
				child.onBeforeRenderObservable.add(function () {
					scene.clipPlane = clipPlane[i];
				});
				child.onAfterRenderObservable.add(function () {
					scene.clipPlane = null;
				});
				return true;
			});
			return true;
		});
	};
	//handle object width on input change
	const handleSizeWidth = (targetValue, inputName, init) => {
		//disconnect from parent 
		dimensionHelper_M.setParent(null);
		//setParent (disconnected on height change)
		mainFrameBase_R.setParent(mainParent_R);
		mainFrameBase_L.setParent(mainParent_L);

		//position the meshes properly
		mainParent_R.position.z = GlobalStore.main_R_startPosZ.current + (targetValue / 60 - 1) * 7.5;
		mainParent_L.position.z = GlobalStore.main_L_startPosZ.current - (targetValue / 60 - 1) * 7.5;
		leftLeanParent.position.z = GlobalStore.lean_L_startPosZ.current - GlobalStore.leftWidthOffset.current - (targetValue / 60 - 1) * 7.5;
		rightLeanParent.position.z = GlobalStore.lean_R_startPosZ.current - GlobalStore.rightWidthOffset.current + (targetValue / 60 - 1) * 7.5;
		GlobalStore.mainWidthOffset.current = GlobalStore.main_R_startPosZ.current - mainParent_R.position.z;

		//the value that represents how much the width is scaled down or up.
		const widthScaleFactor = (GlobalStore.lean_R_startPosZ.current + (targetValue / 60 - 1) * 7.5) / GlobalStore.lean_R_startPosZ.current;
		dimensionHelper_M.scaling.z = GlobalStore.dimensionHelper_M_scalingZ.current * widthScaleFactor;
		//define clipPlanes and clipDistance (from 0,0,0)
		mainParent_R.computeWorldMatrix(true);
		const clipDistance = mainParent_R.getBoundingInfo().boundingBox.maximumWorld.z;
		GlobalStore.leanClipPlaneDistance.current = clipDistance;
		//clear measurements GUI elements
		GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
		GlobalStore.currentDimensionElements.current = [];
		GlobalStore.setMainWidth(targetValue);
		//define clip planes for width 
		const clipPlane_LLW = new Plane(0, 0, 1, GlobalStore.leanClipPlaneDistance.current);
		const clipPlane_LRW = new Plane(0, 0, -1, GlobalStore.leanClipPlaneDistance.current);
		//set clipPlanes
		setWidthClipPlanes([leftLeanParent, rightLeanParent], [clipPlane_LLW, clipPlane_LRW]);
		if (!init) {
			//recreate reference planes
			recreatePlanes("main", null, "dimensionHelper_M", GlobalStore, ["front", "back", "left", "right"], scene);
			recreatePlanes("leftLean", mainParent_L, "dimensionHelper_L", GlobalStore, ["front_L", "back_L", "left_L"], scene);
			recreatePlanes("rightLean", mainParent_R, "dimensionHelper_R", GlobalStore, ["front_R", "back_R", "right_R"], scene);
			//I HAVE NO IDEA WHAT THIS IS!!!
			scene.meshes.map((mesh) => {
				if (mesh.bindedTo?.indexOf("right") > -1 || mesh.bindedTo?.indexOf("left") > -1) {
					const referencePlane = scene.getMeshByName(mesh.bindedTo);
					referencePlane.computeWorldMatrix(true);
					mesh.position.z = referencePlane.position.z / 10;
				}
				return 0
			});
			//create new set of gui elements with proper dimensions
			createMeasurementsGUI(GlobalStore, scene, "main", ["width", targetValue]);
		}
		//update price
		handlePriceValue(targetValue, GlobalStore.currentWidthPrice_main.current, widthSelectOptions, GlobalStore);
	};
	//handle object length on input change
	const handleSizeLength = (targetValue, inputName, init) => {
		//set parents
		dimensionHelper_M.setParent(mainParent_R);
		mainFrameBase_R.setParent(mainParent_R);
		mainFrameBase_L.setParent(mainParent_L);
		mainStorage_R.setParent(null)
		mainStorage_L.setParent(null)
		//scale the mesh length
		mainParent_R.scaling.x = targetValue / 21;
		mainParent_L.scaling.x = targetValue / 21;
		//calculate clip planes
		mainParent_R.computeWorldMatrix(true);
		const storagePlaneDistance = mainParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
		GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;
		//set parent
		mainStorage_R.setParent(mainParent_R);
		mainStorage_L.setParent(mainParent_L);
		//set position of storage (default position)
		mainStorage_R.setAbsolutePosition(storagePlaneDistance, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
		mainStorage_L.setAbsolutePosition(storagePlaneDistance, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
		//translate storage depending on the selected value
		if (GlobalStore.storageMain) {
			mainStorage_R.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
			mainStorage_L.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
		}
		//create clip plane
		const clipPlaneStorage = new Plane(-1, 0, 0, storagePlaneDistance + 0.5);
		//assign clip planes
		mainStorage_R.onBeforeRenderObservable.add(function () {
			scene.clipPlane2 = clipPlaneStorage;
		});
		mainStorage_R.onAfterRenderObservable.add(function () {
			scene.clipPlane2 = null;
		});
		mainStorage_L.onBeforeRenderObservable.add(function () {
			scene.clipPlane2 = clipPlaneStorage;
		});
		mainStorage_L.onAfterRenderObservable.add(function () {
			scene.clipPlane2 = null;
		});
		//clear measurements GUI elements
		GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
		GlobalStore.currentDimensionElements.current = [];
		//remove pre-existing frames in the scene
		GlobalStore.clonedFramesMain.current.map((arrayOfMeshes) => {
			arrayOfMeshes.map((mesh) => mesh.dispose());
			return true;
		});
		//reset the frame array
		GlobalStore.clonedFramesMain.current = [];
		GlobalStore.setMainLength(targetValue)
		//create clip planes
		const clipPlane_R = new Plane(0, 0, -1, 0);
		const clipPlane_L = new Plane(0, 0, 1, 0);
		//recreate frames
		regenerateFrames([mainParent_R, mainParent_L], [clipPlane_R, clipPlane_L], GlobalStore.clonedFramesMain, targetValue / 21, scene);

		if (!init) {
			//recreate reference planes
			recreatePlanes("main", null, "dimensionHelper_M", GlobalStore, ["front", "back", "left", "right"], scene);

			scene.meshes.map((mesh) => {
				if (mesh.bindedTo === "referencePlane_front" || mesh.bindedTo === "referencePlane_back") {
					const referencePlane = scene.getMeshByName(mesh.bindedTo);
					referencePlane.computeWorldMatrix(true);
					mesh.position.x = -referencePlane.position.x / 10;
				}
				return 0;
			});

			//create new set of gui elements with proper dimensions		
			createMeasurementsGUI(GlobalStore, scene, "main", ["length", targetValue]);
		}
		//update price
		handlePriceValue(
			targetValue,
			GlobalStore.currentLengthPrice_main.current,
			lengthSelectOptions,
			GlobalStore
		);
	};
	const handleSizeHeight = (targetValue, inputName, init) => {
		//main structure wainscot meshes
		const wainscots = [wainscot_B_L, wainscot_F_L, wainscot_S_L, wainscot_B_R, wainscot_F_R, wainscot_S_R];
		//set parents to null, but define parents that will be reparented later
		wainscots.map(wainscot => wainscot.setParent(null))

		dimensionHelper_M.setParent(mainParent_R);
		//set parents
		mainFrameBase_R.setParent(null);
		mainFrameBase_L.setParent(null);
		leftSideWallStorage.setParent(mainParent_R);
		rightSideWallStorage.setParent(mainParent_R);
		//set parents height
		mainParent_R.position.y = GlobalStore.main_R_startPosY.current + (targetValue / 9 - 1) * 3.5;
		mainParent_L.position.y = GlobalStore.main_L_startPosY.current + (targetValue / 9 - 1) * 3.5;
		leftSideWallStorage.setParent(leftStorage);
		rightSideWallStorage.setParent(rightStorage);
		//set wainscot parents

		wainscot_B_L.setParent(mainBackWalls_L)
		wainscot_F_L.setParent(mainFrontWalls_L)
		wainscot_S_L.setParent(mainSideWalls_L)
		wainscot_B_R.setParent(mainBackWalls_R)
		wainscot_F_R.setParent(mainFrontWalls_R)
		wainscot_S_R.setParent(mainSideWalls_R)

		//clear measurements GUI elements
		GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
		GlobalStore.currentDimensionElements.current = [];

		//calculate bounds
		wallHeightReference.computeWorldMatrix(true);
		const groundHeightY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y;
		const wallHeightY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
		//set clip planes
		mainEndsWallClipPlanesChange(
			GlobalStore.selectedEndFront,
			[wallHeightY, groundHeightY],
			[mainFrontWalls_R, mainFrontWalls_L],
			scene
		);
		mainEndsWallClipPlanesChange(
			GlobalStore.selectedEndBack,
			[wallHeightY, groundHeightY],
			[mainBackWalls_R, mainBackWalls_L],
			scene
		);
		mainSidesWallClipPlanesChange(
			GlobalStore.selectedSidesLeft,
			[wallHeightY, groundHeightY],
			[mainSideWalls_L],
			scene
		);
		mainSidesWallClipPlanesChange(
			GlobalStore.selectedSidesRight,
			[wallHeightY, groundHeightY],
			[mainSideWalls_R],
			scene
		);

		//set lean wall height constraint 
		GlobalStore.setLeanWallHeightConstraint(targetValue - 3);
		GlobalStore.setMainHeight(targetValue)
		if (!init) {
			//recreate reference planes
			recreatePlanes("main", null, "dimensionHelper_M", GlobalStore, ["front", "back", "left", "right"], scene);
			//create new set of gui elements with proper dimensions
			createMeasurementsGUI(GlobalStore, scene, "main", ["height", targetValue]);
		}
		//uptade price
		handlePriceValue(
			targetValue,
			GlobalStore.currentHeightPrice_main.current,
			heightSelectOptions,
			GlobalStore
		);
	};

	return (
		<BuiltSizeScrollbarWrapper>
			<BuiltSizeWrapper>
				<SectionTitle title={"Building Name"} />
				<TextField
					id="filled-read-only-input"
					label="Building Name"
					defaultValue="Standard Carports"
					fullWidth
					InputProps={{
						readOnly: true,
					}}
				/>
				<Separator />
				<SectionTitle title={"Roof"} />
				<InputBoxes onChange={handleRoofPanels} options={roofData} />
				<InputSelect
					onChange={handleRoofPitch}
					label="Roof Pitch"
					options={roofPitchSelectOptions}
					inputName="built-size-roof-pitch"
					selectedOptionIndex={roofPitchIndex.current}
				/>
				<Separator />
				<SectionTitle title={"Size"} />
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<InputSelect
							onChange={handleSizeWidth}
							label="Width"
							options={widthSelectOptions}
							inputName="built-size-width"
							selectedOptionIndex={widthIndex.current}
						/>
					</Grid>
					<Grid item xs={6}>
						<InputSelect
							onChange={handleSizeLength}
							label="Length"
							options={lengthSelectOptions}
							inputName="built-size-length"
							selectedOptionIndex={lengthIndex.current}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputSelect
							onChange={handleSizeHeight}
							label="Height"
							options={heightSelectOptions}
							inputName="built-size-height"
							selectedOptionIndex={heightIndex.current}
						/>
					</Grid>
				</Grid>
				<Separator />
				<FormControlLabel
					control={
						<Checkbox
							checked={doubleLegBaserail}
							onChange={handleDoubleLegBasetrailChange}
							name="double-leg-baserail"
							color="primary"
						/>
					}
					style={{ color: "#222" }}
					label="Double Leg Baserail"
				/>
				<Separator />
				<SectionTitle title={"Gauge & Wind/Snow"} />
				<FormControlLabel
					control={
						<Checkbox
							checked={upgradeGauge}
							onChange={handleUpgradeGaugeChange}
							name="upgrade-gauge"
							color="primary"
						/>
					}
					style={{ color: "#222" }}
					label="Upgrade To 12` Gauge"
				/>
				<Separator />
				<InputSelect
					label="Wind/Snow"
					options={windSnowSelectOptions}
					inputName="built-size-wind-snow"
				/>
			</BuiltSizeWrapper>
		</BuiltSizeScrollbarWrapper>
	);
};

export default BuiltSize;
