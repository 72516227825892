import React from "react";
import { SummaryWrapper } from "./Summary.css";

import Scrollbar from "react-scrollbars-custom";

const Summary = () => {
	return (
		<Scrollbar
			style={{ width: 340, height: "calc(var(--vh, 1vh)*100 - 250px)" }}
		>
			<SummaryWrapper>summary</SummaryWrapper>
		</Scrollbar>
	);
};

export default Summary;
