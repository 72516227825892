import { React, useContext, useEffect } from 'react'
import {
    ColorsScrollbarWrapper,
    ColorsWrapper
} from './Colors.css'

import ColorPicker from '../parts/ColorPicker/ColorPicker'
import Separator from '../../../utils/Separator/Separator'
import EntryContent from '../../EntryContent/EntryContent'
import SectionTitle from '../../SectionTitle/SectionTitle'

import Scrollbar from 'react-scrollbars-custom'
import { GlobalState } from '../../../App';
import { Color3 } from '@babylonjs/core';

import { colorsOptionsPremium, colorsOptionsWhite } from '../parts/ColorPicker/ColorPickerData.json'

import buildingTypesData from '../../BuildingType/BuildingTypes.json';

const Colors = () => {
    const GlobalStore = useContext(GlobalState);




    const indexOfPresetData = buildingTypesData.findIndex(x => x.id === GlobalStore.buildingType.id)
    const presetData = buildingTypesData[indexOfPresetData].customizationData.materials;
    const roofColor = {
        colorName: "",
        value: buildingTypesData[indexOfPresetData].customizationData.materials.roofMaterialValue
    }
    const wallColor = {
        colorName: "",
        value: buildingTypesData[indexOfPresetData].customizationData.materials.wallMaterialValue
    }

    useEffect(() => {
        handleRoofColor(roofColor, 1)
        handleWallColor(wallColor);
    }, [])

    const handleRoofColor = (color, index) => {
        const roofMaterials = GlobalStore.outerRoofMaterials.current;
        roofMaterials.map(mat => {
            mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
            return 0;
        })
    }

    const handleTrimColor = (color, index) => {
        const trimMaterials = GlobalStore.outerTrimMaterials.current;
        trimMaterials.map(mat => {
            mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
            return 0;
        })
    }

    const handleWallColor = (color) => {
        const wallMaterials = GlobalStore.outerWallsMaterials.current;
        wallMaterials.map(mat => {
            mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
            return 0;
        })
    }

    return (
        <ColorsScrollbarWrapper>
            <ColorsWrapper>
                <ColorPicker onChange={handleRoofColor} title="Roof" colorsOptions={colorsOptionsPremium} checked={0} />
                <Separator marginBotom="25px" />
                <ColorPicker onChange={handleTrimColor} title="Trim" colorsOptions={colorsOptionsPremium} checked={0} />
                <Separator marginBotom="25px" />
                <ColorPicker onChange={handleWallColor} title="Wall" colorsOptions={colorsOptionsPremium} checked={GlobalStore.wallsColor} />
                <Separator marginBotom="25px" />
                <ColorPicker title="Door" colorsOptions={colorsOptionsWhite} checked={0} />
                <Separator marginBotom="25px" />
                <SectionTitle title="Disclamer" />
                <EntryContent content="<p>Due to the variations in monitors and browsers, the color samples displayed on this tool may appear different on different monitors and devices.</p><p>Computer monitors are not all calibrated equally and color reproduction on the Internet is not precise. Since it is not possible to guarantee our online colors will look the same on all computers, we do not guarantee that what you see accurately portrays the color of the actual color of the Sheet Metal.</p><p>We do our very best to make sure our samples are as close to the exact product as possible, but cannot guarantee that what you see is an exact sample. If it is important that the sample be exact, it is highly recommended that once you contact us.</p>" />
            </ColorsWrapper>
        </ColorsScrollbarWrapper>
    )
}

export default Colors
