import styled from 'styled-components/macro';

export const ColorPickerWrapper = styled.div`
    @media (max-width: 991px) {
        max-width: 520px;
        width: 100%;
    }
`;

export const ColorPickerTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

export const ColorPickerTitle = styled.span`
    position: relative;
    color: #222;
    font-size: 13px;

    &::after {
        content: "";
        width: 18px;
        height: 3px;
        position: absolute;
        bottom: -5px;
        background: #eb7324;
        left: 0;
    }
`;

export const ColorPickerValue = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 100%;
    margin: 0 5px;
    background-color: ${props => props.color ? props.color.value : '#fff'};
`;

export const ColorPickerName = styled.div`
    font-size: 14px;
    color: #222;
`;

export const ColorPickerOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ColorPickerOptionsItem = styled.span`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 36px;
    padding: 0;
    margin-right: 4%;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: ${props => props.color ? props.color : '#fff'};
    cursor: pointer;

    &:nth-child(4n) {
        margin-right: 0;
    }

    @media (max-width: 991px) {
        width: 55px;
        height: 36px;
        &:nth-child(4n) {
            margin-right: 4%;
        }
    }
`;

export const ColorPickerOptionsItemText = styled.span`
    font-size: 8px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: .5px;
`;