import styled from 'styled-components/macro'

export const DraggableBoxWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 330px;
    cursor: move;
    pointer-events: none;
    z-index: 1;
`

export const DraggableBoxBody = styled.div`
    padding: 20px;
    background-color: #fff;
    pointer-events: all;
`

export const DraggableBoxFooter = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #fff;
`

export const DraggableBoxIconDrag = styled.span`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -14px);
    background-color: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    pointer-events: all;

    svg {
        width: 100%;
    }
`

export const DraggableBoxFooterBtn = styled.span`
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    cursor: pointer;
    pointer-events:all;
    &:first-child {
        margin-right: 25px;
    }
`

export const DraggableBoxFooterBtnPill = styled(DraggableBoxFooterBtn)`
    background-color: #000;
    color: #fff;
    padding: 5px 15px;
    margin-left: auto;
    border-radius: 40px;
    transition: 0.3s ease-in;

    &:hover {
        background-color: #eb7324;
    }
`

export const ModalDraggableBox = styled.div`
    text-align: center;
    max-width: 660px;
    width: 100%;
`

export const ModalDraggableBoxTitle = styled.h5`
    margin-top: 0;
    margin-bottom: 45px;
    color: #222;
    font-size: 22px;
    text-transform: uppercase;
`

export const ModalDraggableBoxText = styled.p`
    margin-bottom: 45px;
    font-size: 15px;
    color: #222;
    word-break: break-word;
    white-space: normal;
`

export const ModalDraggableBoxNavigation = styled.div`
    display: flex;
    justify-content: center;
`

export const ModalDraggableBoxNavigationButton = styled.span`
    display: inline-flex;
    border: 1px solid #222;
    border-radius: 52px;
    padding: .75rem 1.25rem;
    margin: 0 1rem;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;
    min-width: 100px;
    cursor: pointer;
`

export const ModalDraggableBoxNavigationButtonSolid = styled(ModalDraggableBoxNavigationButton)`
    background-color: #222;
    color: #fff;
`