import React, { useContext } from 'react'
import { HeaderWrapper } from './Header.css'

import Logo from '../../components/Logo/Logo'
import InfoBar from '../../components/InfoBar/InfoBar';

import { GlobalState } from '../../App';

const Header = () => {
    let GlobalStore = useContext(GlobalState);
    return (
        <HeaderWrapper active={GlobalStore.buildingType}>
            <Logo />
            <InfoBar />
        </HeaderWrapper>
    )
}

export default Header
