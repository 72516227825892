import styled from 'styled-components/macro';

export const HeaderWrapper = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background-color: ${props => props.active ? '#fff' : 'transparent'};
    box-shadow: ${props => props.active ? '0 1px 2px rgba(0,17,47,.2)' : 'none'};
    .InfoBarItemLabel {
        color: #222;
    }
`;