import React, { useState, useRef } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "./components/Header/Header";

import { State as StateForm } from "./components/Forms/State/State";
import BuildingType from "./components/BuildingType/BuildingType";
import Build from "./components/Build/Build";
import Canvas from "./components/Canvas/Canvas";
import DraggableBox from "./components/DraggableBox/DraggableBox";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import Spinner from "./components/Spinner/Spinner";

import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
}

const breakpoints = createBreakpoints({ ...customBreakpointValues })

export const GlobalState = React.createContext();

const theme = createMuiTheme({
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: {
    primary: {
      main: "#eb7324", // This is an orange looking color
      dark: "#222",
    },
    secondary: {
      main: "#eb7324", //Another orange-ish color
    },
    text: {
      primary: "#222",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiInputLabel: {
      root: {},
      formControl: {
        // Some CSS
        left: "8px",
        fontWeight: "bold",
      },
      shrink: {
        top: "6px",
      },
    },
    MuiInputBase: {
      formControl: {
        padding: "0 8px",
      },
    },
    MuiSelect: {
      root: {
        width: "100%",
      },
      select: {
        fontWeight: "500",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "white",
        [breakpoints.down('lg')]: {
          color: '#eb7324',
        },
      },
    },
    MuiButton: {
      root: {
        padding: "15px 30px",
        backgroundColor: "#fff",
        border: "2px #fff solid",
        borderRadius: "40px",
        textAlign: "center",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: "16px",
        letterSpacing: "normal",
        transition: "all 0.3s ease-in-out",
        cursor: "pointer",
        lineHeight: 1,
        "&:hover": {
          opacity: "0.9",
          boxShadow: "1px 3px 20px rgba(0, 0, 0, 0.3)",
        },
      },
      contained: {},
      outlinedSecondary: {
        backgroundColor: "#222",
        color: "#fff",
        border: "2px solid #222",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  },
});

function App() {
  const [state, setState] = useState(null); //from state form
  const [isLoading, setIsLoading] = useState(true);
  const [buildingType, setBuildingType] = useState(null); //from building type component form
  //state of canvas visibility
  const [canvasStyle, setCanvasStyle] = useState("hidden");
  //states of the camera
  const [cameraRadius, setCameraRadius] = useState(200);
  const cameraStateRadius = useRef(200);
  const [cameraRotating, setCameraRotating] = useState(false);
  const [fullView, setFullView] = useState(false);
  const [backgroundHidden, setBackgroundHidden] = useState(false);
  //scene reference (3D context of the whole scene (objects/materials....))
  const sceneGlobalRef = useRef(null);
  //Starting references for structures (used for calculation of structure position depending on the user dimensions input (WxHxL))
  const main_R_startPosZ = useRef(null);
  const main_L_startPosZ = useRef(null);
  const main_R_startPosY = useRef(null);
  const main_L_startPosY = useRef(null);
  const lean_R_startPosZ = useRef(null);
  const lean_L_startPosZ = useRef(null);
  const lean_R_startPosY = useRef(null);
  const lean_L_startPosY = useRef(null);
  //offsets (used for calculation of helper elements position depending on the width of the structures)
  const mainWidthOffset = useRef(0);
  const leftWidthOffset = useRef(0);
  const rightWidthOffset = useRef(0);
  //clonedFrames (used for generating frames depending on the length of the structures)
  const clonedFramesMain = useRef([]);
  const clonedFramesLeanR = useRef([]);
  const clonedFramesLeanL = useRef([]);
  //clip distances of the leanTo's (used for calculation of cliping planes for lean tos when width of the main structure is changed)
  const leanClipPlaneDistance = useRef(81);
  //wall meshes references
  const mainWallMeshes = useRef([]);
  const leftLeanWallMeshes = useRef([]);
  const rightLeanWallMeshes = useRef([]);
  //state of walls selections (front back side walls, open/enclosed?)
  //main
  const [selectedEndFront, setSelectedEndFront] = useState("Open");
  const [selectedEndBack, setSelectedEndBack] = useState("Open");
  const [selectedSidesLeft, setSelectedSidesLeft] = useState("Open");
  const [selectedSidesRight, setSelectedSidesRight] = useState("Open");
  //right lean
  const [selectedEndFront_RL, setSelectedEndFront_RL] = useState("Open");
  const [selectedEndBack_RL, setSelectedEndBack_RL] = useState("Open");
  const [selectedSidesRight_RL, setSelectedSidesRight_RL] = useState("Open");
  //left lean
  const [selectedEndFront_LL, setSelectedEndFront_LL] = useState("Open");
  const [selectedEndBack_LL, setSelectedEndBack_LL] = useState("Open");
  const [selectedSidesLeft_LL, setSelectedSidesLeft_LL] = useState("Open");
  //state of price and deposit
  const [price, setPrice] = useState(2800);
  const [deposit, setDeposit] = useState(850);
  //pricing for dimension configuration
  //main structure
  const currentWidthPrice_main = useRef(600);
  const currentHeightPrice_main = useRef(500);
  const currentLengthPrice_main = useRef(400);
  //lean tos
  const currentWidthPrice_rightLean = useRef(200);
  const currentHeightPrice_rightLean = useRef(300);
  const currentLengthPrice_rightLean = useRef(400);
  const currentWidthPrice_leftLean = useRef(200);
  const currentHeightPrice_leftLean = useRef(300);
  const currentLengthPrice_leftLean = useRef(400);
  //pricing for walls
  const currentFrontWallsPrice = useRef(10);
  const currentBackWallsPrice = useRef(10);
  const currentLeftSideWallsPrice = useRef(10);
  const currentRightSideWallsPrice = useRef(10);
  //constrain of lean tos dropdown dimension based on main structure dimensions
  const [leanWallHeightConstraint, setLeanWallHeightConstraint] = useState(6);
  //reference planes used for calculation of auto selecting part of the warehouse
  const cameraDistanceReferencePlanes = useRef([]);
  //check if leans are active or not
  const isLeftLeanActive = useRef(false);
  const isRightLeanActive = useRef(false);
  //SCALINGS OF DIMENSIONHELPER (used for positioning support elements (reference planes, clipplanes...))
  const dimensionHelper_M_scalingZ = useRef(null);
  const dimensionHelper_R_scalingZ = useRef(null);
  const dimensionHelper_L_scalingZ = useRef(null);
  //MATERIAL REFS
  const outerWallsMaterials = useRef([]);
  const outerRoofMaterials = useRef([]);
  const outerTrimMaterials = useRef([]);
  //there are 4 color pickers which set the wall color, this controls state of all of them
  const [wallsColor, setWallsColor] = useState(0);
  //draggable is open?
  const [draggableIsOpen, setDraggableIsOpen] = useState(false);
  //modal is open?
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //45 angle enabled?
  const [haveAngle, setHaveAngle] = useState(false);
  //list of the doors in the scene
  const currentDoors = useRef([]);
  //door options depending on the type of the door
  const [doorOptions, setDoorOptions] = useState(null);
  //wainscot
  const [wainscot, setWainscot] = useState(false);
  const wainscotMeshes = useRef([])
  const [wainscotColor, setWainscotColor] = useState(0);
  //morph data
  const mainMorphLevel = useRef(0);
  //STORAGE
  //is storage on?
  //main
  const [storageMain, setStorageMain] = useState(false);
  //left
  const [storageLeft, setStorageLeft] = useState(false);
  //right
  const [storageRight, setStorageRight] = useState(false);
  //storage translations and clip plane position
  const mainLengthFromCenter = useRef(0)
  const mainStorageTranslation = useRef(5);
  const leanRLengthFromCenter = useRef(0);
  const leanRStorageTranslation = useRef(5);
  const leanLLengthFromCenter = useRef(0);
  const leanLStorageTranslation = useRef(5);
  //measurement gui
  const currentDimensionElements = useRef([])
  //is measure on?
  const [isMeasure, setIsMeasure] = useState(false);
  //default customization data
  const [mainWidth, setMainWidth] = useState("60");
  const [mainLength, setMainLength] = useState("21");
  const [mainHeight, setMainHeight] = useState("9");
  const [mainRoofPitch, setMainRoofPitch] = useState("2/12");

  const [leanRWidth, setLeanRWidth] = useState("16");
  const [leanRHeight, setLeanRHeight] = useState("6");
  const [leanRLength, setLeanRLength] = useState("21");
  const [leanRRoofPitch, setLeanRRoofPitch] = useState("2/12");

  const [leanLWidth, setLeanLWidth] = useState("16");
  const [leanLHeight, setLeanLHeight] = useState("6");
  const [leanLLength, setLeanLLength] = useState("21");
  const [leanLRoofPitch, setLeanLRoofPitch] = useState("2/12");

  return (
    <ThemeProvider theme={theme}>
      <GlobalState.Provider
        value={{
          state,
          setState,
          buildingType,
          setBuildingType,
          canvasStyle,
          setCanvasStyle,
          cameraRadius,
          setCameraRadius,
          cameraStateRadius,
          cameraRotating,
          setCameraRotating,
          fullView,
          setFullView,
          backgroundHidden,
          setBackgroundHidden,
          sceneGlobalRef,
          main_R_startPosZ,
          main_L_startPosZ,
          main_R_startPosY,
          main_L_startPosY,
          lean_R_startPosZ,
          lean_L_startPosZ,
          lean_R_startPosY,
          lean_L_startPosY,
          clonedFramesMain,
          clonedFramesLeanR,
          clonedFramesLeanL,
          leanClipPlaneDistance,
          mainWallMeshes,
          rightLeanWallMeshes,
          leftLeanWallMeshes,
          selectedEndFront,
          setSelectedEndFront,
          selectedEndBack,
          setSelectedEndBack,
          selectedSidesLeft,
          setSelectedSidesLeft,
          selectedSidesRight,
          setSelectedSidesRight,
          selectedEndFront_RL,
          setSelectedEndFront_RL,
          selectedEndBack_RL,
          setSelectedEndBack_RL,
          selectedSidesRight_RL,
          setSelectedSidesRight_RL,
          selectedEndFront_LL,
          setSelectedEndFront_LL,
          selectedEndBack_LL,
          setSelectedEndBack_LL,
          selectedSidesLeft_LL,
          setSelectedSidesLeft_LL,
          price,
          setPrice,
          deposit,
          setDeposit,
          currentWidthPrice_main,
          currentHeightPrice_main,
          currentLengthPrice_main,
          currentWidthPrice_rightLean,
          currentHeightPrice_rightLean,
          currentLengthPrice_rightLean,
          currentWidthPrice_leftLean,
          currentHeightPrice_leftLean,
          currentLengthPrice_leftLean,
          currentFrontWallsPrice,
          currentBackWallsPrice,
          currentLeftSideWallsPrice,
          currentRightSideWallsPrice,
          leanWallHeightConstraint,
          setLeanWallHeightConstraint,
          mainWidthOffset,
          leftWidthOffset,
          rightWidthOffset,
          cameraDistanceReferencePlanes,
          isLeftLeanActive,
          isRightLeanActive,
          dimensionHelper_M_scalingZ,
          dimensionHelper_R_scalingZ,
          dimensionHelper_L_scalingZ,
          outerWallsMaterials,
          outerRoofMaterials,
          outerTrimMaterials,
          wallsColor,
          setWallsColor,
          draggableIsOpen,
          setDraggableIsOpen,
          modalIsOpen,
          setModalIsOpen,
          haveAngle,
          setHaveAngle,
          currentDoors,
          doorOptions,
          setDoorOptions,
          storageMain,
          setStorageMain,
          storageLeft,
          setStorageLeft,
          storageRight,
          setStorageRight,
          wainscot,
          setWainscot,
          wainscotMeshes,
          wainscotColor,
          setWainscotColor,
          mainMorphLevel,
          mainLengthFromCenter,
          mainStorageTranslation,
          leanRLengthFromCenter,
          leanRStorageTranslation,
          leanLLengthFromCenter,
          leanLStorageTranslation,
          currentDimensionElements,
          isMeasure,
          setIsMeasure,
          mainWidth,
          setMainWidth,
          mainLength,
          setMainLength,
          mainHeight,
          setMainHeight,
          mainRoofPitch,
          setMainRoofPitch,
          leanRWidth,
          setLeanRWidth,
          leanRHeight,
          setLeanRHeight,
          leanRLength,
          setLeanRLength,
          leanRRoofPitch,
          setLeanRRoofPitch,
          leanLWidth,
          setLeanLWidth,
          leanLHeight,
          setLeanLHeight,
          leanLLength,
          setLeanLLength,
          leanLRoofPitch,
          setLeanLRoofPitch,
          isLoading,
          setIsLoading
        }}
      >
        <div className="App">
          <DraggableBox
            draggableIsOpen={draggableIsOpen}
            modalIsOpen={modalIsOpen}
            haveAngle={haveAngle}
          />
          {state && <Canvas canvasStyle={canvasStyle} />}
          <Header active={buildingType} />
          {!state && <StateForm />}
          {state && !buildingType && <BuildingType />}
          {state && buildingType && <Build />}
          {state && isLoading && <Spinner content={"Loading..."} />}
        </div>
      </GlobalState.Provider>
    </ThemeProvider>
  );
}

export default App;
