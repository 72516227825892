const roofData = [
	// {
	// 	id: 1,
	// 	text: "Regular",
	// 	imgUrl: "./img/InputBoxesRoof/regular.png",
	// 	isChecked: false,
	// },
	{
		id: 2,
		text: "A-Frame",
		imgUrl: "./img/InputBoxesRoof/a-frame.png",
		isChecked: false,
	},
	{
		id: 3,
		text: "Vertical",
		imgUrl: "./img/InputBoxesRoof/vertical.png",
		isChecked: false,
	},
];

export default roofData;
