import React, { useContext, useRef, useEffect } from 'react'

import { LeanToWrapper } from './LeanTo.css'
import { Plane, Axis, Space } from '@babylonjs/core';
import LeanToSide from './LeanToSide/LeanToSide'
import { GlobalState } from '../../../App'
import {
    regenerateFrames,
    mainEndsWallClipPlanesChange,
    mainSidesWallClipPlanesChange,
    switchMorphLevel,
    recreatePlanes,
    createMeasurementsGUI,
    handlePriceValue
} from '../../../utils/functions'

const LeanTo = () => {
    const GlobalStore = useContext(GlobalState);
    const leanOptions = [];

    const leanToWidth = [];
    for (let i = 6; i <= 16; i++) {
        leanToWidth.push({ value: i.toString(), label: i.toString(), price: i * 15 })
    }

    const leanToLength = [];
    for (let i = 21; i <= 101; i++) {
        leanToLength.push({ value: i.toString(), label: i.toString(), price: i * 15 })
    }

    const leanToHeight = [
        { value: "6", label: "6", price: 6 * 15 },
    ]
    for (let i = 7; i <= GlobalStore.leanWallHeightConstraint; i++) {
        leanToHeight.push({ value: i.toString(), label: i.toString(), price: i * 15 })
    }

    const leanToRoofPitch = [
        { value: "2/12", label: "2/12" },
        { value: "3/12", label: "3/12" },
        { value: "4/12", label: "4/12" },
        { value: "5/12", label: "5/12" }
    ];

    const leanToWindSnow = [
        { value: "Certified 140 MPH + 30 PSF", label: "Certified 140 MPH + 30 PSF" },
    ];
    leanOptions.push(leanToWidth, leanToLength, leanToHeight, leanToRoofPitch, leanToWindSnow);

    const scene = GlobalStore.sceneGlobalRef.current;
    const leftLeanParent = scene.getMeshByName("Lean_Left_Helper");
    const rightLeanParent = scene.getMeshByName("Lean_Right_Helper");
    const mainParent_L = scene.getMeshByName("Main_Helper_L");
    const mainParent_R = scene.getMeshByName("Main_Helper_R");

    //create and define current indexes of the dropdown menus
    const widthIndexLeft = useRef(null);
    const heightIndexLeft = useRef(null);
    const lengthIndexLeft = useRef(null);
    const roofPitchIndexLeft = useRef(null);

    const widthIndexRight = useRef(null);
    const heightIndexRight = useRef(null);
    const lengthIndexRight = useRef(null);
    const roofPitchIndexRight = useRef(null);

    let leftIndexes = useRef([])
    let rightIndexes = useRef([])

    const presetDataLeft = GlobalStore.buildingType.customizationData.leftLeanTo;
    const presetDataRight = GlobalStore.buildingType.customizationData.rightLeanTo;

    widthIndexLeft.current = leanToWidth.findIndex(x => x.value === presetDataLeft.width);
    heightIndexLeft.current = leanToHeight.findIndex(x => x.value === presetDataLeft.height);
    lengthIndexLeft.current = leanToLength.findIndex(x => x.value === presetDataLeft.length);
    roofPitchIndexLeft.current = leanToRoofPitch.findIndex(x => x.value === presetDataLeft.roofPitch);

    widthIndexRight.current = leanToWidth.findIndex(x => x.value === presetDataRight.width);
    heightIndexRight.current = leanToHeight.findIndex(x => x.value === presetDataRight.height);
    lengthIndexRight.current = leanToLength.findIndex(x => x.value === presetDataRight.length);
    roofPitchIndexRight.current = leanToRoofPitch.findIndex(x => x.value === presetDataRight.roofPitch);

    useEffect(() => {
        handleRightLeanChange(!presetDataRight.isOn)
        handleLeftLeanChange(!presetDataLeft.isOn)
        handleLeanWidthChange(presetDataLeft.width, "Left", true)
        handleLeanHeightChange(presetDataLeft.height, "Left", true)
        handleLeanPitchChange(presetDataLeft.roofPitch, "Left", true)
        handleLeanLengthChange(presetDataLeft.length, "Left", true)

        handleLeanWidthChange(presetDataRight.width, "Right", true)
        handleLeanHeightChange(presetDataRight.height, "Right", true)
        handleLeanPitchChange(presetDataRight.roofPitch, "Right", true)
        handleLeanLengthChange(presetDataRight.length, "Right", true)

        leftIndexes.current.push(widthIndexLeft.current, heightIndexLeft.current, lengthIndexLeft.current, roofPitchIndexLeft.current)
        rightIndexes.current.push(widthIndexRight.current, heightIndexRight.current, lengthIndexRight.current, roofPitchIndexRight.current)

        recreatePlanes("leftLean", mainParent_L, "dimensionHelper_L", GlobalStore, ["front_L", "back_L", "left_L"], scene);
        recreatePlanes("rightLean", mainParent_R, "dimensionHelper_R", GlobalStore, ["front_R", "back_R", "right_R"], scene);
        createMeasurementsGUI(GlobalStore, scene, "global");
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLeftLeanChange = (isChecked) => {
        const measurementGUI = GlobalStore.currentDimensionElements.current;
        //toggles Left Lean Tos visibility (walls not included)
        leftLeanParent.getChildMeshes().map(leftChild => !leftChild.name.includes("dimensionHelper_L") && !leftChild.name.includes("Profile") && !leftChild.name.includes("Ridge") && !leftChild.name.includes("storage_leanL_backWall") ? leftChild.isVisible = !isChecked : null);
        leftLeanParent.getChildMeshes().map(leftChild => {
            if (leftChild.name.includes("wainscot")) {
                if (GlobalStore.isLeftLeanActive.current === false && GlobalStore.wainscot === true) {
                    leftChild.isVisible = true;

                }
                else if (GlobalStore.isLeftLeanActive.current === true || GlobalStore.wainscot === false) {
                    leftChild.isVisible = false;
                }
            }
        })

        switch (!isChecked) {
            case true:
                GlobalStore.setPrice(GlobalStore.price + 655)
                GlobalStore.setDeposit(GlobalStore.deposit + 70);
                GlobalStore.isLeftLeanActive.current = true;
                break;
            case false:
                GlobalStore.setPrice(GlobalStore.price - 655)
                GlobalStore.setDeposit(GlobalStore.deposit - 70)
                GlobalStore.isLeftLeanActive.current = false;
                break;

            default:
                break;
        }
        //check if measurement is on and if left lean is on and show/hide measurement gui accordingly
        if (GlobalStore.isMeasure) {
            measurementGUI.map(mesh => {
                if (mesh.name.indexOf("leanL") > -1 && !isChecked) {
                    mesh.isVisible = true;
                }
                else if (mesh.name.indexOf("leanL") > -1 && isChecked) {
                    mesh.isVisible = false;
                }
                return 0
            })
        }
        else {
            measurementGUI.map(mesh => {
                if (mesh.name.indexOf("leanL") > -1) {
                    mesh.isVisible = false;
                }
                return 0
            })
        }
    }
    const handleRightLeanChange = (isChecked) => {
        const measurementGUI = GlobalStore.currentDimensionElements.current;
        rightLeanParent.getChildMeshes().map(rightChild => !rightChild.name.includes("dimensionHelper_R") && !rightChild.name.includes("Profile") && !rightChild.name.includes("Ridge") && !rightChild.name.includes("storage_leanR_backWall") ? rightChild.isVisible = !isChecked : null);
        rightLeanParent.getChildMeshes().map(rightChild => {
            if (rightChild.name.includes("wainscot") && !isChecked && GlobalStore.wainscot) {
                rightChild.isVisible = true;
            }
            else if (rightChild.name.includes("wainscot")) {
                if (isChecked || !GlobalStore.wainscot) {
                    rightChild.isVisible = false;
                }
            }
        })
        switch (!isChecked) {
            case true:
                GlobalStore.setPrice(GlobalStore.price + 655)
                GlobalStore.setDeposit(GlobalStore.deposit + 70)
                GlobalStore.isRightLeanActive.current = true;
                break;
            case false:
                GlobalStore.setPrice(GlobalStore.price - 655)
                GlobalStore.setDeposit(GlobalStore.deposit - 70)
                GlobalStore.isRightLeanActive.current = false;
                break;

            default:
                break;
        }
        //check if measurement is on and if left lean is on and show/hide measurement gui accordingly
        if (GlobalStore.isMeasure) {
            measurementGUI.map(mesh => {
                if (mesh.name.indexOf("leanR") > -1 && !isChecked) {
                    mesh.isVisible = true;
                }
                else if (mesh.name.indexOf("leanR") > -1 && isChecked) {
                    mesh.isVisible = false;
                }
                return 0
            })
        }
        else {
            measurementGUI.map(mesh => {
                if (mesh.name.indexOf("leanR") > -1) {
                    mesh.isVisible = false;
                }
                return 0
            })
        }
    }
    const handleLeanWidthChange = (targetValue, inputName, init) => {
        let parent,
            currentPrice,
            positionZ,
            sign,
            helper,
            storage,
            storageSideWall
        //clear measurements GUI elements
        GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
        GlobalStore.currentDimensionElements.current = [];
        switch (inputName) {
            case "Left":
                parent = scene.getMeshByName("Lean_Left_Helper");
                helper = scene.getMeshByName("Main_Helper_L");
                storage = scene.getMeshByName("storage_lean_L");
                storageSideWall = scene.getMeshByName("storage_leanL_sideWall");
                currentPrice = GlobalStore.currentWidthPrice_leftLean.current;
                positionZ = GlobalStore.lean_L_startPosZ.current;
                sign = 1;
                storageSideWall.setParent(null);
                parent.position.z = positionZ + GlobalStore.mainWidthOffset.current - (((targetValue / 16 - 1) * 6.5)) * sign;
                storageSideWall.setParent(storage);
                GlobalStore.leftWidthOffset.current = (positionZ - parent.position.z) + GlobalStore.mainWidthOffset.current;
                GlobalStore.setLeanLWidth(targetValue)
                if (!init) {
                    recreatePlanes("leftLean", helper, "dimensionHelper_L", GlobalStore, ["front_L", "back_L", "left_L"], scene)
                    scene.meshes.map(mesh => {
                        if (mesh.bindedTo === ("referencePlane_left_L")) {
                            const referencePlane = scene.getMeshByName(mesh.bindedTo);
                            referencePlane.computeWorldMatrix(true);
                            mesh.position.z = referencePlane.position.z / 10;
                        }
                        return 0
                    })
                    //create new set of gui elements with proper dimensions
                    createMeasurementsGUI(GlobalStore, scene, "left", ["width", targetValue]);
                }

                break;
            case "Right":
                parent = scene.getMeshByName("Lean_Right_Helper");
                helper = scene.getMeshByName("Main_Helper_R");
                storage = scene.getMeshByName("storage_lean_R");
                storageSideWall = scene.getMeshByName("storage_leanR_sideWall");
                currentPrice = GlobalStore.currentWidthPrice_rightLean.current;
                positionZ = GlobalStore.lean_R_startPosZ.current;
                sign = -1;
                storageSideWall.setParent(null);
                parent.position.z = positionZ - GlobalStore.mainWidthOffset.current - (((targetValue / 16 - 1) * 6.5)) * sign;
                storageSideWall.setParent(storage);
                GlobalStore.rightWidthOffset.current = (positionZ - parent.position.z) - GlobalStore.mainWidthOffset.current;
                GlobalStore.setLeanRWidth(targetValue)
                if (!init) {
                    recreatePlanes("rightLean", helper, "dimensionHelper_R", GlobalStore, ["front_R", "back_R", "right_R"], scene)
                    scene.meshes.map(mesh => {
                        if (mesh.bindedTo === ("referencePlane_right_R")) {
                            const referencePlane = scene.getMeshByName(mesh.bindedTo);
                            referencePlane.computeWorldMatrix(true);
                            mesh.position.z = referencePlane.position.z / 10;
                        }
                        return 0
                    })
                    //create new set of gui elements with proper dimensions
                    createMeasurementsGUI(GlobalStore, scene, "right", ["width", targetValue]);
                }
                break;

            default:
                break;
        }

        handlePriceValue(targetValue, currentPrice, leanToWidth, GlobalStore);
    }
    const handleLeanHeightChange = (targetValue, inputName, init) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        let parent;
        let parentBase;
        let positionY;
        let currentPrice;
        let planeData;
        let helper;
        let dimensionHelper;
        let frontWall;
        let backWall;
        let sideWall;
        let targetFront;
        let targetBack;
        let targetSide;
        let side;
        let wainscot_B;
        let wainscot_F;
        let wainscot_S;
        let storageSideWall;
        let storage;
        //clear measurements GUI elements
        GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
        GlobalStore.currentDimensionElements.current = [];
        switch (inputName) {
            case "Left":
                parent = scene.getMeshByName("Lean_Left_Helper");
                helper = scene.getMeshByName("Main_Helper_L");
                parentBase = scene.getMeshByName("Standard Garage (frame left, lean-to)_009");
                positionY = GlobalStore.lean_L_startPosY.current
                currentPrice = GlobalStore.currentHeightPrice_leftLean.current;
                planeData = ["leftLean", "dimensionHelper_L", ["front_L", "back_L", "left_L"]];
                dimensionHelper = scene.getMeshByName("dimensionHelper_L");
                frontWall = scene.getTransformNodeByName("frontWall_LL");
                backWall = scene.getTransformNodeByName("backWall_LL");
                sideWall = scene.getTransformNodeByName("sideWall_LL");
                wainscot_B = scene.getMeshByName("wainscot_B_LL");
                wainscot_F = scene.getMeshByName("wainscot_F_LL");
                wainscot_S = scene.getMeshByName("wainscot_S_LL");
                storageSideWall = scene.getMeshByName("storage_leanL_sideWall")
                storage = scene.getMeshByName("storage_lean_L");
                targetFront = GlobalStore.selectedEndFront_LL;
                targetBack = GlobalStore.selectedEndBack_LL;
                targetSide = GlobalStore.selectedSidesLeft_LL;
                side = "left"
                break;
            case "Right":
                parent = scene.getMeshByName("Lean_Right_Helper");
                helper = scene.getMeshByName("Main_Helper_R");
                parentBase = scene.getMeshByName("Standard Garage (frame left, lean-to)_008");
                positionY = GlobalStore.lean_R_startPosY.current
                currentPrice = GlobalStore.currentHeightPrice_rightLean.current;
                planeData = ["rightLean", "dimensionHelper_R", ["front_R", "back_R", "right_R"]]
                dimensionHelper = scene.getMeshByName("dimensionHelper_R");
                frontWall = scene.getTransformNodeByName("frontWall_RL");
                backWall = scene.getTransformNodeByName("backWall_RL");
                sideWall = scene.getTransformNodeByName("sideWall_RL");
                storageSideWall = scene.getMeshByName("storage_leanR_sideWall")
                storage = scene.getMeshByName("storage_lean_R");
                targetFront = GlobalStore.selectedEndFront_RL;
                targetBack = GlobalStore.selectedEndBack_RL;
                targetSide = GlobalStore.selectedSidesRight_RL;
                side = "right"
                wainscot_B = scene.getMeshByName("wainscot_B_RL");
                wainscot_F = scene.getMeshByName("wainscot_F_RL");
                wainscot_S = scene.getMeshByName("wainscot_S_RL");
                break;
            default:
                break;
        }

        wainscot_B.setParent(null);
        wainscot_F.setParent(null);
        wainscot_S.setParent(null);
        parentBase.setParent(null);
        storageSideWall.setParent(null);
        parent.position.y = positionY + ((targetValue / 6 - 1) * 2.33);
        parentBase.setParent(parent);
        wainscot_B.setParent(backWall);
        wainscot_F.setParent(frontWall);
        wainscot_S.setParent(sideWall);
        storageSideWall.setParent(storage);

        dimensionHelper.computeWorldMatrix(true);
        const groundHeightY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        const wallHeightY = dimensionHelper.getBoundingInfo().boundingBox.maximumWorld.y;

        mainEndsWallClipPlanesChange(targetFront, [wallHeightY, groundHeightY], [frontWall], scene)
        mainEndsWallClipPlanesChange(targetBack, [wallHeightY, groundHeightY], [backWall], scene)
        mainSidesWallClipPlanesChange(targetSide, [wallHeightY, groundHeightY], [sideWall], scene)

        if (side === 'right') {
            GlobalStore.setLeanRHeight(targetValue);
        }
        else if (side === "left") {
            GlobalStore.setLeanLHeight(targetValue);
        }
        if (!init) {
            //create new set of gui elements with proper dimensions
            createMeasurementsGUI(GlobalStore, scene, side, ["height", targetValue]);
            recreatePlanes(planeData[0], helper, planeData[1], GlobalStore, planeData[2], scene)
        }
        handlePriceValue(targetValue, currentPrice, leanToHeight, GlobalStore);
    }
    const handleLeanLengthChange = (targetValue, inputName, init) => {
        let parent;
        let clonedFrames;
        let clipPlaneSignZ;
        let clipPlaneDistance;
        let currentPrice;
        let planeData;
        let helper;
        let referencePlaneBackName;
        let referencePlaneFrontName;
        let storageName;
        let storageParent;
        let storageGlobalDistance;
        let storageTranslation;
        let storageOn;
        let side;
        //clear measurements GUI elements
        GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
        GlobalStore.currentDimensionElements.current = [];
        switch (inputName) {
            case "Left":
                parent = scene.getMeshByName("Lean_Left_Helper");
                helper = scene.getMeshByName("Main_Helper_L");
                clonedFrames = GlobalStore.clonedFramesLeanL;
                clipPlaneSignZ = 1;
                clipPlaneDistance = GlobalStore.leanClipPlaneDistance.current
                currentPrice = GlobalStore.currentHeightPrice_leftLean.current;
                planeData = ["leftLean", "dimensionHelper_L", ["front_L", "back_L", "left_L"]]
                referencePlaneBackName = "referencePlane_back_L";
                referencePlaneFrontName = "referencePlane_front_L";
                storageName = "storage_lean_L";
                storageGlobalDistance = GlobalStore.leanLLengthFromCenter;
                storageTranslation = GlobalStore.leanLStorageTranslation
                storageOn = GlobalStore.storageLeft
                side = "left"
                break;
            case "Right":
                parent = scene.getMeshByName("Lean_Right_Helper");
                helper = scene.getMeshByName("Main_Helper_R");
                clonedFrames = GlobalStore.clonedFramesLeanR;
                clipPlaneSignZ = -1;
                clipPlaneDistance = GlobalStore.leanClipPlaneDistance.current
                currentPrice = GlobalStore.currentHeightPrice_rightLean.current;
                planeData = ["rightLean", "dimensionHelper_R", ["front_R", "back_R", "right_R"]]
                referencePlaneBackName = "referencePlane_back_R";
                referencePlaneFrontName = "referencePlane_front_R";
                storageName = "storage_lean_R";
                storageGlobalDistance = GlobalStore.leanRLengthFromCenter;
                storageTranslation = GlobalStore.leanRStorageTranslation
                storageOn = GlobalStore.storageRight
                side = "right"
                break;

            default:
                break;
        }
        parent.getChildMeshes().map(mesh => {
            if (mesh.name === storageName) {
                storageParent = mesh;
            }
            return 0
        })

        storageParent.setParent(null);

        parent.scaling.x = targetValue / 21;

        parent.computeWorldMatrix(true);
        const storagePlaneDistance = parent.getBoundingInfo().boundingBox.minimumWorld.x;
        storageGlobalDistance.current = storagePlaneDistance;

        storageParent.setParent(parent);

        storageParent.setAbsolutePosition(storagePlaneDistance, storageParent.getAbsolutePosition().y, storageParent.getAbsolutePosition().z)

        if (storageOn) {
            storageParent.translate(Axis.Y, storageTranslation.current / 5 * 2.4, Space.LOCAL);
        }

        const clipPlaneStorage = new Plane(-1, 0, 0, storagePlaneDistance + 0.5);
        storageParent.onBeforeRenderObservable.add(function () {
            scene.clipPlane2 = clipPlaneStorage;
        });
        storageParent.onAfterRenderObservable.add(function () {
            scene.clipPlane2 = null;
        });

        if (side === 'right') {
            GlobalStore.setLeanRLength(targetValue);
        }
        else if (side === 'left') {
            GlobalStore.setLeanLLength(targetValue);
        }
        if (!init) {
            //create new set of gui elements with proper dimensions
            createMeasurementsGUI(GlobalStore, scene, side, ["length", targetValue]);
            recreatePlanes(planeData[0], helper, planeData[1], GlobalStore, planeData[2], scene)
        }

        clonedFrames.current.map(frames => frames.map(frame => frame.dispose()))
        clonedFrames.current = [];
        const clipPlane = new Plane(0, 0, clipPlaneSignZ, clipPlaneDistance);
        regenerateFrames([parent], [clipPlane], clonedFrames, targetValue / 21, scene);

        scene.meshes.map(mesh => {
            if (mesh.bindedTo === (referencePlaneBackName) || mesh.bindedTo === (referencePlaneFrontName)) {
                const referencePlane = scene.getMeshByName(mesh.bindedTo);
                referencePlane.computeWorldMatrix(true);
                mesh.position.x = -referencePlane.position.x / 10;
            }
            return 0
        })
        handlePriceValue(targetValue, currentPrice, leanToLength, GlobalStore);
    }
    const handleLeanPitchChange = (targetValue, inputName, init) => {
        let parent;
        let side;
        //clear measurements GUI elements
        GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
        GlobalStore.currentDimensionElements.current = [];
        switch (inputName) {
            case "Left":
                parent = scene.getMeshByName("Lean_Left_Helper");
                side = "left"
                break;
            case "Right":
                parent = scene.getMeshByName("Lean_Right_Helper");
                side = 'right'
                break;

            default:
                break;
        }

        switch (targetValue) {
            case "2/12":
                switchMorphLevel([parent], 0);
                break;
            case "3/12":
                switchMorphLevel([parent], 0.33);
                break;
            case "4/12":
                switchMorphLevel([parent], 0.66);
                break;
            case "5/12":
                switchMorphLevel([parent], 1);
                break;
            default:
                break;
        }

        if (side === 'right') {
            GlobalStore.setLeanRRoofPitch(targetValue);
        }
        else if (side === 'left') {
            GlobalStore.setLeanLRoofPitch(targetValue);
        }

        //create new set of gui elements with proper dimensions

        if (!init) {
            createMeasurementsGUI(GlobalStore, scene, side, ["roofPitch", targetValue]);
        }

    }
    return (
        <LeanToWrapper>
            <LeanToSide onLeanVisbilityChange={handleLeftLeanChange} onLeanWidthChange={handleLeanWidthChange} onLeanHeightChange={handleLeanHeightChange} onLeanLengthChange={handleLeanLengthChange} onLeanPitchChange={handleLeanPitchChange} title='Left' options={leanOptions} indexes={leftIndexes} leanChecked={presetDataLeft.isOn} />
            <LeanToSide onLeanVisbilityChange={handleRightLeanChange} onLeanWidthChange={handleLeanWidthChange} onLeanHeightChange={handleLeanHeightChange} onLeanLengthChange={handleLeanLengthChange} onLeanPitchChange={handleLeanPitchChange} title='Right' options={leanOptions} indexes={rightIndexes} leanChecked={presetDataRight.isOn} />
        </LeanToWrapper>
    )
}

export default LeanTo
