import React from 'react'

import {
    EntryContentWrapper
} from './EntryContent.css'

const EntryContent = ({content}) => {
    function createMarkup() {
        return {
           __html: content    
        };
    }; 

    return (
        <EntryContentWrapper dangerouslySetInnerHTML={createMarkup()}>
        </EntryContentWrapper>
    )
}

export default EntryContent
