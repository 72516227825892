import { React, useContext, useRef, useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import { GlobalState } from '../../App';
import { Vector3, Space, Plane } from '@babylonjs/core';
import { createDnWGUI } from '../../utils/functions'
import {
    CardsAddWrapper,
    CardsAddItem,
    CardsAddItemIcon,
    CardsAddItemText,
    CardsAddItemAddBtn,
} from './CardsAdd.css'

import { garageDoorOptions, walkInDoorOptions, windowOptions } from '../DraggableBox/DraggableBoxData.json';

const CardsAdd = ({ isActive }) => {
    const GlobalStore = useContext(GlobalState)
    const scene = GlobalStore.sceneGlobalRef.current;
    let garageDoorInstances = useRef(1)
    let walkInDoorInstances = useRef(1)
    let windowInstances = useRef(1)
    let framedOpeningInstances = useRef(1)
    let framedWalkinInstances = useRef(1)
    let windowFramedInstances = useRef(1)
    const garageDoor = scene.getMeshByName("GarageDoor_Helper");
    const walkInDoor = scene.getMeshByName("WalkInDoor_Helper");
    const window = scene.getMeshByName("Window_Helper");
    const framedOpening = scene.getMeshByName("FramedOpenings_Helper");
    const framedWalkinDoor = scene.getMeshByName("FramedWalkIn_Helper");
    const windowFramed = scene.getMeshByName("WindowFramed_Helper");
    garageDoor.computeWorldMatrix(true)
    walkInDoor.computeWorldMatrix(true)
    window.computeWorldMatrix(true)
    framedOpening.computeWorldMatrix(true)
    framedWalkinDoor.computeWorldMatrix(true)
    windowFramed.computeWorldMatrix(true)

    const [addedGarageDoor, setAddedGarageDoor] = useState(null);
    const presetDataMain = GlobalStore.buildingType.customizationData.mainStructure;

    useEffect(() => {
        if (presetDataMain.addDoors.length > 0) {
            presetDataMain.addDoors.map(door => {
                switch (door) {
                    case 'addGarageDoor':
                        addGarageDoor(null, "referencePlane_front");
                        break;
                    case 'addWalkInDoor':
                        addWalkInDoor(null, "referencePlane_front");
                        break;
                    case 'addWindow':
                        addWindow(null, "referencePlane_front");
                        break;
                    case 'addFramedOpening':
                        addFramedOpening(null, "referencePlane_front");
                        break;
                    case 'addFramedWalkInDoor':
                        addFramedWalkInDoor(null, "referencePlane_front");
                        break;
                    case 'addFramedWindow':
                        addFramedWindow(null, "referencePlane_front");
                        break;
                    default:
                        break;
                }
            })
        }
        else {
            scene.meshes.map(mesh => {
                if (mesh.bindedTo === "referencePlane_front") {
                    mesh.getChildMeshes().map(mesh => mesh.dispose())
                    mesh.dispose();
                }
            })
        }
    }, [])

    const addGarageDoor = (e, active) => {
        const garageDoorInstance = garageDoor.clone(`garageDoor_instance${garageDoorInstances.current}`);
        garageDoorInstance.getChildMeshes().map(mesh => {
            if (mesh.name.indexOf("_45") > -1) {
                mesh.isVisible = false; mesh.isPickable = false;
            }
            else {
                mesh.isVisible = true; mesh.isPickable = true;
            }
        });
        let activePlane;
        if (active) {
            activePlane = [scene.getMeshByName(active)];
        }
        else {
            activePlane = GlobalStore.cameraDistanceReferencePlanes.current.filter(plane => plane.isActivePlane)
        }

        garageDoorInstance.bindedTo = activePlane[0].name;
        createDnWGUI(garageDoorInstance, scene, GlobalStore, garageDoorOptions);
        activePlane[0].computeWorldMatrix(true);
        const activePlaneMax = activePlane[0].getBoundingInfo().boundingBox.maximumWorld;
        const activePlaneMin = activePlane[0].getBoundingInfo().boundingBox.minimumWorld;
        const activePlaneCenter = activePlane[0].getBoundingInfo().boundingBox.centerWorld;
        switch (activePlane[0].name) {
            case "referencePlane_front":
                garageDoorInstance.position = new Vector3(-activePlaneMax.x / 10, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back":
                garageDoorInstance.position = new Vector3(-activePlaneMax.x / 10, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                break;
            case "referencePlane_left":
                garageDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMin.z / 10 - 0.05)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_right":
                garageDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.05)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_R":
                garageDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.09, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_R":
                garageDoorInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.09, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_right_R":
                garageDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.05)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_L":
                garageDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.09, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_L":
                garageDoorInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.08, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_left_L":
                garageDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 - 0.05)
                garageDoorInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;

            default:
                break;
        }

        garageDoorInstances.current++
    }
    const addWalkInDoor = (e, active) => {
        const walkInDoorInstance = walkInDoor.clone(`walkInDoor_instance${walkInDoorInstances.current}`);
        walkInDoorInstance.getChildMeshes().map(mesh => { mesh.isVisible = true; mesh.isPickable = true; });
        let activePlane;
        if (active) {
            activePlane = [scene.getMeshByName(active)];
        }
        else {
            activePlane = GlobalStore.cameraDistanceReferencePlanes.current.filter(plane => plane.isActivePlane)
        }
        walkInDoorInstance.bindedTo = activePlane[0].name;
        createDnWGUI(walkInDoorInstance, scene, GlobalStore, walkInDoorOptions);
        activePlane[0].computeWorldMatrix(true);
        const activePlaneMax = activePlane[0].getBoundingInfo().boundingBox.maximumWorld;
        const activePlaneMin = activePlane[0].getBoundingInfo().boundingBox.minimumWorld;
        const activePlaneCenter = activePlane[0].getBoundingInfo().boundingBox.centerWorld;
        switch (activePlane[0].name) {
            case "referencePlane_front":
                walkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.05, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back":
                walkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                break;
            case "referencePlane_left":
                walkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMin.z / 10 - 0.1)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_right":
                walkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.08)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_R":
                walkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.15, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_R":
                walkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_right_R":
                walkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.08)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_L":
                walkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.15, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                break;
            case "referencePlane_back_L":
                walkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_left_L":
                walkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 - 0.1)
                walkInDoorInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;

            default:
                break;
        }
        walkInDoorInstance.current++
    }
    const addWindow = (e, active) => {
        const windowInstance = window.clone(`window_instance${windowInstances.current}`);
        windowInstance.getChildMeshes().map(mesh => { mesh.isVisible = true; mesh.isPickable = true; });
        let activePlane;
        if (active) {
            activePlane = [scene.getMeshByName(active)];
        }
        else {
            activePlane = GlobalStore.cameraDistanceReferencePlanes.current.filter(plane => plane.isActivePlane)
        }
        windowInstance.bindedTo = activePlane[0].name;
        createDnWGUI(windowInstance, scene, GlobalStore, windowOptions);
        activePlane[0].computeWorldMatrix(true);
        const activePlaneMax = activePlane[0].getBoundingInfo().boundingBox.maximumWorld;
        const activePlaneMin = activePlane[0].getBoundingInfo().boundingBox.minimumWorld;
        const activePlaneCenter = activePlane[0].getBoundingInfo().boundingBox.centerWorld;
        switch (activePlane[0].name) {
            case "referencePlane_front":
                windowInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.02, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back":
                windowInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.02, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                windowInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                break;
            case "referencePlane_left":
                windowInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMin.z / 10 - 0.1)
                windowInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_right":
                windowInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMax.z / 10 + 0.1)
                windowInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_R":
                windowInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.13, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_R":
                windowInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                windowInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_right_R":
                windowInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMax.z / 10 + 0.08)
                windowInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_L":
                windowInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.13, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_L":
                windowInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                windowInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_left_L":
                windowInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMax.z / 10 - 0.08)
                windowInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;

            default:
                break;
        }
        windowInstances.current++
    }
    const addFramedOpening = (e, active) => {
        const framedOpeningInstance = framedOpening.clone(`framedOpening_instance${framedOpeningInstances.current}`);
        framedOpeningInstance.getChildMeshes().map(mesh => {
            if (mesh.name.indexOf("_45") > -1) {
                mesh.isVisible = false; mesh.isPickable = false;
            }
            else {
                mesh.isVisible = true; mesh.isPickable = true;
            }
        });
        let activePlane;
        if (active) {
            activePlane = [scene.getMeshByName(active)];
        }
        else {
            activePlane = GlobalStore.cameraDistanceReferencePlanes.current.filter(plane => plane.isActivePlane)
        }
        framedOpeningInstance.bindedTo = activePlane[0].name;
        createDnWGUI(framedOpeningInstance, scene, GlobalStore, garageDoorOptions);
        activePlane[0].computeWorldMatrix(true);
        const activePlaneMax = activePlane[0].getBoundingInfo().boundingBox.maximumWorld;
        const activePlaneMin = activePlane[0].getBoundingInfo().boundingBox.minimumWorld;
        const activePlaneCenter = activePlane[0].getBoundingInfo().boundingBox.centerWorld;
        switch (activePlane[0].name) {
            case "referencePlane_front":
                framedOpeningInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back":
                framedOpeningInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                break;
            case "referencePlane_left":
                framedOpeningInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMin.z / 10 - 0.15)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_right":
                framedOpeningInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.15)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_R":
                framedOpeningInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.15, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_R":
                framedOpeningInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_right_R":
                framedOpeningInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.08)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_L":
                framedOpeningInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.15, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_L":
                framedOpeningInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_left_L":
                framedOpeningInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 - 0.08)
                framedOpeningInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;

            default:
                break;
        }
        framedOpeningInstances.current++
    }
    const addFramedWalkInDoor = (e, active) => {
        const framedWalkInDoorInstance = framedWalkinDoor.clone(`walkInDoor_instance${framedWalkinInstances.current}`);
        framedWalkInDoorInstance.getChildMeshes().map(mesh => { mesh.isVisible = true; mesh.isPickable = true; });
        let activePlane;
        if (active) {
            activePlane = [scene.getMeshByName(active)];
        }
        else {
            activePlane = GlobalStore.cameraDistanceReferencePlanes.current.filter(plane => plane.isActivePlane)
        }
        framedWalkInDoorInstance.bindedTo = activePlane[0].name;
        createDnWGUI(framedWalkInDoorInstance, scene, GlobalStore, walkInDoorOptions);
        activePlane[0].computeWorldMatrix(true);
        const activePlaneMax = activePlane[0].getBoundingInfo().boundingBox.maximumWorld;
        const activePlaneMin = activePlane[0].getBoundingInfo().boundingBox.minimumWorld;
        const activePlaneCenter = activePlane[0].getBoundingInfo().boundingBox.centerWorld;
        switch (activePlane[0].name) {
            case "referencePlane_front":
                framedWalkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.05, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back":
                framedWalkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                break;
            case "referencePlane_left":
                framedWalkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMin.z / 10 - 0.1)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_right":
                framedWalkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.08)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_R":
                framedWalkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.15, activePlaneMin.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_R":
                framedWalkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_right_R":
                framedWalkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 + 0.08)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_L":
                framedWalkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.15, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                break;
            case "referencePlane_back_L":
                framedWalkInDoorInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneMin.y / 10, activePlaneCenter.z / 10 + 0.05)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_left_L":
                framedWalkInDoorInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneMin.y / 10, activePlaneMax.z / 10 - 0.1)
                framedWalkInDoorInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;

            default:
                break;
        }
        framedWalkinInstances.current++
    }
    const addFramedWindow = (e, active) => {
        const windowFramedInstance = windowFramed.clone(`window_framed_instance${windowFramedInstances.current}`);
        windowFramedInstance.getChildMeshes().map(mesh => { mesh.isVisible = true; mesh.isPickable = true; });
        let activePlane;
        if (active) {
            activePlane = [scene.getMeshByName(active)];
        }
        else {
            activePlane = GlobalStore.cameraDistanceReferencePlanes.current.filter(plane => plane.isActivePlane)
        }
        windowFramedInstance.bindedTo = activePlane[0].name;
        createDnWGUI(windowFramedInstance, scene, GlobalStore, windowOptions);
        activePlane[0].computeWorldMatrix(true);
        const activePlaneMax = activePlane[0].getBoundingInfo().boundingBox.maximumWorld;
        const activePlaneMin = activePlane[0].getBoundingInfo().boundingBox.minimumWorld;
        const activePlaneCenter = activePlane[0].getBoundingInfo().boundingBox.centerWorld;
        switch (activePlane[0].name) {
            case "referencePlane_front":
                windowFramedInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.02, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back":
                windowFramedInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.02, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                break;
            case "referencePlane_left":
                windowFramedInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMin.z / 10 - 0.1)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_right":
                windowFramedInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMax.z / 10 + 0.1)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_R":
                windowFramedInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.13, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_R":
                windowFramedInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_right_R":
                windowFramedInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMax.z / 10 + 0.08)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.WORLD)
                break;
            case "referencePlane_front_L":
                windowFramedInstance.position = new Vector3(-activePlaneMax.x / 10 - 0.13, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                break;
            case "referencePlane_back_L":
                windowFramedInstance.position = new Vector3(-activePlaneMax.x / 10 + 0.1, activePlaneCenter.y / 10, activePlaneCenter.z / 10)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD)
                break;
            case "referencePlane_left_L":
                windowFramedInstance.position = new Vector3(activePlaneCenter.x / 10, activePlaneCenter.y / 10, activePlaneMax.z / 10 - 0.08)
                windowFramedInstance.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD)
                break;

            default:
                break;
        }
        windowFramedInstances.current++
    }
    return (
        <CardsAddWrapper isActive={isActive}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CardsAddItem>
                        <CardsAddItemIcon>
                            <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.009.186C.802.288.499.573.336.82l-.296.448-.033 19.444C-.01 31.407.004 40.32.04 40.517c.086.482.56 1.025 1.115 1.277.434.197 1.27.206 19.837.206 18.608 0 19.401-.008 19.832-.207.518-.24.887-.607 1.056-1.056.084-.219.12-6.264.12-19.776V1.501l-.252-.5a1.74 1.74 0 00-.7-.75L40.599 0H20.992C2.993.002 1.353.018 1.009.187zm39.394 1.318c.201.077.207 38.71.006 38.91-.179.179-38.419.197-38.752.019-.213-.114-.217-.45-.217-19.496 0-19.079.003-19.38.217-19.46.237-.088 38.517-.061 38.746.027zM9.507 20.384v12.771H7.602v1.456h26.779v-1.456H32.476V7.614H9.507v12.77zM21.412 9.061l9.608.01v23.86H10.964V21.01c0-11.265.01-11.925.196-11.963.108-.022.297-.03.42-.018.123.013 4.548.028 9.832.033zm-7.087 4.77v.728h14.352l-.033-.7-.033-.7-7.143-.029-7.143-.029v.73zm0 4.817v.728h6.994c3.846 0 7.073-.03 7.17-.068.134-.051.178-.23.178-.728v-.66H14.326v.728zm.023 4.732l.033.757h14.23v-1.456l-7.148-.029-7.148-.029.033.757zm-.023 4.79v.73l7.143-.029 7.143-.029.033-.7.033-.7H14.325v.729z" fill="#000" /></svg>
                        </CardsAddItemIcon>
                        <CardsAddItemText>Garage Door</CardsAddItemText>
                        <CardsAddItemAddBtn onClick={addGarageDoor}>
                            <svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z" fill="#fff" /></svg>
                            ADD
                        </CardsAddItemAddBtn>
                    </CardsAddItem>
                </Grid>
                <Grid item xs={6}>
                    <CardsAddItem>
                        <CardsAddItemIcon>
                            <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.997.111C1.826.365.707 1.324.243 2.471L0 3.07v17.816c0 14.325.027 17.917.137 18.332.295 1.106 1.278 2.16 2.411 2.584.567.212.702.213 18.643.186l18.072-.028.583-.285c.743-.365 1.503-1.123 1.868-1.866l.286-.582V2.755l-.257-.526c-.367-.752-1.327-1.658-2.077-1.96l-.613-.247L21.316.003C7.859-.01 3.439.016 2.997.111zM39 1.544c.499.139 1.114.665 1.398 1.195l.234.436v35.632l-.228.42c-.278.514-.828 1.053-1.227 1.203-.204.077-5.811.111-18.135.111-14.783 0-17.897-.024-18.18-.142-.405-.17-.954-.695-1.207-1.156-.17-.309-.183-1.517-.209-18.026-.018-11.519.01-17.83.078-18.084.139-.517.734-1.195 1.29-1.47l.45-.222h17.683c11.15 0 17.82.039 18.053.103zm-26.421 19.08v11.824H14.158V10.27h13.789v22.178h1.474V8.798H12.58v11.825z" fill="#000" /></svg>
                        </CardsAddItemIcon>
                        <CardsAddItemText>Walk-in Door</CardsAddItemText>
                        <CardsAddItemAddBtn onClick={addWalkInDoor}>
                            <svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z" fill="#fff" /></svg>
                            ADD
                        </CardsAddItemAddBtn>
                    </CardsAddItem>
                </Grid>
                <Grid item xs={6}>
                    <CardsAddItem>
                        <CardsAddItemIcon>
                            <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.644.175C1.57.547.862 1.214.324 2.36l-.288.614-.03 17.497C-.013 32.336.014 38.2.089 38.687c.134.872.399 1.414 1.007 2.064a3.688 3.688 0 002.149 1.155c.488.084 5.713.108 18.247.087l17.564-.03.613-.248c.742-.299 1.688-1.194 2.07-1.96L42 39.23V2.765l-.273-.578c-.356-.754-.981-1.382-1.801-1.81l-.66-.345L21.228.01C4.087-.013 3.164-.005 2.644.175zm36.54 1.44c.635.239 1.25.971 1.392 1.657.078.377.104 5.905.085 18.04l-.028 17.497-.227.42c-.29.541-.604.829-1.193 1.096-.47.212-.585.214-18.078.216-13.57.002-17.703-.027-18.037-.126-.601-.178-1.353-.947-1.52-1.553-.1-.368-.123-4.376-.1-18.128l.03-17.654.292-.497c.348-.59 1.062-1.05 1.757-1.13.262-.03 8.285-.044 17.83-.03 15.602.02 17.398.04 17.798.191zm-25.69 11.317c-.028.072-.038 3.63-.023 7.908l.027 7.776 7.599.027 7.599.027V12.8H21.12c-5.964 0-7.587.028-7.626.132zm13.73 4.23v2.891l-6.18-.027-6.179-.027-.028-2.73c-.015-1.502-.005-2.791.023-2.864.04-.106 1.337-.133 6.207-.133h6.156v2.89zm0 7.2V27.2l-6.18-.027-6.179-.027-.028-2.73c-.015-1.503-.01-2.78.013-2.838.027-.071 2.102-.098 6.207-.079l6.166.028v2.837z" fill="#000" /></svg>
                        </CardsAddItemIcon>
                        <CardsAddItemText>Window</CardsAddItemText>
                        <CardsAddItemAddBtn onClick={addWindow}>
                            <svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z" fill="#fff" /></svg>
                            ADD
                        </CardsAddItemAddBtn>
                    </CardsAddItem>
                </Grid>
                <Grid item xs={6}>
                    <CardsAddItem>
                        <CardsAddItemIcon>
                            <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.015.213C.519.475.223.897.086 1.537c-.073.344-.1 6.788-.08 19.78C.032 39.015.05 40.61.208 40.9c.237.428.594.745 1.059.94.346.144 2.514.162 19.8.16 18.646-.002 19.426-.01 19.83-.196.486-.225.832-.57.99-.99.079-.206.113-6.266.113-19.89V1.332l-.252-.43a2.11 2.11 0 00-.671-.666L40.658 0 21.03.005C1.815.009 1.392.014 1.015.213zM40.503 1.66c.172.216.195 38.347.023 38.668-.107.2-.436.203-19.465.203-14.915 0-19.385-.029-19.482-.126-.198-.197-.182-38.654.015-38.818.104-.085 5.339-.125 19.25-.144 10.51-.014 19.202-.009 19.318.013.115.022.269.114.341.204zM8.875 8.787c-.038.038-.07 5.376-.07 11.864v11.796h1.471V10.29H31.705l.026 11.052.027 11.052.762.031.762.03-.027-11.843-.026-11.844-12.143-.025c-6.678-.014-12.174.006-12.211.044z" fill="#000" /></svg>
                        </CardsAddItemIcon>
                        <CardsAddItemText>Framed Openings</CardsAddItemText>
                        <CardsAddItemAddBtn onClick={addFramedOpening}>
                            <svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z" fill="#fff" /></svg>
                            ADD
                        </CardsAddItemAddBtn>
                    </CardsAddItem>
                </Grid>
                <Grid item xs={6}>
                    <CardsAddItem>
                        <CardsAddItemIcon>
                            <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.644.175C1.239.662.322 1.785.088 3.308c-.075.486-.101 6.35-.082 18.162l.03 17.445.244.604c.297.734 1.083 1.62 1.771 1.994.28.152.817.329 1.193.393.487.083 5.727.108 18.247.087l17.564-.03.613-.248c.742-.299 1.688-1.194 2.07-1.96L42 39.23V2.765l-.272-.579C41.383 1.45 40.64.706 39.87.33l-.603-.297L21.228.01C4.087-.013 3.164-.005 2.644.175zm36.54 1.44c.635.238 1.25.97 1.392 1.657.078.377.104 5.905.085 18.04l-.028 17.498-.227.42c-.29.54-.604.828-1.193 1.095-.47.213-.585.214-18.078.216-13.57.002-17.703-.027-18.037-.126-.602-.178-1.353-.947-1.52-1.553-.101-.368-.123-4.376-.1-18.127l.03-17.655.292-.497c.332-.564.972-.993 1.652-1.11.243-.04 8.281-.064 17.864-.05 15.653.022 17.469.041 17.869.192zM13.472 20.97l.027 7.645h15.145l.027-7.645.027-7.645H13.444l.027 7.645zm13.752.027V27.2l-6.179-.027-6.179-.027-.027-6.174-.027-6.174h12.412v6.201z" fill="#000" /></svg>
                        </CardsAddItemIcon>
                        <CardsAddItemText>Walk-in Door <br /> Frame-out</CardsAddItemText>
                        <CardsAddItemAddBtn onClick={addFramedWalkInDoor}>
                            <svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z" fill="#fff" /></svg>
                            ADD
                        </CardsAddItemAddBtn>
                    </CardsAddItem>
                </Grid>
                <Grid item xs={6}>
                    <CardsAddItem>
                        <CardsAddItemIcon>
                            <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.644.175C1.239.662.322 1.785.088 3.308c-.075.486-.101 6.35-.082 18.162l.03 17.445.244.604c.297.734 1.083 1.62 1.771 1.994.28.152.817.329 1.193.393.487.083 5.727.108 18.247.087l17.564-.03.613-.248c.742-.299 1.688-1.194 2.07-1.96L42 39.23V2.765l-.272-.579C41.383 1.45 40.64.706 39.87.33l-.603-.297L21.228.01C4.087-.013 3.164-.005 2.644.175zm36.54 1.44c.635.238 1.25.97 1.392 1.657.078.377.104 5.905.085 18.04l-.028 17.498-.227.42c-.29.54-.604.828-1.193 1.095-.47.213-.585.214-18.078.216-13.57.002-17.703-.027-18.037-.126-.602-.178-1.353-.947-1.52-1.553-.101-.368-.123-4.376-.1-18.127l.03-17.655.292-.497c.332-.564.972-.993 1.652-1.11.243-.04 8.281-.064 17.864-.05 15.653.022 17.469.041 17.869.192zM13.472 20.97l.027 7.645h15.145l.027-7.645.027-7.645H13.444l.027 7.645zm13.752.027V27.2l-6.179-.027-6.179-.027-.027-6.174-.027-6.174h12.412v6.201z" fill="#000" /></svg>
                        </CardsAddItemIcon>
                        <CardsAddItemText>Window <br /> Frame-out</CardsAddItemText>
                        <CardsAddItemAddBtn onClick={addFramedWindow}>
                            <svg width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z" fill="#fff" /></svg>
                            ADD
                        </CardsAddItemAddBtn>
                    </CardsAddItem>
                </Grid>
            </Grid>
        </CardsAddWrapper>
    )
}

export default CardsAdd
