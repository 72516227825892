import React from 'react'

import {
    DoorsAndWindowsLeftWrapper
} from './DoorsAndWindowsLeft.css'

const DoorsAndWindowsLeft = () => {
    return (
        <DoorsAndWindowsLeftWrapper>
            
        </DoorsAndWindowsLeftWrapper>
    )
}

export default DoorsAndWindowsLeft
