import styled from 'styled-components/macro'

export const CardsAddWrapper = styled.div`
    opacity: ${props => props.isActive ? 1 : 0.6};
    user-select: ${props => props.isActive ? 'auto' : 'none'};
    pointer-events: ${props => props.isActive ? 'auto' : 'none'};
`;

export const CardsAddItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;

    @media (max-width: 991px) {
        border: 1px solid #555;
    }
`;

export const CardsAddItemIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
`;

export const CardsAddItemText = styled.span`
    display: block;
    margin-bottom: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
`;

export const CardsAddItemAddBtn = styled.span`
    display: block;
    max-width: 120px;
    background: #eb7324;
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-size: 11px;
    justify-content: center;
    height: 20px;
    width: 60px;
    border-radius: 4px;
    text-transform: uppercase;
    margin: 0 auto;

    svg {
        margin-right: 0.25rem;
    }
`;
