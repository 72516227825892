export const colorsOptionsPremium = [
    {
        name: 'Barn Red',
        value: '#6f2026',
        premium: false
    },
    {
        name: 'Beige',
        value: '#ebe0d3',
        premium: false
    },
    {
        name: 'Black',
        value: '#060606',
        premium: false
    },
    {
        name: 'Brown',
        value: '#492e28',
        premium: false
    },
    {
        name: 'Burgundy',
        value: '#2f0e13',
        premium: false
    },
    {
        name: 'Charcoal',
        value: '#6c6c6c',
        premium: false
    },
    {
        name: 'Clay',
        value: '#9b9b9b',
        premium: false
    },
    {
        name: 'Copper',
        value: '#8b543a',
        premium: true
    },
    {
        name: 'Crimson Red',
        value: '#970e1e',
        premium: true
    },
    {
        name: 'Galvalume',
        value: '#d0d0d0',
        premium: false
    },
    {
        name: 'Mc Gray',
        value: '#9f9f9f',
        premium: false
    },
    {
        name: 'MC Green',
        value: '#0d3c26',
        premium: false
    },
    {
        name: 'Pewter Gray',
        value: '#898989',
        premium: false
    },
    {
        name: 'Quaker Gray',
        value: '#5b5b5b',
        premium: false
    },
    {
        name: 'Sandstone',
        value: '#d1ceba',
        premium: false
    },
    {
        name: 'Slate Blue',
        value: '#345a70',
        premium: false
    },
    {
        name: 'Tan',
        value: '#aa9179',
        premium: false
    },
    {
        name: 'White',
        value: '#ffffff',
        premium: false
    },
];

export const colorsOptionsWhite = [
    {
        name: 'White',
        value: '#ffffff',
        premium: false
    }
]

// export const colorsOptions = [
//     {
//         name: 'Barn Red',
//         value: '#6f2026',
//         premium: false,
//     },
//     {
//         name: 'Black',
//         value: '#231f20',
//         premium: false,
//     },
//     {
//         name: 'Burgundy',
//         value: '#2f0e13',
//         premium: false,
//     },
//     {
//         name: 'Clay',
//         value: '#9b9b9b',
//         premium: false,
//     },
//     {
//         name: 'Earth Brown',
//         value: '#6c451c',
//         premium: false,
//     },
//     {
//         name: 'Evergreen',
//         value: '#1a572d',
//         premium: false,
//     },
//     {
//         name: 'Galvalume',
//         value: '#d0d0d0',
//         premium: false,
//     },
//     {
//         name: 'Pebble Beige',
//         value: '#fae4bb',
//         premium: false,
//     },
//     {
//         name: 'Pewter Gray',
//         value: '#949599',
//         premium: false,
//     },
//     {
//         name: 'Quaker Gray',
//         value: '#77726e',
//         premium: false,
//     },
//     {
//         name: 'Rawhide',
//         value: '#d1b9a1',
//         premium: false,
//     },
//     {
//         name: 'Sandstone',
//         value: '#ebe1be',
//         premium: false,
//     },
//     {
//         name: 'Slate Blue',
//         value: '#578dbb',
//         premium: false,
//     },
//     {
//         name: 'White',
//         value: '#ffffff',
//         premium: false,
//     },
// ];