import React, { useContext, useState, useEffect } from 'react'
import {
    WallsScrollbarWrapper,
    WallsWrapper,
    WallsSelectWrapper,
    WallsWainscot,
    WallsWainscotColorWrapper
} from '../Walls.css'

import InputSelect from '../../parts/InputSelect/InputSelect'
import SectionTitle from '../../../SectionTitle/SectionTitle'
import Separator from '../../../../utils/Separator/Separator'
import ColorPicker from '../../parts/ColorPicker/ColorPicker'
import { colorsOptionsPremium } from '../../parts/ColorPicker/ColorPickerData.json'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Radio,
    RadioGroup

} from '@material-ui/core'
import { Color3, Plane, Axis, Space } from '@babylonjs/core';
import { GlobalState } from '../../../../App'
import { mainEndsWallClipPlanesChange, mainSidesWallClipPlanesChange } from '../../../../utils/functions'


const storageOptions = [
    { value: "5", label: "5 Feet" },
    { value: "10", label: "10 Feet" },
    { value: "15", label: "15 Feet" },
    { value: "20", label: "20 Feet" },
    { value: "25", label: "25 Feet" },
    { value: "30", label: "30 Feet" },
    { value: "35", label: "35 Feet" },
    { value: "40", label: "40 Feet" },
    { value: "45", label: "45 Feet" },
    { value: "50", label: "50 Feet" },
    { value: "55", label: "55 Feet" },
    { value: "60", label: "60 Feet" },
    { value: "65", label: "65 Feet" },
    { value: "70", label: "70 Feet" },
    { value: "75", label: "75 Feet" },
    { value: "80", label: "80 Feet" },
    { value: "85", label: "85 Feet" },
    { value: "90", label: "90 Feet" },
    { value: "95", label: "95 Feet" },
];

const sidesOptions = [
    { value: "Open", label: "Open", price: 0 },
    { value: "1 Panel (3')", label: "1 Panel (3')", price: 0 },
    { value: "2 Panel (6')", label: "2 Panel (6')", price: 100 },
    { value: "1/4 Closed", label: "1/4 Closed", price: 200 },
    { value: "1/2 Closed", label: "1/2 Closed", price: 300 },
    { value: "3/4 Closed", label: "3/4 Closed", price: 400 },
    { value: "Closed", label: "Closed", price: 500 }
];

const endsOptions = [
    { value: "Open", label: "Open", price: 0 },
    { value: "Gable", label: "Gable", price: 100 },
    { value: "Extended-gable", label: "Extended Gable", price: 200 },
    { value: "1/4 Closed", label: "1/4 Closed", price: 300 },
    { value: "1/2 Closed", label: "1/2 Closed", price: 400 },
    { value: "3/4 Closed", label: "3/4 Closed", price: 500 },
    { value: "Closed", label: "Closed", price: 600 },
];

const insulationOptions = [
    { value: "Select", label: "Select" },
    { value: "2-fiber-glass", label: "2'' Fiber Glass" },
];

const WallsCenter = () => {
    const GlobalStore = useContext(GlobalState);
    const scene = GlobalStore.sceneGlobalRef.current;
    const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");

    const [verticalPanels, setVetricalPanels] = useState(false);


    // const [leftSidePanel, setLeftSidePanel] = useState(false);
    // const [rightSidePanel, setRightSidePanel] = useState(false);

    const [insulationValue, setInsulationValue] = useState('Select');
    const [insulationCheckboxes, setInsulationCheckboxes] = useState(false);

    const [insulationRadio, setInsulationRadio] = useState('roof-only');

    const [wall, setWall] = useState('open');

    // const [jTrim, setJTrim] = useState(false);

    //const [wallIsOpen, setWallIsOpen] = useState(true);

    const presetData = GlobalStore.buildingType.customizationData.mainStructure;

    useEffect(() => {
        if (presetData.isStorageOn) {
            GlobalStore.setStorageMain(true)
            handleStorage(null, null, "init");
            handleAddStorage(presetData.storageLength);
        }
        else {
            GlobalStore.setStorageMain(false)
            handleStorage(null, null, "init");
        }
        switch (presetData.wallStyle) {
            case 'open':
                handleFullyOpen();
                handleWallChange(null, 'open')
                break;
            case 'enclosed':
                handleFullyEnclosed();
                handleWallChange(null, 'enclosed')
                break;

            default:
                break;
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     GlobalStore.setWainscot(false)
    // }, [wall])

    const handleWallChange = (e, i) => {
        i ? setWall(i) : setWall(e.target.value);

        // switch (e.target.value) {
        //     case 'open':
        //         setWallIsOpen(true)
        //         break;
        //     case 'enclosed':
        //         setWallIsOpen(false);
        //         break;

        //     default:
        //         break;
        // }
    }
    const handleVerticalPanels = () => {
        setVetricalPanels(!verticalPanels)
        const wallMaterial_main = scene.getMaterialByName("outerWalls_M");
        const wallMaterial_leanR = scene.getMaterialByName("outerWalls_RL")
        const wallMaterial_leanL = scene.getMaterialByName("outerWalls_LL");
        const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");
        if (!verticalPanels) {
            try {
                wallMaterial_main.bumpTexture.wAng = 1.57;
            } catch (error) {
                console.log("Error setting wallMaterial_main to vertical)")
            }
            try {
                wallMaterial_leanR.bumpTexture.wAng = 1.57;

            } catch (error) {
                console.log("Error setting wallMaterial_leanR to vertical)")
            }
            try {
                wallMaterial_leanL.bumpTexture.wAng = 1.57;

            } catch (error) {
                console.log("Error setting wallMaterial_leanL to vertical)")
            }
            try {
                wainscotMaterial.bumpTexture.wAng = 1.57;
            } catch (error) {
                console.log("Error setting wainscotMaterial to vertical)")
            }

        }
        else {
            try {
                wallMaterial_main.bumpTexture.wAng = 0;
            } catch (error) {
                console.log("Error setting wallMaterial_main to horizontal)")
            }
            try {
                wallMaterial_leanR.bumpTexture.wAng = 0;

            } catch (error) {
                console.log("Error setting wallMaterial_leanR to horizontal)")
            }
            try {
                wallMaterial_leanL.bumpTexture.wAng = 0;

            } catch (error) {
                console.log("Error setting wallMaterial_leanL to horizontal)")
            }
            try {
                wainscotMaterial.bumpTexture.wAng = 0;
            } catch (error) {
                console.log("Error setting wainscotMaterial to horizontal)")
            }
        }
    }
    // const handleLeftVerticalPanel = () => {
    //     setLeftSidePanel(!leftSidePanel)
    // }
    // const handleRightVerticalPanel = () => {
    //     setRightSidePanel(!rightSidePanel)
    // }
    const handleInsulationChange = (e) => {
        setInsulationValue(e.target.value)
        if (e.target.value === 'Select') {
            setInsulationCheckboxes(false)
            setInsulationRadio('roof-only')
        } else {
            setInsulationCheckboxes(true)
        }
    }
    const handleInsulationRadio = (event) => {
        setInsulationRadio(event.target.value)
    }
    // const handleJTrim = () => {
    //     setJTrim(!jTrim)
    // }
    const handleWainscot = () => {
        GlobalStore.setWainscot(!GlobalStore.wainscot)
        if (GlobalStore.wainscot) {
            GlobalStore.wainscotMeshes.current.map(mesh => {
                mesh.isVisible = false
            })
        }
        else {
            GlobalStore.wainscotMeshes.current.map(mesh => {
                if (mesh.name.includes("_LL")) {
                    if (GlobalStore.isLeftLeanActive.current) {
                        mesh.isVisible = true
                    }
                    else {
                        mesh.isVisible = false
                    }
                }
                else if (mesh.name.includes("_RL")) {
                    if (GlobalStore.isRightLeanActive.current) {
                        mesh.isVisible = true
                    }
                    else {
                        mesh.isVisible = false
                    }
                }
                else {
                    mesh.isVisible = true
                }
            })
        }

    }
    const handleWainscotColor = (color) => {
        wainscotMaterial.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
    }
    const handleFullyOpen = () => {
        const clipPlane = new Plane(0, -1, 0, 1000);
        GlobalStore.mainWallMeshes.current.map((wall) => {
            wall.onBeforeRenderObservable.add(function () {
                scene.resetCachedMaterial();
                scene.clipPlane2 = clipPlane;
            });
            wall.onAfterRenderObservable.add(function () {
                scene.clipPlane2 = null;
            });
            return true
        });

        GlobalStore.setPrice(GlobalStore.price - 1000);
        GlobalStore.setSelectedEndFront("Open");
        GlobalStore.setSelectedEndBack('Open');
        GlobalStore.setSelectedSidesLeft('Open');
        GlobalStore.setSelectedSidesRight('Open');
    }
    const handleFullyEnclosed = () => {
        const clipPlane = new Plane(0, -1, 0, 0);
        GlobalStore.mainWallMeshes.current.map((wall) => {
            wall.onBeforeRenderObservable.add(function () {
                scene.resetCachedMaterial();
                scene.clipPlane2 = clipPlane;
            });
            wall.onAfterRenderObservable.add(function () {
                scene.clipPlane2 = null;
            });
            return true
        });
        GlobalStore.setPrice(GlobalStore.price + 1000);
        GlobalStore.setSelectedEndFront("Closed");
        GlobalStore.setSelectedEndBack('Closed');
        GlobalStore.setSelectedSidesLeft('Closed');
        GlobalStore.setSelectedSidesRight('Closed');
    }
    const handleEndsFront = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("Profile_004");
        const mainFrontWalls_R = scene.getTransformNodeByName("frontWalls_R");
        const mainFrontWalls_L = scene.getTransformNodeByName("frontWalls_L");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedEndFront(targetValue)
        mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainFrontWalls_R, mainFrontWalls_L], scene)
    }
    const handleEndsBack = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("Profile_004");
        const mainBackWalls_R = scene.getTransformNodeByName("backWalls_R");
        const mainBackWalls_L = scene.getTransformNodeByName("backWalls_L");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedEndBack(targetValue)
        mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainBackWalls_R, mainBackWalls_L], scene)
    }
    const handleSidesLeft = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("Profile_004");
        const mainSideWalls_L = scene.getTransformNodeByName("sideWalls_L");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedSidesLeft(targetValue)
        mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainSideWalls_L], scene)
    }
    const handleSidesRight = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("Profile_004");
        const mainSideWalls_R = scene.getTransformNodeByName("sideWalls_R");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedSidesRight(targetValue)
        mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainSideWalls_R], scene)
    }
    const handleWallColor = (color) => {
        const wallMaterials = GlobalStore.outerWallsMaterials.current;
        wallMaterials.map(mat => mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace())
    }
    const handleStorage = (e, t, i) => {
        if (i) {
        }
        else {
            GlobalStore.setStorageMain(!GlobalStore.storageMain)
        }

        const mainParent_R = scene.getMeshByName("Main_Helper_R");
        const mainParent_L = scene.getMeshByName("Main_Helper_L");
        const storageBackWall_L = scene.getMeshByName("storage_main_backWall_L");
        const storageBackWall_R = scene.getMeshByName("storage_main_backWall_R");
        let mainStorage_R;
        mainParent_R.getChildMeshes().map(mesh => {
            if (mesh.name === "storage_main_R") {
                mainStorage_R = mesh;
            }
            return 0
        })
        let mainStorage_L;
        mainParent_L.getChildMeshes().map(mesh => {
            if (mesh.name === "storage_main_L") {
                mainStorage_L = mesh;
            }
            return 0
        })
        mainParent_R.computeWorldMatrix(true);
        const storagePlaneDistance = mainParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
        GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;

        if (GlobalStore.storageMain) {
            mainStorage_R.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
            mainStorage_L.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
            mainStorage_R.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
            mainStorage_L.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
            storageBackWall_L.isVisible = true;
            storageBackWall_R.isVisible = true;
        }
        else {
            mainStorage_R.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
            mainStorage_L.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
            storageBackWall_L.isVisible = false;
            storageBackWall_R.isVisible = false;
        }
    }
    const handleAddStorage = (targetValue) => {
        const mainParent_R = scene.getMeshByName("Main_Helper_R");
        const mainParent_L = scene.getMeshByName("Main_Helper_L");
        let mainStorage_R;
        mainParent_R.getChildMeshes().map(mesh => {
            if (mesh.name === "storage_main_R") {
                mainStorage_R = mesh;
            }
            return 0
        })
        let mainStorage_L;
        mainParent_L.getChildMeshes().map(mesh => {
            if (mesh.name === "storage_main_L") {
                mainStorage_L = mesh;
            }
            return 0
        })
        mainParent_R.computeWorldMatrix(true);
        const storagePlaneDistance = mainParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
        GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;

        mainStorage_R.setAbsolutePosition(storagePlaneDistance, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
        mainStorage_L.setAbsolutePosition(storagePlaneDistance, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
        mainStorage_R.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);
        mainStorage_L.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);

        GlobalStore.mainStorageTranslation.current = targetValue;
    }

    return (
        <WallsScrollbarWrapper>
            <WallsWrapper>
                <RadioGroup aria-label="walls" name="walls" value={wall} onChange={handleWallChange} row>
                    <FormControlLabel onChange={handleFullyOpen} value="open" control={<Radio />} label="Open" />
                    <FormControlLabel onChange={handleFullyEnclosed} value="enclosed" control={<Radio />} label="Fully Enclosed" />
                </RadioGroup>
                <Separator />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={verticalPanels}
                            onChange={handleVerticalPanels}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="Vertical Panels"
                />
                <Separator marginBottom={'5px'} />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={GlobalStore.storageMain}
                            onChange={handleStorage}
                            name="storage"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="Add Storage"
                />
                <WallsSelectWrapper checked={GlobalStore.storageMain}>
                    <InputSelect
                        onChange={handleAddStorage}
                        label="Utility Enclosed Area"
                        options={storageOptions}
                        inputName='add-storage'
                    />
                </WallsSelectWrapper>

                <Separator />

                <SectionTitle title={'ENDS'} />

                <InputSelect
                    onChange={handleEndsFront}
                    label="Front"
                    options={endsOptions}
                    inputName='ends-front'
                />

                <Separator marginBottom='15px' />

                <InputSelect
                    onChange={handleEndsBack}
                    label="Back"
                    options={endsOptions}
                    inputName='ends-back'
                />

                <Separator marginBottom='15px' />

                <SectionTitle title={'SIDES'} />

                <InputSelect
                    onChange={handleSidesLeft}
                    label="Left"
                    options={sidesOptions}
                    inputName='sides-left'
                />

                <Separator marginBottom='15px' />

                {/* <div>
                    {!verticalPanels && wallIsOpen && <FormControlLabel
                        control={
                            <Checkbox
                                checked={leftSidePanel}
                                onChange={handleLeftVerticalPanel}
                                name="sides-left-cut-panel"
                                color="primary"
                            />
                        }
                        style={{
                            color: '#222',
                            padding: '0 8px',
                        }}
                        label="Left Cut Panel"
                    />}
                </div> */}

                <Separator marginBottom='15px' />

                <InputSelect
                    onChange={handleSidesRight}
                    label="Right"
                    options={sidesOptions}
                    inputName='sides-right'
                />

                <Separator marginBottom='15px' />

                {/* {!verticalPanels && wallIsOpen && <FormControlLabel
                    control={
                        <Checkbox
                            checked={rightSidePanel}
                            onChange={handleRightVerticalPanel}
                            name="sides-right-cut-panel"
                            color="primary"
                        />
                    }
                    style={{
                        color: '#222',
                        padding: '0 8px',
                    }}
                    label="Right Cut Panel"
                />} */}

                <Separator marginBottom='15px' />

                {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={jTrim}
                            onChange={handleJTrim}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="J-Trim"
                /> */}

                <Separator marginBottom='30px' />

                <ColorPicker
                    onChange={handleWallColor}
                    title='Wall'
                    colorsOptions={colorsOptionsPremium}
                    checked={GlobalStore.wallsColor}
                />

                <Separator />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={true}
                            name="add-insulation"
                            color="primary"
                        />
                    }
                    style={{
                        color: '#222',
                    }}
                    label="Add Insulation"
                />
                <br />
                <FormControl fullWidth>
                    <InputLabel style={{ fontSize: '11px' }} id='insulation-select-label'>Insulation</InputLabel>
                    <Select
                        value={insulationValue}
                        onChange={handleInsulationChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                    >
                        {insulationOptions.map(({ label, value }, i) => {
                            return (
                                <MenuItem value={value} key={i}>{label}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                {insulationCheckboxes && <>
                    <RadioGroup
                        aria-label="insulation-radio"
                        name="insulation-radio"
                        value={insulationRadio}
                        onChange={handleInsulationRadio}
                        style={{ padding: '0 8px' }}
                    >
                        <FormControlLabel value="roof-only" control={<Radio />} label="Roof Only" />
                        <FormControlLabel value="full-building" control={<Radio />} label="Full Building" />
                    </RadioGroup>
                </>
                }

                <Separator />

                <WallsWainscot checked={wall}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={GlobalStore.wainscot}
                                onChange={handleWainscot}
                                name="wainscot"
                                color="primary"
                            />
                        }
                        style={{
                            color: '#222',
                        }}
                        label="Add Wainscot(Full Building)"
                    />
                    <WallsWainscotColorWrapper checked={GlobalStore.wainscot}>
                        <ColorPicker
                            onChange={handleWainscotColor}
                            title='wainscot'
                            colorsOptions={colorsOptionsPremium}
                            checked={GlobalStore.wainscotColor}
                        />
                    </WallsWainscotColorWrapper>
                </WallsWainscot>

            </WallsWrapper>
        </WallsScrollbarWrapper>
    )
}

export default WallsCenter
