import React, { useState, useContext } from 'react'
import { GlobalState } from '../../../App';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { CheckboxWithLabel } from 'formik-material-ui';

import { Field, Form, Formik } from 'formik'
import { ReactComponent as OnlineIcon } from '../../../images/icons/online.svg'
import { ReactComponent as ArrowRightIcon } from '../../../images/icons/right-arrow.svg'
import { StateText, StateTextContent, StateTextTitle, StateWrapper } from './State.css'

import SelectInput from '../parts/SelectInput/SelectInput';

const options = [
  { value: "Alabama", label: "Alabama" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Virginia", label: "Virginia" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
  { value: "Washington", label: "Washington" },
  { value: "Maine", label: "Maine" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "Vermont", label: "Vermont" },
];


export const State = (props) => {
  const GlobalStore = useContext(GlobalState);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleCheckbox = () => {
    if (GlobalStore.state !== '') {
      setIsConfirmed(!isConfirmed);
    }
  };

  return (
    <StateWrapper>
      <StateText>
        <StateTextTitle>
          DESIGN, PRICE, & ORDER
          <span>
            <OnlineIcon />
          </span>
        </StateTextTitle>
        <StateTextContent>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam neque, ipsum non possimus modi nam delectus magnam placeat dolorem! Alias, sapiente. Rerum ratione odio obcaecati.</StateTextContent>
      </StateText>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StateTextContent>Chose Your State for Installation</StateTextContent>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          state: '',
          stateCheckbox: false,
        }}
        validate={values => {
          const errors = {};
          if (values.stateCheckbox === false) {
            errors.stateCheckbox = 'Please confirm your state';
          } else if (values.state === '') {
            errors.state = 'Please pick a state'
          }
          if (values.stateCheckbox && values.state !== '') {
            setIsConfirmed(true);
          } else {
            setIsConfirmed(false);
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            GlobalStore.setState(values.state);
            // alert(JSON.stringify(values, null, 2));
          }, 500);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <SelectInput options={options} inputName='state' />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <FormControl>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="stateCheckbox"
                    Label={{ label: 'Yes, I Confirm My State' }}
                    style={{ fontWeight: 'bold' }}
                    onClick={handleCheckbox}
                  />
                  {isSubmitting && <LinearProgress />}
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isConfirmed}
                  onClick={submitForm}
                  style={{
                    color: '#fff',
                    backgroundColor: '#222'
                  }}
                >
                  PROCEED <ArrowRightIcon />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>


    </StateWrapper >
  )
}
