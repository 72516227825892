import styled from 'styled-components/macro';

export const InputBoxesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const InputBoxesItem = styled.div`
    position: relative;
    flex: 0 0 47%;
    margin-bottom: 15px;
    padding: 6px;
    background: #fff;
    box-shadow: 0 0 0 1px #222;
    transition: 0.2s ease;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 1.5px #222;

        img {
            transform: scale(1);
        }
    }
`;

export const InputBoxesIconChecked = styled.span`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;

    svg {
        width: 24px;
    }
`;

export const InputBoxesItemImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        transition: 0.2s ease-in;
        transform: scale(.95);
    }
`;

export const InputBoxesItemText = styled.span`
    display: block;
    text-align: center;
    margin-top: 5px;
`;
