import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Select } from 'formik-material-ui';
import { Field } from 'formik';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    border: 0,
    backgroundColor: "white",
    color: "#444",
    fontWeight: 'bold',
  },
});

const SelectInput = ({
  options, inputName
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root} style={{ width: '330px', margin: '0 auto' }}>
      <InputLabel htmlFor={inputName}>State</InputLabel>
      <Field
        component={Select}
        name={inputName}
        inputProps={{
          id: inputName,
        }}
        defaultValue=''
        className={classes.label}
      >
        {options.map(option => {
          return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        })}
      </Field>
    </FormControl>
  )
}

export default SelectInput