import React from 'react'
import { SectionTitleWrapper, SectionTitleText } from './SectionTitle.css'

const SectionTitle = ({ title }) => {
    return (
        <SectionTitleWrapper>
            <SectionTitleText>{title}</SectionTitleText>
        </SectionTitleWrapper>
    )
}

export default SectionTitle
