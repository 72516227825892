import styled from "styled-components/macro";

export const BuildMenuWrapper = styled.div`
	z-index: 4;
	height: 100%;
	margin-top: auto;
	width: 460px;
	position: absolute;
	top: 74px;
	left: 0;
	backdrop-filter: blur(30px) brightness(1.15);
	background-color: hsla(0, 0%, 100%, 0.25);
	transform: ${(props) =>
		props.isFullView ? "translateX(-100%)" : "translateX(0)"};
	transition: 0.3s ease-in-out;

	.tab-content {
		> .tab-pane {
			display: none;
		}
		> .active {
			display: block;
		}
	}

	.nav-link {
		display: flex;
		flex-direction: column;
		text-align: center;
		font-size: 11px;
		color: #222;
		padding: 15px 0;
		align-items: center;
		text-transform: uppercase;
		text-decoration: none;
		border-bottom: 1px solid rgba(35, 35, 35, 0.1);
		border-right: 1px solid rgba(35, 35, 35, 0.1);
		transition: all 0.2s ease;
		position: relative;
		border-radius: 0;

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 6px;
			height: 100%;
			background: #222;
			z-index: 5;
			opacity: 0;
			visibility: hidden;
		}

		&:focus {
			outline: none;
		}

		svg {
			margin-bottom: 10px;
		}

		span {
			font-weight: bold;
			user-select: none;
		}

		&.active {
			&::before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	@media (max-width: 991px) {
		position: static;
        width: 100%;
		background-color: #fff;
    }
`;

export const BuildMenuNav = styled.div`
	padding: 20px 25px 20px 15px;
	cursor: pointer;
	@media (max-width: 991px) {
		background-color: #fff;
    }
`;

export const BuildMenuNavNextBtn = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #222;
	color: #fff;
	border-radius: 52px;
	padding: 0.35rem 5px 0.35rem 1.25rem;

	@media (max-width: 991px) {
		max-width: 260px;
		margin-left: auto;
		justify-content: flex-start;
	}
`;

export const BuildMenuNavNextBtnText = styled.span`
	display: block;
`;

export const BuildMenuNavNextBtnTextSmall = styled.span`
	display: block;
	margin-top: 2px;
	font-size: 11px;
`;

export const BuildMenuNavNextBtnIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	background: #000;
	border-radius: 100%;
	@media (max-width: 991px) {
		margin-left: auto;
	}
`;

export const BuildMenuNavMobliePrice = styled.div`
	display: none;
	
	@media (max-width: 991px) {
		display: flex;
	}
`;