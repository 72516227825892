import styled from 'styled-components/macro';
import Scrollbar from 'react-scrollbars-custom';

export const WallsScrollbarWrapper = styled(Scrollbar)`
    width: 340px !important;
    height: calc(var(--vh, 1vh)*100 - 250px) !important;

    @media (max-width: 991px) {
        width: 100% !important;
        height: inherit !important;
        position: static !important;
    }
`

export const WallsWrapper = styled.div`
    padding: 15px;
    
    @media (max-width: 991px) {
        width: 100%;
    }
`;

export const WallsSelectWrapper = styled.div`
    opacity: ${props => props.checked ? 1 : 0.6};
    user-select: ${props => props.checked ? 'auto' : 'none'};
    pointer-events: ${props => props.checked ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;

export const WallsWainscot = styled.div`
    opacity: ${props => props.checked === 'enclosed' ? 1 : 0.6};
    user-select: ${props => props.checked === 'enclosed' ? 'auto' : 'none'};
    pointer-events: ${props => props.checked === 'enclosed' ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;

export const WallsWainscotColorWrapper = styled.div`
    opacity: ${props => props.checked ? 1 : 0.6};
    user-select: ${props => props.checked ? 'auto' : 'none'};
    pointer-events: ${props => props.checked ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;