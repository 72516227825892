import styled from "styled-components/macro";
import Scrollbar from 'react-scrollbars-custom';

export const ExtraOptionsScrollbarWrapper = styled(Scrollbar)`
    width: 340px !important;
    height: calc(var(--vh, 1vh)*100 - 250px) !important;

	@media (max-width: 991px) {
        width: 100% !important;
        height: inherit !important;
        position: static !important;
    }
`

export const ExtraOptionsWrapper = styled.div`
	padding: 15px;

	@media (max-width: 991px) {
		width: 100%;
	}
`;

export const ExtraOptionsGroup = styled.div`
	margin-bottom: 20px;
`;

export const ExtraOptionsGroupBtnWrap = styled.div`
	margin: 15px 0 0;
`;

export const ExtraOptionsAddBtn = styled.div`
	display: inline-block;
	max-width: 60px;
	margin-right: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	background-color: #eb7324;
	text-transform: uppercase;
	white-space: nowrap;
	cursor: pointer;
`;

export const ExtraOptionsRemoveBtn = styled(ExtraOptionsAddBtn)`
	background-color: #222;
	max-width: 100%;
`;
