import React, { useContext } from 'react'
import { GlobalState } from '../../App';

import {
    BuildingTypeWrapper,
    BuildingTypeContent,
    BuildingTypeContentTitle,
    BuildingTypeContentText,
    BuildingTypes,
    BuildingTypesItem,
    BuildingTypesItemImage,
    BuildingTypesItemTitle,
    BuildingTypesItemInfo,
    BuildingTypesItemInfoItem,
    BuildingTypesItemInfoItemLabel,
    BuildingTypesItemInfoItemValue,
    BuildingTypesItemButton,
} from './BuildingType.css';

import { ReactComponent as ArrowRightIcon } from '../../images/icons/right-arrow.svg'

import buildingTypesData from './BuildingTypes.json';

const BuildingType = () => {
    const GlobalStore = useContext(GlobalState);

    const handleBuildingTypeButton = (data) => {
        GlobalStore.setBuildingType(data)
        GlobalStore.setCanvasStyle("visible")
        // console.log("buildingTypeData", data)
        // if (GlobalStore.buildingType !== '') {
        // }
        //setGlobalCustomizationData(data);
        const customizationData = data.customizationData;
        const mainData = customizationData.mainStructure;
        const leftLeanData = customizationData.leftLeanTo;
        const rightLeanData = customizationData.rightLeanTo;

        GlobalStore.setMainWidth(mainData.width);
        GlobalStore.setMainHeight(mainData.height);
        GlobalStore.setMainLength(mainData.length);
        GlobalStore.setMainRoofPitch(mainData.roofPitch);

        GlobalStore.setLeanLWidth(leftLeanData.width);
        GlobalStore.setLeanLHeight(leftLeanData.height)
        GlobalStore.setLeanLLength(leftLeanData.length)
        GlobalStore.setLeanLRoofPitch(leftLeanData.roofPitch)

        GlobalStore.setLeanRWidth(rightLeanData.width);
        GlobalStore.setLeanRHeight(rightLeanData.height)
        GlobalStore.setLeanRLength(rightLeanData.length)
        GlobalStore.setLeanRRoofPitch(rightLeanData.roofPitch)
        GlobalStore.setStorageMain(false)
    }

    return (
        <BuildingTypeWrapper>
            <BuildingTypeContent>
                <BuildingTypeContentTitle>Choose a Building Type To Configure</BuildingTypeContentTitle>
                <BuildingTypeContentText>Pick an option closest to your need. You can customize your option by clicking the “Design” button.</BuildingTypeContentText>
            </BuildingTypeContent>
            <BuildingTypes>
                {
                    buildingTypesData.map((data) => {
                        let { id, productName, productImage, productImageDescription, width, height, length } = data;
                        return (
                            // here we use arrow function inside onClick to prevent handleBuildingTypeButton from invoking on render.
                            // we need it to invoke uppon click 
                            <BuildingTypesItem key={id} onClick={() => { handleBuildingTypeButton(data) }}>
                                <BuildingTypesItemImage>
                                    <img src={productImage} />
                                </BuildingTypesItemImage>
                                <BuildingTypesItemTitle>{productName}</BuildingTypesItemTitle>
                                <BuildingTypesItemInfo>
                                    <BuildingTypesItemInfoItem>
                                        <BuildingTypesItemInfoItemLabel>Width:</BuildingTypesItemInfoItemLabel>
                                        <BuildingTypesItemInfoItemValue>{width}</BuildingTypesItemInfoItemValue>
                                    </BuildingTypesItemInfoItem>
                                    <BuildingTypesItemInfoItem>
                                        <BuildingTypesItemInfoItemLabel>Length:</BuildingTypesItemInfoItemLabel>
                                        <BuildingTypesItemInfoItemValue>{length}</BuildingTypesItemInfoItemValue>
                                    </BuildingTypesItemInfoItem>
                                    <BuildingTypesItemInfoItem>
                                        <BuildingTypesItemInfoItemLabel>Height:</BuildingTypesItemInfoItemLabel>
                                        <BuildingTypesItemInfoItemValue>{height}</BuildingTypesItemInfoItemValue>
                                    </BuildingTypesItemInfoItem>
                                </BuildingTypesItemInfo>
                                <BuildingTypesItemButton variant='outlined' color='secondary'>
                                    Design {productName} <ArrowRightIcon />
                                </BuildingTypesItemButton>
                            </BuildingTypesItem>
                        )
                    })
                }
            </BuildingTypes>
        </BuildingTypeWrapper>
    )
}

export default BuildingType
