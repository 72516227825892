const buildingTypesData = [
  {
    id: 1,
    productName: 'Standard Carports',
    productImage: './img/buildingTypes/carports.png',
    productImageDescription: 'image',
    width: '12’-24’',
    height: '6’-16’',
    length: '21’-81’',
    customizationData: {
      mainStructure: {
        width: "12",
        height: "10",
        length: "21",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: [] //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      materials: {  // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      }
    }
  },
  {
    id: 2,
    productName: 'Triple Wide Carports',
    productImage: './img/buildingTypes/carports.png',
    productImageDescription: 'image',
    width: '26’-30’',
    height: '7’-20’',
    length: '21’-101’',
    customizationData: {
      mainStructure: {
        width: "26",
        height: "9",
        length: "26",
        roofPitch: "4/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []  //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      materials: {  // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      }
    }
  },
  {
    id: 3,
    productName: 'Utility Carports',
    productImage: './img/buildingTypes/carports.png',
    productImageDescription: 'image',
    width: '12’-24’',
    height: '7’-20’',
    length: '21’-101’',
    customizationData: {
      mainStructure: {
        width: "12",
        height: "9",
        length: "21",
        roofPitch: "3/12",
        isStorageOn: true,
        storageLength: "5",
        wallStyle: "open",
        addDoors: [] //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      materials: {  // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#492e28",
        roofMaterialValue: "#492e28",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      }
    }
  },
  {
    id: 4,
    productName: 'Standard Garages',
    productImage: './img/buildingTypes/carports.png',
    productImageDescription: 'image',
    width: '12’-24’',
    height: '6’-16’',
    length: '21’-81’',
    customizationData: {
      mainStructure: {
        width: "20",
        height: "10",
        length: "21",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        addDoors: ["addGarageDoor"], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      rightLeanTo: {
        isOn: true,
        width: "16",
        height: "6",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: []
      },
      materials: {  // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#0d3c26",
        roofMaterialValue: "#0d3c26",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      }
    }
  }
]

export default buildingTypesData