import { AdvancedDynamicTexture, Control, Ellipse, TextBlock, Rectangle } from '@babylonjs/gui/2D';
import {
    Vector3,
    Plane,
    MeshBuilder,
    StandardMaterial,
    Space,
    Mesh,
} from '@babylonjs/core';

export const generateFrames = (mesh, parent, frameSpacing, endFrameOffset, clipPlane, globalFrameRef, lengthScaleFactor, scene) => {
    let clonedFrames = [];
    parent.computeWorldMatrix(true)
    let parent_extendSize = parent.getBoundingInfo().boundingBox.extendSizeWorld;
    let parent_lengthSize = parent_extendSize.x / 10;
    let frameInstanceMin = mesh.clone(`${mesh.name}_cloned`);
    let frameInstanceMax = mesh.clone(`${mesh.name}_cloned`);
    frameInstanceMin.position.x = parent_lengthSize / lengthScaleFactor;
    frameInstanceMax.position.x = -parent_lengthSize / lengthScaleFactor;
    frameInstanceMin.scaling.x = -1 / lengthScaleFactor;
    frameInstanceMax.scaling.x = 1 / lengthScaleFactor;
    frameInstanceMin.isPickable = false;
    frameInstanceMax.isPickable = false;
    clonedFrames.push(frameInstanceMax, frameInstanceMin);
    for (let i = frameSpacing; i < (parent_lengthSize - endFrameOffset); i += frameSpacing) {
        let clonedFrameMin = mesh.clone(`${mesh.name}_cloned`);
        let clonedFrameMax = mesh.clone(`${mesh.name}_cloned`);
        clonedFrameMin.position.x -= i / lengthScaleFactor;
        clonedFrameMax.position.x += i / lengthScaleFactor;
        clonedFrameMin.scaling.x = 1 / lengthScaleFactor;
        clonedFrameMax.scaling.x = 1 / lengthScaleFactor;
        clonedFrameMin.isPickable = false;
        clonedFrameMax.isPickable = false;
        clonedFrames.push(clonedFrameMin, clonedFrameMax);
    }
    mesh.scaling.x = 1 / lengthScaleFactor;
    globalFrameRef.current.push(clonedFrames);

    const clipPlaneWholeScene = new Plane(0, -1, 0, 12.324542045593262);

    for (const frame of clonedFrames) {
        frame.onBeforeRenderObservable.add(function () {
            scene.resetCachedMaterial();
            scene.clipPlane = clipPlane;
            scene.clipPlane3 = clipPlaneWholeScene;
        });
        frame.onAfterRenderObservable.add(function () {
            scene.clipPlane = null;
            scene.clipPlane3 = null;
        });
    }
}
export const regenerateFrames = (parents, clipPlane, globalFrameRef, lengthScaleFactor, scene) => {
    parents.map((parent, i) => {
        parent.getChildMeshes().map((mesh) => {
            if (mesh.name.includes("multiFrameBase")) {
                generateFrames(
                    mesh,
                    parent,
                    2.4,
                    0.65,
                    clipPlane[i],
                    globalFrameRef,
                    lengthScaleFactor,
                    scene
                );
            }
            return true;
        });
        return true;
    });
}
export const recreatePlanes = (part, helper, parentName, GlobalStore, positions, scene) => {
    //hideBoundingBox and dispose the proper planes
    GlobalStore.cameraDistanceReferencePlanes.current.map(plane => {
        plane.showBoundingBox = false;
        if (plane.partOf === part) {
            plane.dispose();
        }
        return 0
    })
    //filter the globalArray of planes/remove the ones that belong to main
    GlobalStore.cameraDistanceReferencePlanes.current = GlobalStore.cameraDistanceReferencePlanes.current.filter((entry) => {
        return entry.partOf === part ? null : entry;
    })
    //get meshes from the scene
    const ground = scene.getMeshByName("Cylinder001");
    const dimHelper = scene.getMeshByName(parentName);
    const planeMat = scene.getMaterialByName("planeMat");
    //create new planes 

    positions.map(position => createReferencePlanes(dimHelper, helper, position, ground, planeMat, GlobalStore, scene))
}
export const createReferencePlanes = (parent, helper, side, ground, material, GlobalStore, scene) => {
    parent.computeWorldMatrix(true);
    const parentMax = parent.getBoundingInfo().boundingBox.maximumWorld;
    const parentMin = parent.getBoundingInfo().boundingBox.minimumWorld;
    const groundOffsetY = ground.getBoundingInfo().boundingBox.maximumWorld.y;
    const planeWidth_F = parentMax.z - parentMin.z;

    const planeWidth_S = parentMax.x - parentMin.x;
    let leanPlaneWidth_F_R = planeWidth_F;
    let leanPlaneWidth_F_L = planeWidth_F;
    if (helper !== null) {
        helper.computeWorldMatrix(true);
        const helperMax = helper.getBoundingInfo().boundingBox.maximumWorld;
        const helperMin = helper.getBoundingInfo().boundingBox.minimumWorld;
        leanPlaneWidth_F_R = parentMax.z - helperMax.z;
        leanPlaneWidth_F_L = parentMin.z - helperMin.z;
    }
    const planeHeight = parentMax.y - groundOffsetY;
    let plane;
    switch (side) {
        case 'front':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: planeWidth_F, height: planeHeight }, scene);
            plane.rotation.y = -Math.PI / 2
            plane.position.x = parentMax.x;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "main";
            break;
        case 'back':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: planeWidth_F, height: planeHeight }, scene);
            plane.rotation.y = Math.PI / 2
            plane.position.x = parentMin.x;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "main";
            break;
        case 'left':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: planeWidth_S, height: planeHeight }, scene);
            plane.position.z = parentMin.z
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "main";
            break;
        case 'right':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: planeWidth_S, height: planeHeight }, scene);
            plane.rotation.y = -Math.PI
            plane.position.z = parentMax.z;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "main";
            break;
        case 'front_L':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: leanPlaneWidth_F_L, height: planeHeight }, scene);
            plane.rotation.y = -Math.PI / 2
            plane.position.x = parentMax.x;
            plane.position.z = parentMin.z - leanPlaneWidth_F_L / 2;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "leftLean";
            break;
        case 'back_L':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: leanPlaneWidth_F_L, height: planeHeight }, scene);
            plane.rotation.y = Math.PI / 2
            plane.position.x = parentMin.x;
            plane.position.z = parentMin.z - leanPlaneWidth_F_L / 2;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "leftLean";
            break;
        case 'left_L':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: planeWidth_S, height: planeHeight }, scene);
            plane.position.z = parentMin.z;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "leftLean";
            break;
        case 'front_R':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: leanPlaneWidth_F_R, height: planeHeight }, scene);
            plane.rotation.y = -Math.PI / 2
            plane.position.x = parentMax.x;
            plane.position.z = parentMax.z - leanPlaneWidth_F_R / 2;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "rightLean";
            break;
        case 'back_R':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: leanPlaneWidth_F_R, height: planeHeight }, scene);
            plane.rotation.y = Math.PI / 2
            plane.position.x = parentMin.x;
            plane.position.z = parentMax.z - leanPlaneWidth_F_R / 2;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "rightLean";
            break;
        case 'right_R':
            plane = new MeshBuilder.CreatePlane("referencePlane_" + side, { width: planeWidth_S, height: planeHeight }, scene);
            plane.rotation.y = -Math.PI
            plane.position.z = parentMax.z;
            plane.position.y = groundOffsetY + planeHeight / 2;
            plane.partOf = "rightLean";
            break;
        default:
            break;
    }
    plane.material = material;
    plane.isPickable = false;
    GlobalStore.cameraDistanceReferencePlanes.current.push(plane);
    return plane;
}
export const createDnWGUI = (mesh, scene, GlobalStore, options) => {
    mesh.computeWorldMatrix(true);
    const meshSize = mesh.getBoundingInfo().boundingBox.extendSizeWorld.scale(2);
    const meshWidth = meshSize.z;
    const meshHeight = meshSize.y;
    // const meshMax = mesh.getBoundingInfo().boundingBox.maximumWorld;
    // const meshMin = mesh.getBoundingInfo().boundingBox.minimumWorld;
    const meshCenter = mesh.getBoundingInfo().boundingBox.centerWorld;
    const guiPlane = new MeshBuilder.CreatePlane("guiPlane", { width: meshWidth, height: meshHeight }); guiPlane.position = new Vector3(meshCenter.x, meshCenter.y, meshCenter.z)

    guiPlane.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD);

    guiPlane.setParent(mesh);
    const guiPlaneMaterial = new StandardMaterial("guiPlaneMaterial", scene);
    guiPlaneMaterial.alpha = 0;
    guiPlane.material = guiPlaneMaterial;
    //create advancedYnamicTexture for mesh 
    const advancedTexture = new AdvancedDynamicTexture.CreateForMesh(
        guiPlane,
        512,
        512
    );
    //deleteButton
    const deleteBtn = new Ellipse();
    deleteBtn.width = "90px"
    deleteBtn.height = "90px";
    deleteBtn.thickness = 0;
    deleteBtn.background = "green";
    deleteBtn.isPointerBlocker = true;
    deleteBtn.hoverCursor = 'pointer'
    deleteBtn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    deleteBtn.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    deleteBtn.topInPixels = 10;
    deleteBtn.leftInPixels = 10;
    advancedTexture.addControl(deleteBtn);

    const deleteText = new TextBlock();
    deleteText.text = "Delete";
    deleteText.isPointerBlocker = false;
    deleteText.color = "white";
    deleteText.fontSize = 20;
    deleteBtn.addControl(deleteText);

    const editBtn = new Ellipse();
    editBtn.width = "90px"
    editBtn.height = "90px";
    editBtn.thickness = 0;
    editBtn.isPointerBlocker = true;
    editBtn.hoverCursor = 'pointer'
    editBtn.background = "green";
    editBtn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    editBtn.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;

    editBtn.topInPixels = 10;
    editBtn.leftInPixels = -10;
    advancedTexture.addControl(editBtn);

    const editText = new TextBlock();
    editText.text = "Edit";
    editText.isPointerBlocker = false;
    editText.color = "white";
    editText.fontSize = 20;
    editBtn.addControl(editText);

    editBtn.onPointerClickObservable.add(function () {
        if (mesh.name.indexOf("framedOpening") > -1 || mesh.name.indexOf("garageDoor") > -1) {
            GlobalStore.setHaveAngle(true);
        }
        else {
            GlobalStore.setHaveAngle(false);
        }
        GlobalStore.setDoorOptions(options);
        GlobalStore.setDraggableIsOpen(true);
        GlobalStore.currentDoors.current = [];
        GlobalStore.currentDoors.current.push(guiPlane, mesh);
    })

    deleteBtn.onPointerClickObservable.add(function () {
        GlobalStore.setModalIsOpen(true);
        GlobalStore.currentDoors.current = [];
        GlobalStore.currentDoors.current.push(guiPlane, mesh);
    })
}
export const createMeasurementsGUI = (GlobalStore, scene, partOf, targetValue) => {

    //get helper elements
    const dimensionHelper_M = scene.getMeshByName("dimensionHelper_M");
    const dimensionHelper_R = scene.getMeshByName("dimensionHelper_R");
    const dimensionHelper_L = scene.getMeshByName("dimensionHelper_L");
    const ground = scene.getMeshByName("Cylinder001");
    //compute world matrices
    dimensionHelper_M.computeWorldMatrix(true);
    dimensionHelper_R.computeWorldMatrix(true);
    dimensionHelper_L.computeWorldMatrix(true);
    //set default parameters
    const offset = 4;
    const heightOffset = 0.5;
    const lineThickness = 0.15;
    let rightHeightCorrection_main;
    switch (GlobalStore.mainMorphLevel.current) {
        case 0:
            rightHeightCorrection_main = 4;
            break;
        case 0.33:
            rightHeightCorrection_main = 5;
            break;
        case 0.66:
            rightHeightCorrection_main = 6;
            break;
        case 1:
            rightHeightCorrection_main = 7;
            break;

        default:
            break;
    }

    //calculate bounds
    //main
    const dim_M_min = dimensionHelper_M.getBoundingInfo().boundingBox.minimumWorld;
    const dim_M_max = dimensionHelper_M.getBoundingInfo().boundingBox.maximumWorld;
    const dim_M_center = dimensionHelper_M.getBoundingInfo().boundingBox.centerWorld;
    //leanR
    const dim_RL_min = dimensionHelper_R.getBoundingInfo().boundingBox.minimumWorld;
    const dim_RL_max = dimensionHelper_R.getBoundingInfo().boundingBox.maximumWorld;
    //leanL
    const dim_LL_min = dimensionHelper_L.getBoundingInfo().boundingBox.minimumWorld;
    const dim_LL_max = dimensionHelper_L.getBoundingInfo().boundingBox.maximumWorld;
    //ground
    const groundMaxY = ground.getBoundingInfo().boundingBox.maximumWorld.y;

    //CREATE LINE PATHS 
    //MAIN
    //MAIN FRONT LINE PATH
    const main_dim_front_start = new Vector3(dim_M_max.x + offset, groundMaxY + heightOffset, dim_M_min.z)
    const main_dim_front_end = new Vector3(dim_M_max.x + offset, groundMaxY + heightOffset, dim_M_max.z)
    const main_dim_front = [main_dim_front_start, main_dim_front_end]
    //MAIN BACK LINE PATH
    const main_dim_back_start = new Vector3(dim_M_min.x - offset, groundMaxY + heightOffset, dim_M_min.z)
    const main_dim_back_end = new Vector3(dim_M_min.x - offset, groundMaxY + heightOffset, dim_M_max.z)
    const main_dim_back = [main_dim_back_start, main_dim_back_end]
    //MAIN RIGHT LINE PATH
    const main_dim_right_start = new Vector3(dim_M_max.x, groundMaxY + heightOffset, dim_M_max.z + offset)
    const main_dim_right_end = new Vector3(dim_M_min.x, groundMaxY + heightOffset, dim_M_max.z + offset)
    const main_dim_right = [main_dim_right_start, main_dim_right_end]
    //MAIN LEFT LINE PATH
    const main_dim_left_start = new Vector3(dim_M_max.x, groundMaxY + heightOffset, dim_M_min.z - offset)
    const main_dim_left_end = new Vector3(dim_M_min.x, groundMaxY + heightOffset, dim_M_min.z - offset)
    const main_dim_left = [main_dim_left_start, main_dim_left_end]
    //MAIN LEFT FRONT HEIGHT PATH
    const main_dim_leftFrontHeight_start = new Vector3(dim_M_max.x, groundMaxY, dim_M_min.z - offset / 2)
    const main_dim_leftFrontHeight_end = new Vector3(dim_M_max.x, dim_M_max.y, dim_M_min.z - offset / 2)
    const main_dim_leftFrontHeight = [main_dim_leftFrontHeight_start, main_dim_leftFrontHeight_end]
    //MAIN LEFT BACK HEIGHT PATH
    const main_dim_leftBackHeight_start = new Vector3(dim_M_min.x, groundMaxY, dim_M_min.z - offset / 2)
    const main_dim_leftBackHeight_end = new Vector3(dim_M_min.x, dim_M_max.y, dim_M_min.z - offset / 2)
    const main_dim_leftBackHeight = [main_dim_leftBackHeight_start, main_dim_leftBackHeight_end]
    //MAIN RIGHT FRONT HEIGHT PATH
    const main_dim_rightFrontHeight_start = new Vector3(dim_M_max.x, groundMaxY, dim_M_max.z + offset / 2)
    const main_dim_rightFrontHeight_end = new Vector3(dim_M_max.x, dim_M_max.y + rightHeightCorrection_main, dim_M_max.z + offset / 2)
    const main_dim_rightFrontHeight = [main_dim_rightFrontHeight_start, main_dim_rightFrontHeight_end]
    //MAIN RIGHT BACK HEIGHT PATH
    const main_dim_rightBackHeight_start = new Vector3(dim_M_min.x, groundMaxY, dim_M_max.z + offset / 2)
    const main_dim_rightBackHeight_end = new Vector3(dim_M_min.x, dim_M_max.y + rightHeightCorrection_main, dim_M_max.z + offset / 2)
    const main_dim_rightBackHeight = [main_dim_rightBackHeight_start, main_dim_rightBackHeight_end]
    //MAIN TOP FRONT
    const main_dim_topFront_start = new Vector3(dim_M_max.x, dim_M_max.y + rightHeightCorrection_main, dim_M_center.z)
    const main_dim_topFront_end = new Vector3(dim_M_max.x, dim_M_max.y + rightHeightCorrection_main, dim_M_max.z + offset / 2)
    const main_dim_topFront = [main_dim_topFront_start, main_dim_topFront_end]
    // //MAIN TOP BACK
    const main_dim_topBack_start = new Vector3(dim_M_min.x, dim_M_max.y + rightHeightCorrection_main, dim_M_center.z)
    const main_dim_topBack_end = new Vector3(dim_M_min.x, dim_M_max.y + rightHeightCorrection_main, dim_M_max.z + offset / 2)
    const main_dim_topBack = [main_dim_topBack_start, main_dim_topBack_end]

    //LEAN RIGHT
    //LEAN RIGHT FRONT LINE
    const leanR_dim_front_start = new Vector3(dim_RL_max.x + offset, groundMaxY + heightOffset, dim_M_max.z)
    const leanR_dim_front_end = new Vector3(dim_RL_max.x + offset, groundMaxY + heightOffset, dim_RL_max.z)
    const leanR_dim_front = [leanR_dim_front_start, leanR_dim_front_end]
    //LEAN RIGHT BACK LINE 
    const leanR_dim_back_start = new Vector3(dim_RL_min.x - offset, groundMaxY + heightOffset, dim_M_max.z)
    const leanR_dim_back_end = new Vector3(dim_RL_min.x - offset, groundMaxY + heightOffset, dim_RL_max.z)
    const leanR_dim_back = [leanR_dim_back_start, leanR_dim_back_end]
    //LEAN RIGHT FRONT HEIGHT
    const leanR_dim_rightFrontHeight_start = new Vector3(dim_RL_max.x, groundMaxY, dim_RL_max.z + offset / 2)
    const leanR_dim_rightFrontHeight_end = new Vector3(dim_RL_max.x, dim_RL_max.y, dim_RL_max.z + offset / 2)
    const leanR_dim_rightFrontHeight = [leanR_dim_rightFrontHeight_start, leanR_dim_rightFrontHeight_end]
    //LEAN RIGHT BACK HEIGHT
    const leanR_dim_rightBackHeight_start = new Vector3(dim_RL_min.x, groundMaxY, dim_RL_max.z + offset / 2)
    const leanR_dim_rightBackHeight_end = new Vector3(dim_RL_min.x, dim_RL_max.y, dim_RL_max.z + offset / 2)
    const leanR_dim_rightBackHeight = [leanR_dim_rightBackHeight_start, leanR_dim_rightBackHeight_end]
    //LEAN RIGHT LINE 
    const leanR_dim_right_start = new Vector3(dim_RL_max.x, groundMaxY + heightOffset, dim_RL_max.z + offset)
    const leanR_dim_right_end = new Vector3(dim_RL_min.x, groundMaxY + heightOffset, dim_RL_max.z + offset)
    const leanR_dim_right = [leanR_dim_right_start, leanR_dim_right_end]

    //LEAN LEFT
    //LEAN LEFT FRONT LINE
    const leanL_dim_front_start = new Vector3(dim_LL_max.x + offset, groundMaxY + heightOffset, dim_LL_min.z)
    const leanL_dim_front_end = new Vector3(dim_LL_max.x + offset, groundMaxY + heightOffset, dim_M_min.z)
    const leanL_dim_front = [leanL_dim_front_start, leanL_dim_front_end]
    //LEAN LEFT BACK LINE 
    const leanL_dim_back_start = new Vector3(dim_LL_min.x - offset, groundMaxY + heightOffset, dim_LL_min.z)
    const leanL_dim_back_end = new Vector3(dim_LL_min.x - offset, groundMaxY + heightOffset, dim_M_min.z)
    const leanL_dim_back = [leanL_dim_back_start, leanL_dim_back_end]
    //LEAN LEFT FRONT HEIGHT
    const leanL_dim_leftFrontHeight_start = new Vector3(dim_LL_max.x, groundMaxY, dim_LL_min.z - offset / 2)
    const leanL_dim_leftFrontHeight_end = new Vector3(dim_LL_max.x, dim_LL_max.y, dim_LL_min.z - offset / 2)
    const leanL_dim_leftFrontHeight = [leanL_dim_leftFrontHeight_start, leanL_dim_leftFrontHeight_end]
    //LEAN LEFT BACK HEIGHT
    const leanL_dim_leftBackHeight_start = new Vector3(dim_LL_min.x, groundMaxY, dim_LL_min.z - offset / 2)
    const leanL_dim_leftBackHeight_end = new Vector3(dim_LL_min.x, dim_LL_max.y, dim_LL_min.z - offset / 2)
    const leanL_dim_leftBackHeight = [leanL_dim_leftBackHeight_start, leanL_dim_leftBackHeight_end]
    //LEAN LEFT LINE 
    const leanL_dim_left_start = new Vector3(dim_LL_max.x, groundMaxY + heightOffset, dim_LL_min.z - offset)
    const leanL_dim_left_end = new Vector3(dim_LL_min.x, groundMaxY + heightOffset, dim_LL_min.z - offset)
    const leanL_dim_left = [leanL_dim_left_start, leanL_dim_left_end]

    let mainVisible;
    let leftVisible;
    let rightVisible;

    //decide which part of gui will be visible when user changes parameters
    switch (partOf) {
        case "main":
            if (GlobalStore.isMeasure) {
                mainVisible = true;
                GlobalStore.isLeftLeanActive.current ? leftVisible = true : leftVisible = false
                GlobalStore.isRightLeanActive.current ? rightVisible = true : rightVisible = false
            }
            else {
                rightVisible = false;
                leftVisible = false
                mainVisible = false;
            }
            break;
        case "left":
            if (GlobalStore.isMeasure) {
                leftVisible = true;
                mainVisible = true;
                GlobalStore.isRightLeanActive.current ? rightVisible = true : rightVisible = false
            }
            else {
                rightVisible = false;
                leftVisible = false
                mainVisible = false;
            }
            break;
        case "right":
            if (GlobalStore.isMeasure) {
                rightVisible = true;
                mainVisible = true;
                GlobalStore.isLeftLeanActive.current ? leftVisible = true : leftVisible = false
            }
            else {
                rightVisible = false;
                leftVisible = false
                mainVisible = false;
            }
            break;

        default:
            rightVisible = false;
            leftVisible = false
            mainVisible = false;
            break;
    }


    let mainWidth = GlobalStore.mainWidth;
    let mainHeight = GlobalStore.mainHeight;
    let mainLength = GlobalStore.mainLength;
    let mainRoofPitch = GlobalStore.mainRoofPitch;

    let leanRWidth = GlobalStore.leanRWidth;
    let leanRHeight = GlobalStore.leanRHeight;
    let leanRLength = GlobalStore.leanRLength;
    let leanRRoofPitch = GlobalStore.leanRRoofPitch;

    let leanLWidth = GlobalStore.leanLWidth;
    let leanLHeight = GlobalStore.leanLHeight;
    let leanLLength = GlobalStore.leanLLength;
    let leanLRoofPitch = GlobalStore.leanLRoofPitch;


    if (targetValue) {
        if (partOf === "main") {
            switch (targetValue[0]) {
                case "height":
                    mainHeight = targetValue[1]
                    break;
                case "width":
                    mainWidth = targetValue[1]
                    break;
                case "length":
                    mainLength = targetValue[1]
                    break;
                case "roofPitch":
                    mainRoofPitch = targetValue[1]
                    break;

                default:
                    break;
            }
        }
        if (partOf === "right") {
            switch (targetValue[0]) {
                case "height":
                    leanRHeight = targetValue[1]
                    break;
                case "width":
                    leanRWidth = targetValue[1]
                    break;
                case "length":
                    leanRLength = targetValue[1]
                    break;

                default:
                    break;
            }
        }
        if (partOf === "left") {
            switch (targetValue[0]) {
                case "height":
                    leanLHeight = targetValue[1]
                    break;
                case "width":
                    leanLWidth = targetValue[1]
                    break;
                case "length":
                    leanLLength = targetValue[1]
                    break;

                default:
                    break;
            }
        }
    }
    //MAIN
    createLine("main_front", main_dim_front, lineThickness, 8, "Z", GlobalStore, mainVisible, scene, [mainWidth]);
    createLine("main_back", main_dim_back, lineThickness, 8, "Z", GlobalStore, mainVisible, scene, [mainWidth]);
    createLine("main_right", main_dim_right, lineThickness, 8, "X", GlobalStore, mainVisible, scene, [mainLength]);
    createLine("main_left", main_dim_left, lineThickness, 8, "X", GlobalStore, mainVisible, scene, [mainLength]);
    createLine("main_leftFrontHeight", main_dim_leftFrontHeight, lineThickness, 8, "Y", GlobalStore, mainVisible, scene, [mainHeight]);
    createLine("main_leftBackHeight", main_dim_leftBackHeight, lineThickness, 8, "Y", GlobalStore, mainVisible, scene, [mainHeight]);
    createLine("main_rightFrontHeight", main_dim_rightFrontHeight, lineThickness, 8, "Y", GlobalStore, mainVisible, scene, [mainHeight, mainRoofPitch]);
    createLine("main_rightBackHeight", main_dim_rightBackHeight, lineThickness, 8, "Y", GlobalStore, mainVisible, scene, [mainHeight, mainRoofPitch]);
    createLine("main_topFront", main_dim_topFront, lineThickness, 8, "Z", GlobalStore, mainVisible, scene, 0);
    createLine("main_topBack", main_dim_topBack, lineThickness, 8, "Z", GlobalStore, mainVisible, scene, 0);
    //LEAN RIGHT
    createLine("leanR_front", leanR_dim_front, lineThickness, 8, "Z", GlobalStore, rightVisible, scene, [leanRWidth]);
    createLine("leanR_back", leanR_dim_back, lineThickness, 8, "Z", GlobalStore, rightVisible, scene, [leanRWidth]);
    createLine("leanR_rightFrontHeight", leanR_dim_rightFrontHeight, lineThickness, 8, "Y", GlobalStore, rightVisible, scene, [leanRHeight]);
    createLine("leanR_rightBackHeight", leanR_dim_rightBackHeight, lineThickness, 8, "Y", GlobalStore, rightVisible, scene, [leanRHeight]);
    createLine("leanR_right", leanR_dim_right, lineThickness, 8, "X", GlobalStore, rightVisible, scene, [leanRLength]);
    //LEAN LEFT
    createLine("leanL_front", leanL_dim_front, lineThickness, 8, "Z", GlobalStore, leftVisible, scene, [leanLWidth]);
    createLine("leanL_back", leanL_dim_back, lineThickness, 8, "Z", GlobalStore, leftVisible, scene, [leanLWidth]);
    createLine("leanL_leftFrontHeight", leanL_dim_leftFrontHeight, lineThickness, 8, "Y", GlobalStore, leftVisible, scene, [leanLHeight]);
    createLine("leanL_leftBackHeight", leanL_dim_leftBackHeight, lineThickness, 8, "Y", GlobalStore, leftVisible, scene, [leanLHeight]);
    createLine("leanL_left", leanL_dim_left, lineThickness, 8, "X", GlobalStore, leftVisible, scene, [leanLLength]);
}
const createMeasurementPlanes = (name, line, lineMin, lineMax, lineCenter, orientation, scene, dimensionValues) => {
    if (name !== "main_topFront" && name !== "main_topBack") {
        const plane = new MeshBuilder.CreatePlane(name + "_plane_UIDIM", { width: 7, height: 4 }, scene);
        plane.position = new Vector3(lineCenter.x * 10, lineCenter.y * 10, lineCenter.z * 10)
        plane.isVisible = line.isVisible;
        plane.setParent(line);

        switch (orientation) {
            case "X":
                plane.position.y += 2;
                if (name.indexOf("right") > -1) {
                    plane.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                }
                break;
            case "Y":
                plane.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD);
                if (name.indexOf("Front") > -1) {
                    plane.position.x += 1;
                }
                else {
                    plane.position.x -= 1;
                    plane.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                }

                if (name === 'main_rightBackHeight' || name === 'main_rightFrontHeight') {
                    plane.position.y = lineMax.y * 10;
                }
                break;
            case "Z":
                plane.position.y += 2;
                plane.rotate(new Vector3(0, 1, 0), -Math.PI / 2, Space.WORLD);
                if (name.indexOf("back") > -1 || name.indexOf("main_topBack") > -1) {
                    plane.rotate(new Vector3(0, 1, 0), Math.PI, Space.WORLD);
                }

                break;
            default:
                break;
        }

        const advancedTexture = AdvancedDynamicTexture.CreateForMesh(plane, 256, 256);
        var rect = new Rectangle();
        rect.width = 1;
        rect.height = 1;
        rect.thickness = 0;
        rect.background = "white";
        advancedTexture.addControl(rect);

        let text = new TextBlock();
        text.color = "black";
        text.fontSize = 90;
        text.fontWeight = "600"
        text.background = "white"
        text.scaleY = 2;
        rect.addControl(text);


        if (dimensionValues !== undefined) {
            if (dimensionValues.length === 1) {
                text.text = dimensionValues[0];
            }
            else if (dimensionValues.length > 1) {
                const splitRoofPitchValue = dimensionValues[1].split('/');
                const roofPitchValue = splitRoofPitchValue[0]
                const value = (parseFloat(dimensionValues[0]) + parseFloat(roofPitchValue)).toString();
                text.text = value;
            }
        }
        else {
            text.text = "NaN"
        }

        return plane;
    }

}
const createLine = (name, path, thickness, tesselation, orientation, GlobalStore, isVisible, scene, dimensionValues) => {

    const lineMaterial = scene.getMaterialByName("lineMaterial");
    const arrow = scene.getMeshByName("arrowPoint");
    arrow.material = lineMaterial;
    const line = Mesh.CreateTube(name + "_line_UIDIM", path, thickness, tesselation, null, Mesh.CAP_ALL, scene, false, Mesh.DOUBLESIDE);
    line.material = lineMaterial;

    const arrowLeft = arrow.clone(name + "_arrowLeft");
    const arrowRight = arrow.clone(name + "_arrowRight");

    line.isVisible = isVisible;
    arrowLeft.isVisible = isVisible;
    arrowRight.isVisible = isVisible;


    line.computeWorldMatrix(true);
    const lineMax = line.getBoundingInfo().boundingBox.maximumWorld.scale(0.1);
    const lineMin = line.getBoundingInfo().boundingBox.minimumWorld.scale(0.1);
    const lineCenter = line.getBoundingInfo().boundingBox.centerWorld.scale(0.1);
    switch (orientation) {
        case "X":
            arrowLeft.position = new Vector3(lineMax.x, lineCenter.y, lineCenter.z)
            arrowRight.position = new Vector3(lineMin.x, lineCenter.y, lineCenter.z)
            arrowLeft.rotate(new Vector3(0, 0, 1), -Math.PI / 2, Space.WORLD);
            arrowRight.rotate(new Vector3(0, 0, 1), Math.PI / 2, Space.WORLD);
            break;
        case "Y":
            arrowLeft.position = new Vector3(lineCenter.x, lineMin.y, lineCenter.z)
            arrowRight.position = new Vector3(lineCenter.x, lineMax.y, lineCenter.z)
            arrowLeft.rotate(new Vector3(1, 0, 0), -Math.PI, Space.WORLD);

            break;
        case "Z":
            arrowLeft.position = new Vector3(lineCenter.x, lineCenter.y, lineMin.z)
            arrowRight.position = new Vector3(lineCenter.x, lineCenter.y, lineMax.z)
            arrowLeft.rotate(new Vector3(1, 0, 0), Math.PI / 2, Space.WORLD);
            arrowRight.rotate(new Vector3(1, 0, 0), -Math.PI / 2, Space.WORLD);
            break;
        default:
            break;
    }

    const plane = createMeasurementPlanes(name, line, lineMin, lineMax, lineCenter, orientation, scene, dimensionValues)

    GlobalStore.currentDimensionElements.current.push(line);
    GlobalStore.currentDimensionElements.current.push(arrowLeft);
    GlobalStore.currentDimensionElements.current.push(arrowRight);
    if (plane !== undefined) {
        GlobalStore.currentDimensionElements.current.push(plane);
    }

}
export const switchMorphLevel = (parents, morphLevel) => {
    parents.map((parent) =>
        parent
            .getChildMeshes()
            .map((mesh) =>
                mesh.morphTargetManager != null
                    ? (mesh.morphTargetManager.getTarget(0).influence = morphLevel)
                    : null
            )
    );
}
export const handlePriceValue = (targetValue, currentValue, options, GlobalStore) => {
    for (const option of options) {
        if (targetValue === option.value) {
            GlobalStore.setPrice(
                GlobalStore.price - currentValue + option.price
            );
            GlobalStore.setDeposit(
                GlobalStore.deposit - currentValue / 20 + option.price / 20
            );
            currentValue = option.price;
        }
    }
}
export const mainEndsWallClipPlanesChange = (target, refs, parents, scene) => {
    let wallsClipPanelDistanceFromGround;
    switch (target) {
        case "Open":
            wallsClipPanelDistanceFromGround = refs[0] + 100;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "Gable":
            wallsClipPanelDistanceFromGround = refs[0] - 2;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "Extended-gable":
            wallsClipPanelDistanceFromGround =
                refs[0] - (refs[0] - refs[1]) / 4;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "1/4 Closed":
            wallsClipPanelDistanceFromGround =
                refs[0] - (refs[0] - refs[1]) / 4;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "1/2 Closed":
            wallsClipPanelDistanceFromGround =
                refs[0] - (refs[0] - refs[1]) / 2;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "3/4 Closed":
            wallsClipPanelDistanceFromGround =
                refs[0] - (3 * (refs[0] - refs[1])) / 4;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "Closed":
            wallsClipPanelDistanceFromGround = refs[1];
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;

        default:
            break;
    }
}
export const mainSidesWallClipPlanesChange = (target, refs, parents, scene) => {
    let wallsClipPanelDistanceFromGround;
    switch (target) {
        case "Open":
            wallsClipPanelDistanceFromGround = refs[0] + 100;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "1 Panel (3')":
            wallsClipPanelDistanceFromGround =
                refs[0] - (refs[0] - refs[1]) / 3;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "2 Panel (6')":
            wallsClipPanelDistanceFromGround =
                refs[0] - (2 * (refs[0] - refs[1])) / 3;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "1/4 Closed":
            wallsClipPanelDistanceFromGround =
                refs[0] - (refs[0] - refs[1]) / 4;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "1/2 Closed":
            wallsClipPanelDistanceFromGround =
                refs[0] - (refs[0] - refs[1]) / 2;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "3/4 Closed":
            wallsClipPanelDistanceFromGround =
                refs[0] - (3 * (refs[0] - refs[1])) / 4;
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        case "Closed":
            wallsClipPanelDistanceFromGround = refs[1];
            setWallClipPlanes(parents, wallsClipPanelDistanceFromGround, scene);
            break;
        default:
            break;
    }
}
const setWallClipPlanes = (parents, distance, scene) => {
    let clipPlane = new Plane(0, -1, 0, 0);
    clipPlane.d = distance;
    parents.map((parent) => {
        parent.getChildMeshes().map((mesh) => {
            mesh.onBeforeRenderObservable.add(function () {
                scene.resetCachedMaterial();
                scene.clipPlane2 = clipPlane;
            });
            mesh.onAfterRenderObservable.add(function () {
                scene.clipPlane2 = null;
            });
            return true;
        });
        return true;
    });
}
