import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';

export const BuildingTypeWrapper = styled.div`
    padding: 90px 170px 50px;
    flex-wrap: wrap;
    max-width: 2560px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    font-weight: bold;

    @media (max-width: 1400px) {
        padding: 90px 50px 50px;
    }

    @media (max-width: 991px) {
        transform: translateY(-40vh);
    }
`;

export const BuildingTypeContent = styled.div`
    margin-bottom: 50px;
`;

export const BuildingTypeContentTitle = styled.h1`
    font-family: Quantico;
    text-transform: uppercase;
    font-size: 36px;
`;

export const BuildingTypeContentText = styled.p`
    font-size: 15px;
`;

export const BuildingTypes = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;


export const BuildingTypesItem = styled.div`
    position: relative;
    width: 29%;
    margin: 0 1.2% 30px;
    padding: 10px 10px 20px;
    border-radius: 8px;
    background-color: #fff;
    color: #222;
    text-align: center;
    box-shadow: 0 0 0 1px #ccc;
    cursor: pointer;

    &::before, &::after {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        opacity: 0;
        transition: opacity .35s, transform .35s;
        border-radius: 8px;
    }

    &::before {
        transform: scaleX(0);
        border-top: 1px solid #222;
        border-bottom: 1px solid #222;
    }

    &::after {
        transform: scaleY(0);
        border-right: 1px solid #222;
        border-left: 1px solid #222;
    }

    &:hover {
        &::before, &::after {
            opacity: 1;
            transform: scale(1);
        }
    }

    @media (max-width: 991px) {
        width: 47%;
    }
`;

export const BuildingTypesItemImage = styled.div`
    position: relative;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BuildingTypesItemTitle = styled.h6`
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 0;
`;

export const BuildingTypesItemInfo = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const BuildingTypesItemInfoItem = styled.div`
    width: 30%;
    display: flex;
    justify-content: center;
    padding: 2px 10px;
    line-height: 12px;
    margin-top: 10px;
    font-size: 13px;

    &:nth-child(2) {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
`;

export const BuildingTypesItemInfoItemLabel = styled.div`
    color: #555;
`;

export const BuildingTypesItemInfoItemValue = styled.div`
    font-weight: bold;
`;

export const BuildingTypesItemButton = styled(Button)`
    width: auto;
    height: 35px;
    border-width: 2px;
    pointer-events: none;
    background: #fff;
    font-size: 12px;

    span {
        font-size: 12px;
    }
`;

// export const BuildingTypesItem = styled.div``;
// export const BuildingTypesItem = styled.div``;
