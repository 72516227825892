import React from 'react'
import { SeparatorWrapper } from './Separator.css'

const Separator = ({ marginBottom, marginTop }) => {
    
    return (
        <SeparatorWrapper marginBottom={marginBottom} marginTop={marginTop}>

        </SeparatorWrapper>
    )
}

export default Separator
