import styled from 'styled-components/macro';

export const LeanToSideWrapper = styled.div``;

export const LeanToSideTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const LeanToSideTitleLabel = styled.span`
    color: #eb7324;
    margin-left: 10px;
    font-size: 11px;
    white-space: nowrap;
`;

export const LeanToSideFormContent = styled.div`
    opacity: ${props => props.checked ? 1 : 0.6};
    user-select: ${props => props.checked ? 'auto' : 'none'};
    pointer-events: ${props => props.checked ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;