import React, { useState } from "react";

import {
	InputBoxesWrapper,
	InputBoxesItem,
	InputBoxesItemImage,
	InputBoxesItemText,
	InputBoxesIconChecked,
} from "./InputBoxes.css";

const InputBoxes = ({ options, onChange }) => {
	const [activeIndex, setActiveIndex] = useState(0);

	const handleClick = (index) => () => {
		setActiveIndex(Number(index));
		onChange(Number(index));
	};

	return (
		<InputBoxesWrapper>
			{options.map(({ id, text, imgUrl }, index) => {
				return (
					<InputBoxesItem
						key={`${id}`}
						value={`${text}`}
						onClick={handleClick(index)}
					>
						{activeIndex === index ? (
							<InputBoxesIconChecked>
								<svg
									width="24"
									height="24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm6.89 9.973l-7.538 7.539c-.321.32-.747.497-1.2.497-.454 0-.88-.176-1.2-.497L5.109 13.67a1.686 1.686 0 01-.497-1.2c0-.454.177-.88.497-1.2.32-.32.747-.497 1.2-.497.454 0 .88.176 1.2.497l2.643 2.642 6.338-6.339c.32-.32.747-.497 1.2-.497a1.699 1.699 0 011.2 2.897z"
										fill="#01B753"
									/>
								</svg>
							</InputBoxesIconChecked>
						) : null}
						<InputBoxesItemImage>
							{imgUrl && <img src={imgUrl} alt="no image" />}
						</InputBoxesItemImage>
						<InputBoxesItemText>{text}</InputBoxesItemText>
					</InputBoxesItem>
				);
			})}
		</InputBoxesWrapper>
	);
};

export default InputBoxes;
