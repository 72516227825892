import styled from 'styled-components/macro'

export const StateWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

export const StateText = styled.div`
  text-align: center;
  color: $white;
  font-weight: 500;
  line-height: 22.5px;
  max-width: 770px;
`;

export const StateTextTitle = styled.div`
  font-size: 40px;
  font-family: Quantico;

  span {
    display: inline-block;
    margin-left: 5px;
  }
`;

export const StateTextContent = styled.p`
  font-weight: bold;
  text-align: center;
`;