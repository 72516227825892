import styled from 'styled-components/macro';
import Scrollbar from 'react-scrollbars-custom';

export const DoorsAndWindowsScrollbarWrapper = styled(Scrollbar)`
    width: 340px !important;
    height: calc(var(--vh, 1vh)*100 - 250px) !important;

    @media (max-width: 991px) {
        width: 100% !important;
        height: inherit !important;
        position: static !important;
    }
`
export const DoorsAndWindowsWrapper = styled.div`
    padding: 15px;

    @media (max-width: 991px) {
        width: 100%;
    }
`;