import React, { useContext, useState, useEffect } from 'react'
import {
    WallsScrollbarWrapper,
    WallsWrapper,
    WallsSelectWrapper,
    WallsWainscot,
    WallsWainscotColorWrapper
} from '../Walls.css'

import InputSelect from '../../parts/InputSelect/InputSelect'
import SectionTitle from '../../../SectionTitle/SectionTitle'
import Separator from '../../../../utils/Separator/Separator'
import ColorPicker from '../../parts/ColorPicker/ColorPicker'
import { colorsOptionsPremium } from '../../parts/ColorPicker/ColorPickerData.json'

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Radio,
    RadioGroup,
} from '@material-ui/core'
import { Color3, Plane, Axis, Space } from '@babylonjs/core';
import { GlobalState } from '../../../../App'
import { mainEndsWallClipPlanesChange, mainSidesWallClipPlanesChange } from '../../../../utils/functions'

const storageOptions = [
    { value: "5", label: "5 Feet" },
    { value: "10", label: "10 Feet" },
    { value: "15", label: "15 Feet" },
    { value: "20", label: "20 Feet" },
    { value: "25", label: "25 Feet" },
    { value: "30", label: "30 Feet" },
    { value: "35", label: "35 Feet" },
    { value: "40", label: "40 Feet" },
    { value: "45", label: "45 Feet" },
    { value: "50", label: "50 Feet" },
    { value: "55", label: "55 Feet" },
    { value: "60", label: "60 Feet" },
    { value: "65", label: "65 Feet" },
    { value: "70", label: "70 Feet" },
    { value: "75", label: "75 Feet" },
    { value: "80", label: "80 Feet" },
    { value: "85", label: "85 Feet" },
    { value: "90", label: "90 Feet" },
    { value: "95", label: "95 Feet" },
];

const sidesOptions = [
    { value: "Open", label: "Open" },
    { value: "1 Panel (3')", label: "1 Panel (3')" },
    { value: "2 Panel (6')", label: "2 Panel (6')" },
    { value: "1/4 Closed", label: "1/4 Closed" },
    { value: "1/2 Closed", label: "1/2 Closed" },
    { value: "3/4 Closed", label: "3/4 Closed" },
    { value: "Closed", label: "Closed" }
];

const endsOptions = [
    { value: "Open", label: "Open" },
    { value: "Gable", label: "Gable" },
    { value: "1/4 Closed", label: "1/4 Closed" },
    { value: "1/2 Closed", label: "1/2 Closed" },
    { value: "3/4 Closed", label: "3/4 Closed" },
    { value: "Closed", label: "Closed" },
];

const insulationOptions = [
    { value: "Select", label: "Select" },
    { value: "2-fiber-glass", label: "2'' Fiber Glass" },
];

const WallsLeft = () => {
    const GlobalStore = useContext(GlobalState);
    const scene = GlobalStore.sceneGlobalRef.current;
    const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");
    //  const [verticalPanels, setVetricalPanels] = useState(false);
    // const [storage, setStorage] = useState(false);

    // const [leftSidePanel, setLeftSidePanel] = useState(false);
    // const [rightSidePanel, setRightSidePanel] = useState(false);

    const [insulationValue, setInsulationValue] = useState('Select');
    const [insulationCheckboxes, setInsulationCheckboxes] = useState(false);

    const [insulationRadio, setInsulationRadio] = useState('roof-only');

    const [wall, setWall] = useState('open');

    // const [jTrim, setJTrim] = useState(false);

    const [wainscot, setWainscot] = useState(false);

    // const [wallIsOpen, setWallIsOpen] = useState(true);

    const presetData = GlobalStore.buildingType.customizationData.leftLeanTo;
    useEffect(() => {
        if (presetData.isStorageOn) {
            handleStorage();
            handleAddStorage(presetData.storageLength);
        }
        switch (presetData.wallStyle) {
            case 'open':
                handleFullyOpen();
                handleWallChange(null, 'open')
                break;
            case 'enclosed':
                handleFullyEnclosed();
                handleWallChange(null, 'enclosed')
                break;

            default:
                break;
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     GlobalStore.setWainscot(false)
    // }, [wall])

    const handleWallChange = (e, i) => {
        i ? setWall(i) : setWall(e.target.value);
        // switch (e.target.value) {
        //     case 'open':
        //         setWallIsOpen(true)
        //         break;
        //     case 'enclosed':
        //         setWallIsOpen(false);
        //         break;

        //     default:
        //         break;
        // }
        //console.log(e.target.value);
    }
    // const handleVerticalPanels = () => {
    //     setVetricalPanels(!verticalPanels)
    // }

    // const handleLeftVerticalPanel = () => {
    //     setLeftSidePanel(!leftSidePanel)
    // }

    // const handleRightVerticalPanel = () => {
    //     setRightSidePanel(!rightSidePanel)
    // }
    const handleInsulationChange = (e) => {

        setInsulationValue(e.target.value)

        if (e.target.value === 'Select') {
            setInsulationCheckboxes(false)
            setInsulationRadio('roof-only')
        } else {
            setInsulationCheckboxes(true)
        }
    }
    const handleInsulationRadio = (event) => {
        setInsulationRadio(event.target.value)
    }
    // const handleJTrim = () => {
    //     setJTrim(!jTrim)
    // }
    const handleWainscot = () => {
        GlobalStore.setWainscot(!GlobalStore.wainscot)
        if (GlobalStore.wainscot) {
            GlobalStore.wainscotMeshes.current.map(mesh => {
                mesh.isVisible = false
            })
        }
        else {
            GlobalStore.wainscotMeshes.current.map(mesh => {
                if (mesh.name.includes("_LL")) {
                    if (GlobalStore.isLeftLeanActive.current) {
                        mesh.isVisible = true
                    }
                    else {
                        mesh.isVisible = false
                    }
                }
                else if (mesh.name.includes("_RL")) {
                    if (GlobalStore.isRightLeanActive.current) {
                        mesh.isVisible = true
                    }
                    else {
                        mesh.isVisible = false
                    }
                }
                else {
                    mesh.isVisible = true
                }
            })
        }

    }
    const handleWainscotColor = (color) => {
        wainscotMaterial.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
    }
    const handleFullyOpen = () => {
        const clipPlane = new Plane(0, -1, 0, 1000);
        GlobalStore.leftLeanWallMeshes.current.map((wall) => {
            wall.onBeforeRenderObservable.add(function () {
                scene.resetCachedMaterial();
                scene.clipPlane2 = clipPlane;
            });
            wall.onAfterRenderObservable.add(function () {
                scene.clipPlane2 = null;
            });
            return true
        });

        GlobalStore.setPrice(GlobalStore.price - 300);
        GlobalStore.setSelectedEndFront_LL("Open");
        GlobalStore.setSelectedEndBack_LL('Open');
        GlobalStore.setSelectedSidesLeft_LL('Open');
    }
    const handleFullyEnclosed = () => {
        const clipPlane = new Plane(0, -1, 0, 0);
        GlobalStore.leftLeanWallMeshes.current.map((wall) => {
            wall.onBeforeRenderObservable.add(function () {
                scene.resetCachedMaterial();
                scene.clipPlane2 = clipPlane;
            });
            wall.onAfterRenderObservable.add(function () {
                scene.clipPlane2 = null;
            });
            return true
        });
        GlobalStore.setPrice(GlobalStore.price + 300);
        GlobalStore.setSelectedEndFront_LL("Closed");
        GlobalStore.setSelectedEndBack_LL('Closed');
        GlobalStore.setSelectedSidesLeft_LL('Closed');
    }
    const handleEndsFront = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("dimensionHelper_L");
        const leftLeanFrontWalls = scene.getTransformNodeByName("frontWall_LL");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedEndFront_LL(targetValue)
        mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [leftLeanFrontWalls], scene)
    }
    const handleEndsBack = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("dimensionHelper_L");
        const leftLeanBackWalls = scene.getTransformNodeByName("backWall_LL");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedEndBack_LL(targetValue)
        mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [leftLeanBackWalls], scene)
    }
    const handleSidesLeft = (targetValue) => {
        const groundRef = scene.getMeshByName("Cylinder001");
        const wallHeightReference = scene.getMeshByName("dimensionHelper_L");
        const leftLeanSideWalls = scene.getTransformNodeByName("sideWall_LL");
        const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        GlobalStore.setSelectedSidesLeft_LL(targetValue)
        mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [leftLeanSideWalls], scene)
    }
    const handleWallColor = (color) => {
        const wallMaterials = GlobalStore.outerWallsMaterials.current;
        wallMaterials.map(mat => mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace())
    }
    const handleStorage = () => {
        GlobalStore.setStorageLeft(!GlobalStore.storageLeft)

        const leanParent_L = scene.getMeshByName("Lean_Left_Helper");
        const storageBackWall = scene.getMeshByName("storage_leanL_backWall");

        let leanStorage_L;
        leanParent_L.getChildMeshes().map(mesh => {
            if (mesh.name === "storage_lean_L") {
                leanStorage_L = mesh;
            }
            return 0
        })
        leanParent_L.computeWorldMatrix(true);
        const storagePlaneDistance = leanParent_L.getBoundingInfo().boundingBox.minimumWorld.x;
        GlobalStore.leanLLengthFromCenter.current = storagePlaneDistance;

        if (!GlobalStore.storageLeft) {
            leanStorage_L.setAbsolutePosition(GlobalStore.leanLLengthFromCenter.current, leanStorage_L.getAbsolutePosition().y, leanStorage_L.getAbsolutePosition().z)
            leanStorage_L.translate(Axis.Y, GlobalStore.leanLStorageTranslation.current / 5 * 2.4, Space.LOCAL);
            storageBackWall.isVisible = true;
        }
        else {
            leanStorage_L.setAbsolutePosition(GlobalStore.leanLLengthFromCenter.current, leanStorage_L.getAbsolutePosition().y, leanStorage_L.getAbsolutePosition().z)
            storageBackWall.isVisible = false;
        }
    }
    const handleAddStorage = (targetValue) => {
        const leanParent_L = scene.getMeshByName("Lean_Left_Helper");
        let leanStorage_L;
        leanParent_L.getChildMeshes().map(mesh => {
            if (mesh.name === "storage_lean_L") {
                leanStorage_L = mesh;
            }
            return 0
        })

        leanParent_L.computeWorldMatrix(true);
        const storagePlaneDistance = leanParent_L.getBoundingInfo().boundingBox.minimumWorld.x;
        GlobalStore.leanLLengthFromCenter.current = storagePlaneDistance;

        leanStorage_L.setAbsolutePosition(storagePlaneDistance, leanStorage_L.getAbsolutePosition().y, leanStorage_L.getAbsolutePosition().z)
        leanStorage_L.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);
        GlobalStore.leanLStorageTranslation.current = targetValue;
    }

    return (
        <WallsScrollbarWrapper>
            <WallsWrapper>
                <RadioGroup aria-label="walls" name="walls" value={wall} onChange={handleWallChange} row>
                    <FormControlLabel onChange={handleFullyOpen} value="open" control={<Radio />} label="Open" />
                    <FormControlLabel onChange={handleFullyEnclosed} value="enclosed" control={<Radio />} label="Fully Enclosed" />
                </RadioGroup>
                <Separator marginBottom={'15px'} />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={GlobalStore.storageLeft}
                            onChange={handleStorage}
                            name="storage"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="Add Storage"
                />
                <WallsSelectWrapper checked={GlobalStore.storageLeft}>
                    <InputSelect
                        onChange={handleAddStorage}
                        label="Utility Enclosed Area"
                        options={storageOptions}
                        inputName='add-storage'
                    />
                </WallsSelectWrapper>

                <Separator />

                <SectionTitle title={'ENDS'} />

                <InputSelect
                    onChange={handleEndsFront}
                    label="Front"
                    options={endsOptions}
                    inputName='ends-front'
                />

                <Separator marginBottom='15px' />

                <InputSelect
                    onChange={handleEndsBack}
                    label="Back"
                    options={endsOptions}
                    inputName='ends-back'
                />

                <Separator marginBottom='15px' />

                <SectionTitle title={'SIDES'} />


                <InputSelect
                    onChange={handleSidesLeft}
                    label="Left"
                    options={sidesOptions}
                    inputName='sides-left'
                />
                {/* <Separator marginBottom='15px' />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={jTrim}
                            onChange={handleJTrim}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="J-Trim"
                /> */}

                <Separator marginBottom='30px' />

                <ColorPicker
                    onChange={handleWallColor}
                    title='Wall'
                    colorsOptions={colorsOptionsPremium}
                    checked={GlobalStore.wallsColor}
                />

                <Separator />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={true}
                            name="add-insulation"
                            color="primary"
                        />
                    }
                    style={{
                        color: '#222',
                    }}
                    label="Add Insulation"
                />
                <br />
                <FormControl fullWidth>
                    <InputLabel style={{ fontSize: '11px' }} id='insulation-select-label'>Insulation</InputLabel>
                    <Select
                        value={insulationValue}
                        onChange={handleInsulationChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                    >
                        {insulationOptions.map(({ label, value }, i) => {
                            return (
                                <MenuItem value={value} key={i}>{label}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                {insulationCheckboxes && <>
                    <RadioGroup
                        aria-label="insulation-radio"
                        name="insulation-radio"
                        value={insulationRadio}
                        onChange={handleInsulationRadio}
                        style={{ padding: '0 8px' }}
                    >
                        <FormControlLabel value="roof-only" control={<Radio />} label="Roof Only" />
                        <FormControlLabel value="full-building" control={<Radio />} label="Full Building" />
                    </RadioGroup>
                </>
                }

                <Separator />

                <WallsWainscot checked={wall}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={GlobalStore.wainscot}
                                onChange={handleWainscot}
                                name="wainscot"
                                color="primary"
                            />
                        }
                        style={{
                            color: '#222',
                        }}
                        label="Add Wainscot(Full Building)"
                    />
                    <WallsWainscotColorWrapper checked={GlobalStore.wainscot}>
                        <ColorPicker
                            onChange={handleWainscotColor}
                            title='wainscot'
                            colorsOptions={colorsOptionsPremium}
                            checked={GlobalStore.wainscotColor}
                        />
                    </WallsWainscotColorWrapper>
                </WallsWainscot>

            </WallsWrapper>
        </WallsScrollbarWrapper>
    )
}

export default WallsLeft
