import React from 'react'
import { ReactComponent as LogoIcon } from '../../images/icons/logo-26.svg'
import { LogoWrapper } from './Logo.css';

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoIcon />
    </LogoWrapper>
  )
}

export default Logo
