import styled from 'styled-components/macro';

export const LeanToWrapper = styled.div`
    padding: 15px;

    @media (max-width: 991px) {
        width: 100%;
        max-width: 755px;
        margin: 0 auto;
        background-color: #fff;
    }
`;